// Action Types
import * as Actions from './action-types';
// User Storage Key
import {
    CART_LOCAL_STORAGE_KEY,
    CART_STORAGE_EXPIRY,
} from '../../Utils/CommonConstants';
// Local Storage Methods
import * as LS from '../../Utils/LocalStorageHelpers';
// Logger
import Logger from '../../Utils/Logger';
import {getNewDate} from "../../Utils/Helpers";
import {SET_CHILD_OPEN_FOR_EMBROIDERY, SET_UNIQUE_CART_ITEMS} from "./action-types";
// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    cartDetails: [],
    cartDetailsParsed: [],
    selectedCustomer: null,
    selectedStore: null,
    cartDetailRevamped: [],
    userId: null,
    open: false,
    openChild: false,
    openChildForEdit: false,
    openChildForEmbroidery: false,
    itemToBeSplit: null,
    itemToBeEdit: null,
    cartStorage: [],
    tempAccountCartStorage: [],
    uniqueCartItems : [],
    maxCartDeliveryDate: getNewDate(),
    minMaxCartDeliveryDate: getNewDate(),

};
// Get Initial State
const getInitialState = () => {
    const getData = LS.getStorage(CART_LOCAL_STORAGE_KEY);
    if (getData) {
        const dataParsed = JSON.parse(getData);
        // Logger.log("CART STORAGE DATA", dataParsed);
        return {
            processing: false,
            error: false,
            message: null,
            cartDetails: dataParsed.cartDetails,
            cartDetailsParsed: dataParsed.cartDetailsParsed,
            cartDetailRevamped: dataParsed.cartDetailRevamped,
            userId: dataParsed.userId,
            open: false,
            itemToBeSplit: null,
            itemToBeEdit: null,
            openChild: false,
            openChildForEdit: false,
            openChildForEmbroidery: false,
            selectedCustomer: dataParsed.selectedCustomer,
            selectedStore: dataParsed.selectedStore,
            cartStorage: [],
            tempAccountCartStorage: [],
            uniqueCartItems: dataParsed.uniqueCartItems,
            maxCartDeliveryDate: getNewDate(),
            minMaxCartDeliveryDate: getNewDate(),
        }
    }
    return initialState;
}

const reducer = (state = getInitialState(), action) => {
// const reducer = (state = initialState, action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;
        case Actions.SET_CART_DETAIL:
            newState.cartDetails = payload;
            break;
        case Actions.SET_CART_DETAIL_PARSED:
            newState.cartDetailsParsed = payload;
            break;

        case Actions.SET_USER_ID:
            newState.userId = payload;
            break;

        case Actions.SET_OPEN:
            newState.open = payload;
            break;

        case Actions.SET_CHILD_OPEN:
            newState.openChild = payload;
            break;

        case Actions.SET_CHILD_OPEN_FOR_EDIT:
            newState.openChildForEdit = payload;
            break;

        case Actions.SET_CHILD_OPEN_FOR_EMBROIDERY:
            newState.openChildForEmbroidery = payload;
            break;

        case Actions.SET_ITEM_TO_BE_EDIT:
            newState.itemToBeEdit = payload;
            break;

        case Actions.SET_ITEM_TO_BE_SPLIT:
            newState.itemToBeSplit = payload;
            break;

        case Actions.SET_SELECTED_CUSTOMER:
            newState.selectedCustomer = payload;
            break;
        case Actions.SET_SELECTED_STORE:
            newState.selectedStore = payload;
            break;


        case Actions.TOGGLE_STORAGE:
            return setStateToStorage(state, payload);

        case Actions.SET_CART_DETAIL_REVAMPED:
            newState.cartDetailRevamped = payload;
            break;

        case Actions.SET_UNIQUE_CART_ITEMS:
            newState.uniqueCartItems = payload;
            break;

        case Actions.SET_CART_STORAGE:
            newState.cartStorage = payload;
            break;

        case Actions.SET_TEMP_ACCOUNT_CART_STORAGE:
            newState.tempAccountCartStorage = payload;
            break;

        case Actions.SET_MAX_CART_DELIVERY_DATE:
            newState.maxCartDeliveryDate = payload;
            break;

        case Actions.SET_MIN_MAX_CART_DELIVERY_DATE:
            newState.minMaxCartDeliveryDate = payload;
            break;

        case Actions.RESET_STATE:
            resetState(newState);
            break;

        default:
            break;
    }

    return newState;
}
const setStateToStorage = (state, fill) => {
    if (fill) {
        // Logger.log("SAVING TO STORAGE", state);
        LS.setStorage(CART_LOCAL_STORAGE_KEY, JSON.stringify(state), CART_STORAGE_EXPIRY);
    } else {
        LS.removeStorage(CART_LOCAL_STORAGE_KEY);
    }
    return state;
};
// Reset State
const resetState = (state) => {
    state.processing = false;
    state.error = false;
    state.message = null;
    state.cartDetails = [];
    state.cartDetailsParsed = [];
    state.selectedCustomer = null;
    state.selectedStore = null;
    state.cartDetailRevamped = [];
    state.uniqueCartItems = [];
    state.userId = null;
    state.open = false;
    state.openChild = false;
    state.openChildForEdit = false;
    state.openChildForEmbroidery = false;
    state.itemToBeSplit = null;
    state.itemToBeEdit = null;
    state.cartStorage = [];
    state.tempAccountCartStorage = [];
    state.maxCartDeliveryDate = getNewDate();
    state.minMaxCartDeliveryDate = getNewDate();


    setStateToStorage(state, false);


    return state;

};

export default reducer;
