// Library
import React from 'react';
import {Switch} from 'antd';

const ToggleButton = (props) => {
    const {changer, checked, disabled} = props;

    return (
        <>
            <Switch defaultChecked={checked} onChange={changer} disabled={disabled ? disabled : false}/>
        </>
    )
}

export default ToggleButton;
