import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
// Redux Actions
import {setTheme} from "../../Store/Theme/actions";
import Color from "../../Config/Colors";

const ThemeSwitcher = (props) => {

    const dispatch = useDispatch();

    // State
    const themeState = useSelector(state => state.theme);

    const handleThemeChange = () => {
        dispatch(setTheme(themeState.themeMode === 'dark' ? 'light' : 'dark'));
    };

    return (

            <IconButton sx={{ color: themeState.themeMode === 'dark' ? Color.white : Color.black }} onClick={handleThemeChange} color="inherit">
                {themeState.themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
    );
}
export default ThemeSwitcher;