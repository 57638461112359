//Action Types
import * as Actions from './action-types';
import * as LS from "../../Utils/LocalStorageHelpers";
import {
    THEME_LOCAL_STORAGE_KEY,
    THEME_STORAGE_EXPIRY
} from "../../Utils/CommonConstants";
import Logger from "../../Utils/Logger";

// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    themeMode: 'light'

};

const getInitialState = () => {
    const getData = LS.getStorage(THEME_LOCAL_STORAGE_KEY);
    if (getData) {
        const dataParsed = JSON.parse(getData);
        Logger.log("CART STORAGE DATA", dataParsed);

        return {
            processing: false,
            error: false,
            message: null,
            themeMode: dataParsed.themeMode
        }
    }
    return initialState;
}

const reducer = (state = getInitialState(), action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;

        case Actions.SET_THEME_MODE:
            newState.themeMode = payload;
            break;

        case Actions.TOGGLE_STORAGE:
            setStateToStorage(state, payload);
            break;

        default:
            break;
    }


    return newState;

};

const setStateToStorage = (state, fill) => {
    if (fill) {
        // Logger.log("SAVING TO STORAGE", state);
        LS.setStorage(THEME_LOCAL_STORAGE_KEY, JSON.stringify(state), THEME_STORAGE_EXPIRY);
    } else {
        LS.removeStorage(THEME_LOCAL_STORAGE_KEY);
    }
    return state;
};

export default reducer;
