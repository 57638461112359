// Library
import React from 'react';
import {withRouter} from "react-router-dom";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// Header
import Header from '../Containers/Header';
// Container
import AppContainer from "../Containers/AppContainer";

const AppLayout = (props) => {
    return (
        <>
            <CssBaseline/>
            <Header/>
            <AppContainer>
                <Box>
                    {props.children}
                </Box>
            </AppContainer>
        </>
    )
}
export default withRouter(AppLayout);
