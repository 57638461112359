// Library
import React from 'react';
// Full Page Bg
import FullPageBg from "../Containers/FulPageBg";
// Helpers
import * as Helpers from "../Utils/Helpers";
// Pictures
import Pictures from "../Config/Pictures";

// Images
const bgImage = Helpers.toAbsoluteUrl(Pictures.LoginBG);

const AuthenticationLayout = (props) => {
    const {children, bg} = props;
    return (
        <>
            <FullPageBg img={bg ? bg : bgImage}>
                {children}
            </FullPageBg>

        </>
    )
}
export default AuthenticationLayout;
