// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "ERROR_LOG_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";

