// Library
import React from 'react';
import {useSelector} from "react-redux";
import Colors from "../../Config/Colors";
import Logger from '../../Utils/Logger';

const PageContainer = (props) => {
    const {themeMode} = useSelector(state => state.theme);
    const {children} = props;

    // Logger.log("THEME: " , themeMode);

    return (
        <div style={{marginTop: "50px"}}>
            <div id={"page-container-bg"} style={{padding: "10px", background: themeMode === 'light' ? Colors.gray100 : Colors.gray1000}}>
                {children}
            </div>
        </div>
    )
}
export default PageContainer
