import React from 'react';
import DatePicker from "react-datepicker";
import {Row, Col, Label, FormGroup} from 'reactstrap';
import {useSelector} from "react-redux";

const DateRangePickerComponent = (props) => {


    const {themeMode} = useSelector(state => state.theme);

    const {id, className, labelSize, label, inputSize, changer, startDate, endDate} = props;
    return (
        <Row>

            <FormGroup row style={{margin: "15px 0px"}}>
                <Label for={id} className={themeMode === 'light' ? 'text-dark' : 'text-light'} sm={labelSize ? labelSize : 2}>{label ? label : "text-box"}</Label>
                <Col sm={inputSize ? inputSize : 6}>
                    <DatePicker
                        className={className}
                        selected={startDate}
                        onChange={changer}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat={"yyyy-MM-dd"}
                        selectsRange
                    />
                </Col>
            </FormGroup>
        </Row>
    )


}

export default DateRangePickerComponent;