// Library
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as yup from 'yup';
// Components
import InputBox from '../../../Components/Input';
import Button from '../../../Components/Button';
import Logo from '../../../Components/Logo';
import Loader from '../../../Components/Loader';
// Container
import AuthenticationLayout from "../../../Layouts/AuthenticationLayout";
import CenterContainer from "../../../Containers/PageCenterContainer";
// Colors
import Colors from "../../../Config/Colors";
// Logger
import Logger from "../../../Utils/Logger";
// Redux Actions
import {login} from '../../../Store/User/actions';
// Version
import VersionNumber from "../../../Containers/Version";
import Linker from "../../../Components/Link";
import {logTrack} from "../../../Store/Tracker/actions";
import {getTrackerDO} from "../../../Utils/Helpers";
import {PAGES} from '../../../Utils/CommonConstants';


// Validations
const validationSchema = yup.object({
    username: yup
        .string('Enter your Username')
        .min(4, "Username should be minimum 4 characters long")
        .required('Username is required'),
    password: yup
        .string('Enter your password')
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
});
const LoginPage = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TRACKER
    const tracker = useSelector(state => state.tracker);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };


    // Formik Form
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setStatus(false);

            dispatch(logTrack(getTrackerDO(tracker.tracker, "LOGIN SUBMITTED", PAGES.LOGIN, {
                username: values.username,
                password: values.password
            })));


            dispatch(login(values.username, values.password, (err) => {
                dispatch(logTrack(getTrackerDO(tracker.tracker, "LOGIN RESPONSE", err)));
                disableLoading();
                Logger.error("Login Error: ", err);
                if (err) {
                    setSubmitting(false);
                    setStatus("Login Failed");

                    // setNotificationText("Wrong Credentials");
                    // setNotificationSeverity("error");
                } else {
                    // setNotificationText("Success");
                    // setNotificationSeverity("success");

                }
                // setNotification(true);
                // setTimeout(() => {
                //     setNotification(false);
                // }, 6000);

            }))

            Logger.log("Submitter, Login Values", values);
        },
    });


    return (
        <>
            <AuthenticationLayout>
                <CenterContainer>
                    <div className={"p-5 br-2 rounded-top rounded-bottom"}
                         style={{background: Colors.white, boxShadow: "0px 0px 16px #fff"}}>
                        <Logo/>
                        <form onSubmit={formik.handleSubmit}>
                            <InputBox
                                margin={'dense'}
                                fullWidth
                                id="username"
                                name="username"
                                label="Username"
                                variant="standard"
                                value={formik.values.username}
                                changer={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                            <InputBox
                                fullWidth
                                margin={'normal'}
                                id="password"
                                variant="standard"
                                name="password"
                                label="Password"
                                type="password"
                                value={formik.values.password}
                                changer={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                style={{color: Colors.white, marginTop: "30px"}}
                                disabled={formik.isSubmitting}
                            >
                                {loading ? <Loader dark/> : "Login"}
                            </Button>
                        </form>
                        <div style={{marginTop: "10px", float: "right"}}>
                            <Linker to={"/auth/forgot-password"}>Forgot Password</Linker>
                        </div>
                        <VersionNumber version={window.config.VERSION}/>
                    </div>

                </CenterContainer>

            </AuthenticationLayout>
        </>
    )
}
export default connect(null, {login})(LoginPage);
