// Logger
import Logger from "./Logger";
// store
import store from '../Store/index';
// Toast
import {toaster} from './Helpers';
// User Actions
import {logout} from '../Store/User/actions';


const errorHandler = (error) => {

    // debugger;
    Logger.log("Error Handler: " , error);
    Logger.log("Error Handler: " , typeof error);

    let message = "";
    let statusCode = "";

    if(error && error.hasOwnProperty("response") && error.response.hasOwnProperty("status")) {
        Logger.log("ERROR RESPONSE: " , error.response);
        Logger.log("ERROR STATUS: " , error.response.status);

        message = error.response.data.content ? error.response.data.content : error.response.data.message;
        statusCode = error.response.status;

        const {dispatch} = store;


        Logger.log("STATUS CODE" , statusCode);

        // Token Expires
        if(error.response.status === 401) {
            message = "Your token is expired, please login again";

            const userState = store.getState().user;

            Logger.log("USER STATE" , userState);

            dispatch(logout());
        }

        toaster(message);
    } else {
        toaster("Something Went Wrong, Please Contact Administrator !");
    }


};


export default errorHandler;
