// Configuration
import {Config} from '../Config';
import axios from 'axios';

const envCheck = Config.ENV === "DEV" || Config.ENV === "STAGE";

const Logger = {

    log: (name, obj) => {
        if (envCheck) {
            if (obj) {
                console.log("------", name + " ==> ", obj, " | type ==> ", typeof obj);
            } else {
                console.log("------", name);
            }

        }
        if(window.config.LOG_IN_FILE) {
            axios.post("http://localhost:3001/api/logs", {
                "userId" : 1,
                "message" : ">>>>---->> " + name + " ===> " + JSON.stringify(obj),
                "level" : "info",
                "timestamp" : new Date().toISOString(),
            })
        }

    },
    error: (name, obj) => {
        if (envCheck) {
            if (obj) {
                console.error("------", name + " ==> ", obj, " | type ==> ", typeof obj);
            } else {
                console.error("------", name);
            }
        }
    }

}

export default Logger;
