// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "TRACKER_LOG_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_TRACKER = MODULE_PREFIX_PREFIX + "SET_TRACKER";
export const TOGGLE_STORAGE = MODULE_PREFIX_PREFIX + "TOGGLE_STORAGE";

