//Action Types
import * as Actions from './action-types';
import {FILTER_PRICE_LOW_TO_HIGH, FILTER_STYLE_LOW_TO_HIGH} from "../../Utils/CommonConstants";

// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    allCustomers: [],
    selectedCustomer: null,
    customerProcessing: false,
    allStores: [],
    selectedStore: null,
    storeProcessing: false,
    season: [],
    division: [],
    selectedSeason: null,
    selectedDivision: null,
    groupProcessing: false,
    allGroups: [],
    selectedGroup: null,
    allProducts: [],
    selectedProduct: null,
    productsProcessing: false,
    allItems: [],
    itemProcessing: false,
    allSizes: [],
    allSizesAccessory: [],
    sizeProcessing: false,
    itemsParsed: [],
    placedOrder: null,
    allOrders: [],
    page: 1,
    limit: 0,
    totalPages: 0,
    dataCount: 0,
    nextPage: false,
    totalDocuments: 0,
    allOrderTypes: [],
    selectedOrderType: null,
    allShippingTypes: [],
    selectedShippingType: null,
    preLoadedCustomerAndStore: false,
    itemFilter: FILTER_STYLE_LOW_TO_HIGH,

};
const reducer = (state = initialState, action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;
        case Actions.SET_ALL_CUSTOMERS:
            newState.allCustomers = payload;
            break;

        case Actions.SET_SELECTED_CUSTOMER:
            newState.selectedCustomer = payload;
            break;
        case Actions.SET_CUSTOMER_PROCESSING:
            newState.customerProcessing = payload;
            break;
        case Actions.SET_ALL_STORES:
            newState.allStores = payload;
            break;
        case Actions.SET_SELECTED_STORE:
            newState.selectedStore = payload;
            break;
        case Actions.SET_STORES_PROCESSING:
            newState.storeProcessing = payload;
            break;
        case Actions.SET_SEASON:
            newState.season = payload;
            break;
        case Actions.SET_DIVISION:
            newState.division = payload;
            break;

        case Actions.SET_SELECTED_DIVISION:
            newState.selectedDivision = payload;
            break;

        case Actions.SET_SELECTED_SEASON:
            newState.selectedSeason = payload;
            break;

        case Actions.SET_SELECTED_GROUP:
            newState.selectedGroup = payload;
            break;

        case Actions.SET_GROUP_PROCESSING:
            newState.groupProcessing = payload;
            break;
        case Actions.SET_GROUP:
            newState.allGroups = payload;
            break;
        case Actions.SET_ALL_PRODUCTS:
            newState.allProducts = payload;
            break;
        case Actions.SET_SELECTED_PRODUCT:
            newState.selectedProduct = payload;
            break;
        case Actions.SET_PRODUCTS_PROCESSING:
            newState.productsProcessing = payload;
            break;
        case Actions.SET_ALL_ITEMS:
            newState.allItems = payload;
            break;
        case Actions.SET_ALL_ITEMS_PARSED:
            newState.itemsParsed = payload;
            break;
        case Actions.SET_ITEM_PROCESSING:
            newState.itemProcessing = payload;
            break;

        case Actions.SET_ALL_SIZES:
            newState.allSizes = payload;
            break;

        case Actions.SET_ALL_SIZES_ACCESSORY:
            newState.allSizesAccessory = payload;
            break;
        case Actions.SET_SIZE_PROCESSING:
            newState.sizeProcessing = payload;
            break;
        case Actions.SET_PLACED_ORDER:
            newState.placedOrder = payload;
            break;

        case Actions.SET_ALL_ORDERS:
            newState.allOrders = payload;
            break;

        case Actions.SET_LIMIT:
            newState.limit = payload;
            break;

        case Actions.SET_TOTAL_PAGES:
            newState.totalPages = payload;
            break;

        case Actions.SET_DATA_COUNT:
            newState.dataCount = payload;
            break;

        case Actions.SET_TOTAL_DOCUMENTS:
            newState.totalDocuments = payload;
            break;

        case Actions.SET_NEXT_PAGE:
            newState.nextPage = payload;
            break;

        case Actions.SET_CURRENT_PAGE:
            newState.page = payload;
            break;

        case Actions.SET_ORDER_TYPES:
            newState.allOrderTypes = payload;
            break;
        case Actions.SET_SELECTED_ORDER_TYPE:
            newState.selectedOrderType = payload;
            break;

        case Actions.SET_SHIPPING_TYPES:
            newState.allShippingTypes = payload;
            break;

        case Actions.SET_SELECTED_SHIPPING_TYPE:
            newState.selectedShippingType = payload;
            break;

        case Actions.SET_PRELOADED_CUSTOMER_AND_STORE:
            newState.preLoadedCustomerAndStore = payload;
            break;

        case Actions.SET_ITEM_FILTER:
            newState.itemFilter = payload;
            break;


        case Actions.RESET_STATE:
            resetState(newState);
            break;
        default:
            break;
    }


    return newState;

};

// Reset State
const resetState = (state) => {

    state.processing = false;
    state.error = false;
    state.message = null;
    state.allCustomers = [];
    state.selectedCustomer = null;
    state.customerProcessing = false;
    state.allStores = [];
    state.selectedStore = null;
    state.storeProcessing = false;
    state.season = [];
    state.division = [];
    state.selectedSeason = null;
    state.selectedDivision = null;
    state.groupProcessing = false;
    state.allGroups = [];
    state.selectedGroup = null;
    state.allProducts = [];
    state.selectedProduct = null;
    state.productsProcessing = false;
    state.allItems = [];
    state.itemProcessing = false;
    state.allSizes = [];
    state.sizeProcessing = false;
    state.itemsParsed = [];
    state.placedOrder = null;
    state.allOrders = [];
    state.page = 1;
    state.limit = 0;
    state.totalPages = 0;
    state.dataCount = 0;
    state.nextPage = false;
    state.totalDocuments = 0;
    state.allOrderTypes = [];
    state.selectedOrderType = null;
    state.allShippingTypes = [];
    state.selectedShippingType = null;
    state.preLoadedCustomerAndStore = false;
    state.itemFilter = FILTER_STYLE_LOW_TO_HIGH;


    return state;

};

export default reducer;
