// Library
import React from 'react';
import {Table} from 'antd';
// CSS
import 'antd/dist/antd.css';
// Logger
import Logger from "../../Utils/Logger";
// Components
import Pagination from "../Pagination";
import Loader from "../Loader";
import {useSelector} from "react-redux";


const ReactTable = (props) => {
    const {themeMode} = useSelector(state => state.theme);
    const {
        dataSource,
        columns,
        rowKey,
        paginated,
        currentPage,
        totalPages,
        changePageHandler,
        loading,
        expandable,
        size
    } = props;

    // Logger.log("DATASOURCE", dataSource);
    // Logger.log("COLUMNS", columns);
    return (
        <>
            <Table
                className={themeMode === 'light' ? 'antDesignTbLight' : 'antDesignTbDark'}
                expandable={expandable ? expandable : undefined}
                size={size ? size : "md"}
                loading={{spinning: loading, indicator: <Loader/>}}
                rowKey={rowKey}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowClassName={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
            />
            <div className={"mt-5"}>
                {paginated ? (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        changer={(e, page) => {
                            changePageHandler(e, page)
                        }}
                    />
                ) : null}


            </div>
        </>
    )
}
export default ReactTable;
