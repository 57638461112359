// Library
import React, {useEffect, useState, useRef} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Row, Col, Modal} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {useReactToPrint} from 'react-to-print';
// Components
import Loader from '../../Components/Loader';
import Button from '../../Components/Button';
import InputBox from "../../Components/Input";
// Containers
import PageContainer from "../../Containers/PageContainer";
import CustomerViewer from "../../Containers/CustomerTable";
import StoreViewer from "../../Containers/StoreContainer";
import OrderDetailViewer from "../../Containers/OrderDetailViewer";
// Logger
import Logger from "../../Utils/Logger";
// Redux Actions
import {getAllOrdersFromTransactionWithUUID} from '../../Store/Order/action';
import {sendEmailWithAttachment} from '../../Store/Email/action';
// Helper
import {toaster, validateEmail} from "../../Utils/Helpers";
// Colors
import Colors from "../../Config/Colors";

const OrderAcceptancePage = (props) => {

    const [show, setShow] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [to, setTo] = useState(null);


    // Hooks
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => afterPrint()
    });
    const dispatch = useDispatch();
    const {uuid} = useParams();
    // State
    const orderState = useSelector(state => state.order);
    const emailState = useSelector(state => state.email);

    // Use Effect
    useEffect(() => {

        Logger.log("UUID", uuid);

        dispatch(getAllOrdersFromTransactionWithUUID(uuid), (err) => {

        });

    }, []);

    const afterPrint = () => {
        const pageContainerBg = document.getElementById('page-container-bg');
        pageContainerBg.style.background = Colors.gray100;
        toaster("File is available to download", "success");
    }

    const printDocument = () => {
        // const input = document.getElementById('page-to-print');
        const pageContainerBg = document.getElementById('page-container-bg');

        pageContainerBg.style.background = Colors.white;

        handlePrint();
    }

    const emailDocument = () => {
        if (to && validateEmail(to)) {

            setShow(false);
            dispatch(sendEmailWithAttachment(to, uuid, (err) => {
                handleClose();
                if (err) {
                    toaster("Something went wrong while sending email");
                } else {
                    toaster("Email Sent Successfully", "success");
                }
            }));
        } else {
            toaster("Please check the email address entered");
        }
    }


    const handleClose = () => {
        setShow(false);
        setAttachment(null);
        setTo(null)
    }
    const handleShow = () => setShow(true);


    Logger.log("Order State", orderState.placedOrder);
    Logger.log("STATE TO", to);
    Logger.log("STATE ATTACHMENT", attachment);

    return (
        <>
            <h3 className={"text-center"}>Order Acknowledgement</h3>
            <div id={"page-to-print"} ref={printRef}>
                <PageContainer>


                    {orderState.processing ? (
                        <>
                            <Loader/>
                        </>
                    ) : (
                        <>
                            {orderState.placedOrder ? (
                                <>
                                    <Row>
                                        <Col sm={{size: "12"}}>
                                            <div style={{border: "1px solid rgb(222, 226, 230)", padding: "10px"}}>
                                                <h3 className={"text-center"}><b>LUCKY IN LOVE</b></h3>
                                                <div className={"text-center"}>
                                                    3300 NW 41ST STREET
                                                    MIAMI , FL 33142 USA<br/>
                                                    Tel : 305-638-5484<br/>
                                                    Fax : 305-638-8949
                                                </div>
                                            </div>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{border: "1px solid rgb(222, 226, 230)", padding: "10px"}}>
                                                <div className={"text-center"}>
                                                    Order Number : <b>{uuid}</b>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{size: "6"}}>
                                            <CustomerViewer data={orderState.placedOrder.orders[0].customer}/>

                                        </Col>
                                        <Col sm={{size: "6"}}>
                                            <StoreViewer data={orderState.placedOrder.orders[0].store}/>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <h4 className={"text-center"}>Order Details</h4>
                                        <Col>

                                            <OrderDetailViewer data={orderState.placedOrder.orders} term={orderState.placedOrder.orders[0].customer.term}/>
                                        </Col>

                                    </Row>

                                </>
                            ) : null}


                        </>
                    )}


                </PageContainer>


            </div>
            <div className={"mt-5"}>
                {emailState.processing ? (
                    <Loader/>
                ) : (
                    <>
                        <Button clicker={() => printDocument()}>Print</Button> {" "}
                        <Button color={"info"} clicker={handleShow}>Send Via Email</Button>
                    </>
                )}

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Write Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="to"
                        name="to"
                        label="Add Recipient's Email"
                        variant="standard"
                        value={to}
                        changer={(e) => setTo(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button color="error" clicker={handleClose}>
                        Close
                    </Button>
                    {"    "}
                    <Button color="primary" clicker={emailDocument}>
                        Sent
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default connect(null, {getAllOrdersFromTransactionWithUUID, sendEmailWithAttachment})(OrderAcceptancePage);
