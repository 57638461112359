// Action Types
import * as Actions from "./action-types";
// Logger
import Logger from '../../Utils/Logger';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Error Handler
import errorHandler from "../../Utils/errorHandler";


// Send Email With Attachment
export const sendEmailWithAttachment = (to, attachment, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setTo(to));
        dispatch(_setAttachment(attachment));

        axios.post(URLS.EmailUrls.SendEmailWithAttachment, {
            to,
            attachment
        })
            .then(response => {
                Logger.log("EMAIL RESPONSE: ", response.data);

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("EMAIL ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}
// Send Individual Delivery With Attachment
export const sendIndividualDeliveryEmail = (to, attachment, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setTo(to));
        dispatch(_setAttachment(attachment));

        axios.post(URLS.EmailUrls.SendIndividualDeliveryEmail, {
            to,
            attachment
        })
            .then(response => {
                Logger.log("EMAIL RESPONSE: ", response.data);

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("EMAIL ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}
// Send Individual Delivery With Attachment
export const sendATSReportEmail = (data, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));

        axios.post(URLS.EmailUrls.SendATSReportEmail, data)
            .then(response => {
                Logger.log("EMAIL RESPONSE: ", response.data);

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("EMAIL ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}
// Send Cart Storage
export const sendCartStorageEmail = (data, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));

        axios.post(URLS.EmailUrls.SendCartStorageEmail, data)
            .then(response => {
                Logger.log("EMAIL RESPONSE: ", response.data);

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("EMAIL ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}

export const sendSupportEmail = (emailBody, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));

        axios.post(URLS.EmailUrls.SendSupportEmail, {emailBody: emailBody})
            .then(response => {
                Logger.log("EMAIL RESPONSE: ", response.data);

                dispatch(_setProcessing(false));
                dispatch(_setError(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("EMAIL ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
const _setTo = (payload) => {
    return {
        type: Actions.SET_TO,
        payload,
    };
};
const _setAttachment = (payload) => {
    return {
        type: Actions.SET_ATTACHMENT,
        payload,
    };
};
