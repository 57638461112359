import React from 'react';

// Container
import AuthenticationLayout from "../../Layouts/AuthenticationLayout";
import CenterContainer from "../../Containers/PageCenterContainer";
import * as Helpers from "../../Utils/Helpers";
import Pictures from "../../Config/Pictures";
// CSS
import "./style.css";
// Images
const bgImage = Helpers.toAbsoluteUrl(Pictures.MaintenancePageBg);


const MaintenancePage = (props) => {
    return (
        <>
            <AuthenticationLayout bg={bgImage}>
                <CenterContainer>
                    <span style={{
                        fontSize:"50px",
                        fontFamily: 'Anton',
                        color:"white",
                        marginTop:"100px",
                        textAlign:"center"
                    }}>
                        We Are Down For Maintenance
                        <br/>
                         <span style={{
                             fontSize:"20px"
                         }}>will be back shortly...</span>
                    </span>

                </CenterContainer>
            </AuthenticationLayout>
        </>
    )
}

export default MaintenancePage;