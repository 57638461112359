import React, {useEffect, useState} from 'react';
// Config
import Colors from "../../Config/Colors";
// Logo
import Logo from "../../Components/Logo";
// Container
import AuthenticationLayout from "../../Layouts/AuthenticationLayout";
import CenterContainer from "../../Containers/PageCenterContainer";
import InputBox from "../../Components/Input";
import {useFormik} from "formik";
import {changePassword} from "../../Store/User/actions";
import Logger from "../../Utils/Logger";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import Loader from "../../Components/Loader";
import Button from "../../Components/Button";
import {toaster} from "../../Utils/Helpers";
import {useHistory, useParams} from "react-router-dom";
import {getUserToUpdatePassword} from '../../Store/User/actions';


// Validations
const validationSchema = yup.object().shape({
    password: yup.string().required("Password is Required").min(6, "Password is too short"),
});

const ForgotPasswordImpl = (props) => {

    const dispatch = useDispatch();
    const {uuid} = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const userState = useSelector(state => state.user);

    // const enableLoading = () => {
    //     setLoading(true);
    // };
    //
    // const disableLoading = () => {
    //     setLoading(false);
    // };

    useEffect(() => {
        if(uuid) {
            dispatch(getUserToUpdatePassword(uuid, (err) => {
                if(err) {
                    toaster("You are not allowed to change through this link" , "danger");
                    history.push("/");
                }
            }));

        }
    }, []);

    // Formik Form
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            Logger.log("VALUES: ", values);
            values.id = userState.transactionalUser.id;
            setLoading(true);
            setSubmitting(true);
            setStatus(false);
            let sev = "";
            let message = "";

            dispatch(changePassword(values, (err) => {
                if (!err) {
                    message = "Successfully Changed The Password";
                    sev = "success";
                    history.push("/");
                    toaster(message, sev);

                }
                setLoading(false);
                setSubmitting(false);

            }));
        },
    });

    return (
        <>
            <AuthenticationLayout>
                <CenterContainer>
                    <div className={"p-5 br-2 rounded-top rounded-bottom"}
                         style={{background: Colors.white, boxShadow: "0px 0px 16px #fff"}}>
                        <Logo/>
                        <form onSubmit={formik.handleSubmit}>
                            <InputBox
                                margin={'dense'}
                                fullWidth
                                type={"password"}
                                id="password"
                                name="password"
                                label="Password"
                                variant="standard"
                                value={formik.values.password}
                                changer={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            {userState.processing ? (
                                <Loader/>
                            ) : (
                                <Button
                                    fullWidth
                                    type="submit"
                                    style={{color: Colors.white, marginTop: "30px"}}
                                    disabled={formik.isSubmitting}
                                >
                                    {loading ? <Loader dark/> : "Change Password"}
                                </Button>
                            )}

                        </form>
                    </div>
                </CenterContainer>
            </AuthenticationLayout>
        </>
    )
}

export default ForgotPasswordImpl;