// Library
import React from 'react';
import Button from '../Button';
import {Col, FormGroup, Label, Input, FormText, FormFeedback} from 'reactstrap';
import {useSelector} from "react-redux";

const InputV2 = (props) => {

    const {themeMode} = useSelector(state => state.theme);
    const {
        id,
        name,
        label,
        labelSize,
        inputSize,
        value,
        changer,
        helperText,
        type,
        error,
        placeholder,
        rows,
        button,
        btnClicker,
        btnLabel
    } = props;
    const labelClass = themeMode === 'light' ? 'text-dark' : 'text-light';
    return (
        <>
            <FormGroup row style={{margin: "15px 0px"}}>
                <Label for={id} className={labelClass} sm={labelSize ? labelSize : 3}>{label ? label : "text-box"}</Label>
                <Col sm={inputSize ? inputSize : 9}>
                    <Input
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                        style={{resize: "none"}}
                        id={id ? id : "text-box"}
                        name={name ? name : "text-box"}
                        onChange={changer}
                        type={type ? type : "text"}
                        placeholder={placeholder}
                        value={value}
                        rows={rows ? rows : 1}
                        invalid={!!error}
                    />
                    {helperText ? (
                        <FormText>{helperText}</FormText>
                    ) : null}
                    {error ? (
                        <FormFeedback>{error}</FormFeedback>
                    ) : null}

                </Col>
                {button ? (
                    <Col>
                        <Button clicker={btnClicker}>{btnLabel ? btnLabel : "Click Me"}</Button>
                    </Col>
                ) : null}
            </FormGroup>
        </>
    )
}

export default InputV2;
