// Action Types
import * as Actions from "./action-types";
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Logger
import Logger from '../../Utils/Logger';
// Parser
import {
    parseItemsForSelectMenu,
    setSizesForProduct,
    mapSizesToItems,
    getParsedOrder,
    extractSizesFromAllItemsList,
    filterCustomers,
    filetrStore
} from './parser';
// Error Handler
import errorHandler from "../../Utils/errorHandler";
// Cart Actions
import {_setSelectedCustomer as cartSelectedCustomer, _setSelectedStore as cartSelectedStore} from "../Cart/action";
import {
    FILTER_PRICE_HIGH_TO_LOW,
    FILTER_PRICE_LOW_TO_HIGH,
    FILTER_STYLE_HIGH_TO_LOW, FILTER_STYLE_LOW_TO_HIGH
} from "../../Utils/CommonConstants";
import _ from "lodash";


// Get All Customer
export const getAllCustomersBySalesmanId = (salesmanId, cb) => {

    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));
        dispatch(_setSelectedCustomer(null));
        dispatch(_setAllStores([]));
        dispatch(_setSelectedStore(null));
        dispatch(_setSeason([]));
        dispatch(_setDivision([]));
        dispatch(_setSelectedDivision(null));
        dispatch(_setSelectedSeason(null));
        dispatch(_setGroup([]));
        dispatch(_setSelectedGroup(null));
        dispatch(_setAllItems([]));
        dispatch(_setSelectedProduct(null));

        const url = salesmanId === 0 ? URLS.CustomerUrls.GetAllCustomers : URLS.CustomerUrls.GetAllCustomerBySalesmanId + salesmanId;

        axios.get(url)
            .then(response => {

                Logger.log("CUSTOMER RESPONSE: ", response.data);

                const {content} = response.data;

                dispatch(_setAllCustomers(filterCustomers(content)));
                dispatch(_setProcessing(false));
                dispatch(_setError(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("CUSTOMER ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}
// Gte All Customer By Rep id
export const getAllCustomersByRepIds = (userId, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));
        dispatch(_setSelectedCustomer(null));
        dispatch(_setAllStores([]));
        dispatch(_setSelectedStore(null));
        dispatch(_setSeason([]));
        dispatch(_setDivision([]));
        dispatch(_setSelectedDivision(null));
        dispatch(_setSelectedSeason(null));
        dispatch(_setGroup([]));
        dispatch(_setSelectedGroup(null));
        dispatch(_setAllItems([]));
        dispatch(_setSelectedProduct(null));

        const url = URLS.CustomerUrls.GetAllCustomerByRepsId + userId;

        axios.get(url)
            .then(response => {

                Logger.log("CUSTOMER RESPONSE: ", response.data);

                const {content} = response.data;

                dispatch(_setAllCustomers(filterCustomers(content)));
                dispatch(_setProcessing(false));
                dispatch(_setError(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("CUSTOMER ERROR: ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }
}
// Set Selected Customer
export const setSelectedCustomer = (customer, cb) => {
    Logger.log("Selected Customer in Actions: ", customer);
    return (dispatch) => {
        dispatch(_setCustomerProcessing(true));
        dispatch(_setSelectedCustomer(customer));

        dispatch(_setAllStores([]));
        dispatch(_setSelectedStore(null));
        dispatch(_setSelectedDivision(null));
        dispatch(_setSelectedSeason(null));
        dispatch(_setGroup([]));
        dispatch(_setSelectedGroup(null));

        dispatch(cartSelectedCustomer(customer));
        dispatch(_setCustomerProcessing(false));


        cb && cb(customer);
    }
}

// Get All Stores By Customer Number
export const getAllStoresByCustomerNumber = (customerNumber, cb) => {
    return (dispatch) => {
        Logger.log("Get All Stores By Customer ID with Customer Number: ", customerNumber);
        dispatch(_setStoreProcessing(true));
        dispatch(_setSelectedStore(null));

        axios.get(URLS.StoreUrls.GetAllByCustomerNumber + customerNumber)
            .then(response => {
                Logger.log("GET ALL STORES BY CUSTOMER NUMBER Response", response.data);


                const {content} = response.data;

                dispatch(_setAllStores(filetrStore(content)));
                dispatch(_setError(false));
                dispatch(_setStoreProcessing(false));


                cb && cb();

            })
            .catch(error => {
                Logger.log("GET ALL STORES BY CUSTOMER NUMBER Error", error);

                dispatch(_setError(true));
                dispatch(_setStoreProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }


}
// Set Selected Store
export const setSelectedStore = (store, cb) => {
    Logger.log("Selected Store in Actions: ", store);
    return (dispatch) => {
        dispatch(_setStoreProcessing(true));
        dispatch(_setSelectedStore(store));
        dispatch(_setStoreProcessing(false));

        dispatch(cartSelectedStore(store));


        cb && cb(store);
    }
}

// Set Selected Season
export const setSelectedSeason = (season, cb) => {
    Logger.log("Selected Season in Actions: ", season);
    return (dispatch) => {
        dispatch(_setCustomerProcessing(true));
        dispatch(_setSelectedSeason(season));
        dispatch(_setCustomerProcessing(false));


        cb && cb(season);
    }
}
// Set Selected Division
export const setSelectedDivision = (division, cb) => {
    Logger.log("Selected Division in Actions: ", division);
    return (dispatch) => {
        dispatch(_setCustomerProcessing(true));
        dispatch(_setSelectedDivision(division));
        dispatch(_setCustomerProcessing(false));


        cb && cb(division);
    }
}


// Get Season & Divisions
export const getLatestSeasonsAndDivisions = (cb) => {

    return (dispatch) => {
        dispatch(_setGroupProcessing(true));
        dispatch(_setError(false));

        axios.get(URLS.SeasonAndDivisionUrls.GetLatestSeasonAndDivisions)
            .then(response => {
                Logger.log("SEASON & DIVISION Response", response.data);

                const {content} = response.data;
                const {season, division} = content;

                dispatch(_setDivision(division));
                dispatch(_setSeason(season));
                dispatch(_setGroupProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("SEASON & DIVISION Error ", error);

                dispatch(_setGroupProcessing(false));
                dispatch(_setError(true));

                errorHandler(error);

                cb && cb(error);
            });
    }


}

// Get All Groups By Season & Division
export const getAllGroupsBySeasonsAndDivisions = (cb) => {


    return (dispatch, getStore) => {

        const season = getStore().order.selectedSeason;
        const division = getStore().order.selectedDivision;

        Logger.log("Getting groups By season : ", season);
        Logger.log("Getting groups By division : ", division);
        if (season && division) {
            dispatch(_setItemProcessing(true));
            dispatch(_setSelectedProduct(null));
            dispatch(_setAllProducts([]));
            dispatch(_setAllItems([]));
            dispatch(_setAllSizes([]));


            axios.get(URLS.ProductsUrls.GetAllGroupsBySeasonsAndDivisions + season.label + "/" + division.name)
                .then(response => {

                    Logger.log("All Groups Response: ", response.data);

                    const {content} = response.data;

                    dispatch(_setGroup(content));
                    dispatch(_setError(false));
                    dispatch(_setItemProcessing(false));


                    cb && cb();


                })
                .catch(error => {
                    Logger.error("All Groups ERROR: ", error);

                    dispatch(_setError(true));
                    dispatch(_setItemProcessing(false));

                    errorHandler(error);

                    cb && cb(error);
                });
        }
    }


}

// Set Selected Group
export const setSelectedGroup = (group, cb) => {
    Logger.log("Selected Group in Actions: ", group);
    return (dispatch) => {
        dispatch(_setCustomerProcessing(true));
        dispatch(_setSelectedGroup(group));
        dispatch(_setCustomerProcessing(false));


        cb && cb(group);
    }
}
// Get All Items By Season, Division And Group

export const getAllItemsBySeasonDivisionAndGroup = (cb) => {
    Logger.log("Getting all Items");
    return (dispatch, getStore) => {
        dispatch(_setProductProcessing(true));
        dispatch(_setSizeProcessing(true));
        dispatch(_setAllProducts([]));

        const season = getStore().order.selectedSeason;
        const division = getStore().order.selectedDivision;
        const group = getStore().order.selectedGroup;
        const filter = getStore().order.itemFilter;

        if (season && division && group) {


            const productFetcher = axios.get(URLS.ProductsUrls.GetAllItemsBySeasonsDivisionAndGroup + season.label + "/" + division.name + "/" + group.group);
            const availabilityFetcher = axios.getWithAES(URLS.ProductsUrls.GetStatusOfProductV2(season.label, division.name));

            Promise.all([productFetcher, availabilityFetcher])
                .then(promiseResponse => {

                    Logger.log("All Responses In Promises: ", promiseResponse);

                    const response = promiseResponse[0];
                    const statusResponse = promiseResponse[1].data.response.data.styles;
                    Logger.log("All Items Response: ", response.data);

                    const {content} = response.data;

                    dispatch(_setAllItems(parseItemsForSelectMenu(content, statusResponse, filter)));
                    const sizes = extractSizesFromAllItemsList(content);
                    const sizes_accessory = extractSizesFromAllItemsList(content, true);
                    // Logger.log("SIZES EXTRACTED: ", sizes);
                    dispatch(_setAllSizes(sizes));
                    dispatch(_setAllSizesAccessory(sizes_accessory));
                    dispatch(_setError(false));
                    dispatch(_setProductProcessing(false));
                    dispatch(_setSizeProcessing(false));


                    cb && cb({error: false, content: content});
                })
                .catch(error => {
                    Logger.log("All Items Error: ", error);

                    dispatch(_setError(true));
                    dispatch(_setProductProcessing(false));
                    dispatch(_setSizeProcessing(false));

                    errorHandler(error);


                    cb && cb({error: true, content: null});

                });
        }

    }
}

export const applyFilters = (allItems, filter, cb) => {

    return (dispatch => {

        let items = null;

        switch (filter) {
            case FILTER_PRICE_LOW_TO_HIGH:
                items = _.orderBy(allItems, ["cost"], ["asc"]);
                break;

            case FILTER_PRICE_HIGH_TO_LOW:
                items = _.orderBy(allItems, ["cost"], ["desc"]);
                break;

            case FILTER_STYLE_HIGH_TO_LOW:
                items = _.orderBy(allItems, ["fullStyle"], ["desc"]);
                break;

            case FILTER_STYLE_LOW_TO_HIGH:
                items = _.orderBy(allItems, ["fullStyle"], ["asc"]);
                break;

            default:
                items = _.orderBy(allItems, ["fullStyle"], ["asc"]);
                break;
        }

        dispatch(_setAllItems(items));
        dispatch(_setItemFilter(filter));

        cb && cb();

    });
}

// Get Item by Size Number

export const getItemBySizeNumber = (sizeNumber, cb) => {
    // Logger.log("Getting Items");
    return (dispatch, getStore) => {
        dispatch(_setProductProcessing(true));
        dispatch(_setSizeProcessing(true));
        dispatch(_setAllProducts([]));


        const productFetcher = axios.get(URLS.ProductsUrls.GetItemBySizeNumber + sizeNumber);
        const availabilityFetcher = axios.getWithAES(URLS.ProductsUrls.GetStatusOfProductByItemV2(sizeNumber));

        Promise.all([productFetcher, availabilityFetcher])
            .then(promiseResponse => {

                Logger.log("All Responses In Promises: ", promiseResponse);

                const response = promiseResponse[0];
                const statusResponse = promiseResponse[1].data.response.data.styles;
                Logger.log("All Items Response: ", response.data);

                const {content} = response.data;

                let products = content;

                if(!Array.isArray(products)) {
                    products = [products];
                }

                dispatch(_setAllItems(parseItemsForSelectMenu(products, statusResponse)));
                const sizes = extractSizesFromAllItemsList(products);
                const sizes_accessory = extractSizesFromAllItemsList(products, true);
                // Logger.log("SIZES EXTRACTED: ", sizes);
                dispatch(_setAllSizes(sizes));
                dispatch(_setAllSizesAccessory(sizes_accessory));
                dispatch(_setError(false));
                dispatch(_setProductProcessing(false));
                dispatch(_setSizeProcessing(false));


                cb && cb({error: false, content: content});
            })
            .catch(error => {
                Logger.log("All Items Error: ", error);

                dispatch(_setError(true));
                dispatch(_setProductProcessing(false));
                dispatch(_setSizeProcessing(false));

                errorHandler(error);


                cb && cb({error: true, content: null});

            });

    }
}

export const setSelectedProduct = (item, cb) => {
    Logger.log("Selected Product in Actions: ", item);
    return (dispatch) => {
        dispatch(_setCustomerProcessing(true));
        dispatch(_setSelectedProduct(item));
        dispatch(_setCustomerProcessing(false));


        cb && cb(item);
    }
}

// @Deprecated
export const getAllSizesWithSizeNumber = (sizeNumber, cb) => {
    Logger.log("Getting all Sizes");

    return (dispatch) => {

        dispatch(_setSizeProcessing(true));

        axios.get(URLS.SizeUrls.GetSizeBySizeNumber + sizeNumber)
            .then(response => {
                Logger.log("Sizes Response : ", response.data);

                const {content} = response.data;

                dispatch(_setAllSizes(setSizesForProduct(content)));
                dispatch(_setError(false));
                dispatch(_setSizeProcessing(false));

                cb && cb();


            })
            .catch(error => {
                Logger.error("Sizes Error : ", error);
                dispatch(_setError(true));
                dispatch(_setSizeProcessing(false));

                errorHandler(error);

                cb && cb(error);
            });
    }


}
export const getAllSizes = (cb) => {
    Logger.log("Getting all Sizes");
    return (dispatch, getStore) => {
        dispatch(_setSizeProcessing(true));

        axios.get(URLS.SizeUrls.GetAllSizes)
            .then(response => {
                Logger.log("All Sizes Response : ", response.data);

                const {content} = response.data;
                const allItems = getStore().order.allItems;


                dispatch(_setAllSizes(content));
                dispatch(_setAllItemsParsed(mapSizesToItems(content, allItems)));
                dispatch(_setError(false));
                dispatch(_setSizeProcessing(false));

                cb && cb();


            })
            .catch(error => {
                Logger.error("All Sizes Error : ", error);
                dispatch(_setError(true));
                dispatch(_setSizeProcessing(false));

                errorHandler(error);

                cb && cb(error);

            });
    }
}

export const addOrder = (order, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));


        const parsedOrder = getParsedOrder(order);
        Logger.log("PARSED ORDER", JSON.stringify(parsedOrder));

        axios.post(URLS.OrderUrls.AddOrder, {"order": parsedOrder})
            .then(response => {
                Logger.log("Add Order Response : ", response.data);

                const {content} = response.data;

                dispatch(_setPlacedOrder(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb(content, false);

            })
            .catch(error => {
                Logger.error("Add Order Error : ", error);
                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error, true);

            });

    }
};

export const getAllOrdersByUser = (data, cb) => {
    return (dispatch) => {
        // dispatch(_setProcessing(true));
        dispatch(_setStoreProcessing(true));

        axios.post(URLS.OrderUrls.GetAllOrdersByUser, data)
            .then(response => {
                Logger.log("Get All Orders By User Response : ", response.data);
                const {
                    content,
                    page,
                    limit,
                    totalPages,
                    next,
                    dataCount,
                    totalDocuments
                } = response.data;


                dispatch(_setAllOrders(content));
                dispatch(_setLimit(limit));
                dispatch(_setDataCount(dataCount));
                dispatch(_setNextPage(next));
                dispatch(_setCurrentPage(page));
                dispatch(_setTotalPages(totalPages));
                dispatch(_setTotalDocuments(totalDocuments));
                dispatch(_setError(false));
                // dispatch(_setProcessing(false));
                dispatch(_setStoreProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("Get All Orders By User Error : ", error);
                dispatch(_setError(true));
                // dispatch(_setProcessing(false));
                dispatch(_setStoreProcessing(false));

                errorHandler(error);

                cb && cb(error);


            });

    }
};

export const getAllOrdersFromTransactionWithUUID = (uuid, cb) => {
    return (dispatch => {

        dispatch(_setProcessing(true));
        dispatch(_setPlacedOrder(null));


        axios.get(URLS.OrderUrls.GetAllOrdersFromTransactionByUUID + uuid)
            .then(response => {
                Logger.log("Get All Orders By Transaction By UUID | Response : ", response.data);


                const {content} = response.data;

                dispatch(_setPlacedOrder(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("Get All By Transaction UUID | Error : ", error);
                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);

            });
    });
}

// Get All Visible Order and Shipping Type
export const getAllVisibleOrderAndShippingTypes = (cb) => {

    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.get(URLS.OrderUrls.GetVisibleOrderAndShippingTypes)
            .then(response => {
                Logger.log("Get All Visible Order and Shipping Types | Response : ", response.data);

                const {content} = response.data;

                const {orderType, shippingType} = content;


                dispatch(_setAllOrderTypes(orderType));
                dispatch(_setAllShippingTypes(shippingType));

                dispatch(_setProcessing(false));


                cb && cb();


            })
            .catch(error => {
                Logger.error("Get All Visible Order and Shipping Types | Error : ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);

            });
    });

}
// get All Divisions
export const getAllDivisions = (cb) => {


    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.get(URLS.SeasonAndDivisionUrls.GetAllDivisions)
            .then(response => {
                Logger.log("Get All Divisions | Response : ", response.data);

                const {content} = response.data;


                dispatch(_setDivision(content));

                dispatch(_setProcessing(false));


                cb && cb();
            })
            .catch(error => {
                Logger.error("Get All Divisions | Error : ", error);

                dispatch(_setError(true));
                dispatch(_setProcessing(false));

                errorHandler(error);

                cb && cb(error);
            })
    })
}

// Send Notification Email
export const sendNotificationEmail = (uuid, cb) => {

    return (dispatch => {

        axios.get(URLS.EmailUrls.SendNotificationEmail + uuid)
            .then(repsonse => {
                Logger.log("EMAIL SENT SUCCESSFULLY", repsonse);
                cb && cb();
            })
            .catch(error => {
                Logger.error("FAIL TO SEND EMAILS", error);
                cb && cb();

            });
    })
}

export const sendEmbroideryAlertEmail = (uuid, cb) => {

    return (dispatch => {

        axios.get(URLS.EmailUrls.SendEmbroideryAlert +  uuid)
            .then(repsonse => {
                Logger.log("EMBROIDERY ALERT EMAIL SENT SUCCESSFULLY", repsonse);
                cb && cb();
            })
            .catch(error => {
                Logger.error("FAIL TO SEND EMAILS", error);
                cb && cb();

            });
    })
}

// Send Credit Email
export const sendCreditAppEmail = (email, cb) => {

    return (dispatch => {
        dispatch(_setProcessing(true));
        axios.getWithAES(URLS.CreditApp.SendCreditAppEmail(email))
            .then(response => {
                Logger.log("Credit App  SUCCESSFULLY", response);
                dispatch(_setProcessing(false));
                const res = response.data.response;
                cb && cb(res);
            })
            .catch(error => {
                dispatch(_setProcessing(false));
                Logger.error("CREDIT APP ERROR", error);
                cb && cb(false);
            });
    });


}

// Dispatch Actions

const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
const _setAllCustomers = (payload) => {
    return {
        type: Actions.SET_ALL_CUSTOMERS,
        payload,
    };
};
const _setSelectedCustomer = (payload) => {
    return {
        type: Actions.SET_SELECTED_CUSTOMER,
        payload,
    };
};
const _setCustomerProcessing = (payload) => {
    return {
        type: Actions.SET_CUSTOMER_PROCESSING,
        payload,
    };
};
const _setAllStores = (payload) => {
    return {
        type: Actions.SET_ALL_STORES,
        payload,
    };
};
const _setSelectedStore = (payload) => {
    return {
        type: Actions.SET_SELECTED_STORE,
        payload,
    };
};
const _setStoreProcessing = (payload) => {
    return {
        type: Actions.SET_STORES_PROCESSING,
        payload,
    };
};
const _setAllProducts = (payload) => {
    return {
        type: Actions.SET_ALL_PRODUCTS,
        payload,
    };
};
const _setSelectedProduct = (payload) => {
    return {
        type: Actions.SET_SELECTED_PRODUCT,
        payload,
    };
};
const _setProductProcessing = (payload) => {
    return {
        type: Actions.SET_PRODUCTS_PROCESSING,
        payload,
    };
};
const _setSeason = (payload) => {
    return {
        type: Actions.SET_SEASON,
        payload,
    };
};
const _setDivision = (payload) => {
    return {
        type: Actions.SET_DIVISION,
        payload,
    };
};
const _setGroup = (payload) => {
    return {
        type: Actions.SET_GROUP,
        payload,
    };
};
const _setGroupProcessing = (payload) => {
    return {
        type: Actions.SET_GROUP_PROCESSING,
        payload,
    };
};
const _setSelectedDivision = (payload) => {
    return {
        type: Actions.SET_SELECTED_DIVISION,
        payload,
    };
};
const _setSelectedSeason = (payload) => {
    return {
        type: Actions.SET_SELECTED_SEASON,
        payload,
    };
};
const _setSelectedGroup = (payload) => {
    return {
        type: Actions.SET_SELECTED_GROUP,
        payload,
    };
};
const _setAllItems = (payload) => {
    return {
        type: Actions.SET_ALL_ITEMS,
        payload,
    };
};
const _setAllItemsParsed = (payload) => {
    return {
        type: Actions.SET_ALL_ITEMS_PARSED,
        payload,
    };
};
const _setItemProcessing = (payload) => {
    return {
        type: Actions.SET_ITEM_PROCESSING,
        payload,
    };
};
export const _setAllSizes = (payload) => {
    return {
        type: Actions.SET_ALL_SIZES,
        payload,
    };
};
export const _setAllSizesAccessory = (payload) => {
    return {
        type: Actions.SET_ALL_SIZES_ACCESSORY,
        payload,
    };
};
const _setSizeProcessing = (payload) => {
    return {
        type: Actions.SET_SIZE_PROCESSING,
        payload,
    };
};
const _setPlacedOrder = (payload) => {
    return {
        type: Actions.SET_PLACED_ORDER,
        payload,
    };
};

const _setAllOrders = (payload) => {
    return {
        type: Actions.SET_ALL_ORDERS,
        payload
    }
}

const _setLimit = (payload) => {
    return {
        type: Actions.SET_LIMIT,
        payload
    }
}
const _setTotalPages = (payload) => {
    return {
        type: Actions.SET_TOTAL_PAGES,
        payload
    }
}
const _setDataCount = (payload) => {
    return {
        type: Actions.SET_DATA_COUNT,
        payload
    }
}
const _setTotalDocuments = (payload) => {
    return {
        type: Actions.SET_TOTAL_DOCUMENTS,
        payload
    }
}
const _setNextPage = (payload) => {
    return {
        type: Actions.SET_NEXT_PAGE,
        payload
    }
}
const _setCurrentPage = (payload) => {
    return {
        type: Actions.SET_CURRENT_PAGE,
        payload
    }
}

export const _resetState = (payload) => {
    return {
        type: Actions.RESET_STATE,
        payload
    }
}
const _setAllOrderTypes = (payload) => {
    return {
        type: Actions.SET_ORDER_TYPES,
        payload
    }
}
// const _setSelectedOrderType = (payload) => {
//     return {
//         type: Actions.SET_SELECTED_ORDER_TYPE,
//         payload
//     }
// }
const _setAllShippingTypes = (payload) => {
    return {
        type: Actions.SET_SHIPPING_TYPES,
        payload
    }
}
// const _setSelectedShippingType = (payload) => {
//     return {
//         type: Actions.SET_SELECTED_SHIPPING_TYPE,
//         payload
//     }
// }
export const _setPreLoadedCustomerAndStore = (payload) => {
    return {
        type: Actions.SET_PRELOADED_CUSTOMER_AND_STORE,
        payload
    }
}
export const _setItemFilter = (payload) => {
    return {
        type: Actions.SET_ITEM_FILTER,
        payload
    }
}
