// URL CONFIGS
export const URLS_CONFIGS = {
    // BASE URLS
    BASE_URL_DEV: window.config.BASE_URL_DEV,
    BASE_URL_PROD: window.config.BASE_URL_PROD,
    BASE_URL_STAGE: window.config.BASE_URL_STAGING,

    // URL PREFIX
    API_PREFIX: window.config.API_PREFIX,

    // VERSION PREFIX
    VERSION_ONE_PREFIX: window.config.VERSION_ONE_PREFIX,

};
// MODULES PREFIX
export const MODULES_PREFIX = {
    // AUTHENTICATION PREFIX
    AUTH_PREFIX: "",

    // CUSTOMER PREFIX
    CUSTOMER_PREFIX: "customer",

    // SEASON AND DIVISION PREFIX
    SEASON_AND_DIVISION_PREFIX: "seasonAndDivision",

    // STORE PREFIX
    STORE_PREFIX: "store",

    // PRODUCT PREFIX
    PRODUCT_PREFIX: "products",

    // SIZE PREFIX
    SIZE_PREFIX: "size",

    // Order Prefix
    ORDER_PREFIX: "order",

    // Email Prefix
    EMAIL_PREFIX: "email",

    // User Prefix
    USER_PREFIX: "user",

    // Roles Prefix
    ROLE_PREFIX: "role",

    // Cart Storage Prefix
    CART_PREFIX: "cart",

    // Order Reports Prefix
    ORDER_REPORTS_PREFIX: "reports",

    // Error Log
    ERROR_LOG_PREFIX: "error-log",
}


// AUTHENTICATION URLS
export const AuthenticationUrls = {
    // LOGIN
    Login: MODULES_PREFIX.AUTH_PREFIX + "login"
}

// ORDER URLS
export const OrderUrls = {
    // ADD ORDER
    AddOrder: MODULES_PREFIX.ORDER_PREFIX + "/",

    // Get All Order By User (Using JWT)/
    // GetAllOrdersByUser : MODULES_PREFIX.ORDER_PREFIX + "/getAllOrdersByUser",

    // Get All Transactions By User (Using JWT)
    GetAllOrdersByUser: MODULES_PREFIX.ORDER_PREFIX + "/getAllTransactionsByUser",

    // Get All Orders From Transaction By UUID
    GetAllOrdersFromTransactionByUUID: MODULES_PREFIX.ORDER_PREFIX + "/getOrderFromTransactionWithUUID/",


    // Get Visible Order and Shipping Types
    GetVisibleOrderAndShippingTypes: MODULES_PREFIX.ORDER_PREFIX + "/getVisibleOrderAndShippingType",
}


// CUSTOMER URLS
export const CustomerUrls = {
    // Get All Customers By Salesman Id
    GetAllCustomerBySalesmanId: MODULES_PREFIX.CUSTOMER_PREFIX + "/getAllBySalesmanId/",
    // Get All Customers By Reps Ids
    GetAllCustomerByRepsId: MODULES_PREFIX.CUSTOMER_PREFIX + "/getAllByReps/",

    // Get All Customers
    GetAllCustomers: MODULES_PREFIX.CUSTOMER_PREFIX + "/getAll",

}

// SEASON & DIVISION URLS
export const SeasonAndDivisionUrls = {
    // Get Latest Season And Divisions
    GetLatestSeasonAndDivisions: MODULES_PREFIX.SEASON_AND_DIVISION_PREFIX + "/getLatest",

    // Get All Divisions
    GetAllDivisions: MODULES_PREFIX.SEASON_AND_DIVISION_PREFIX + "/getAll",
}
// STORES URLS
export const StoreUrls = {
    // Get All By Customer Number
    GetAllByCustomerNumber: MODULES_PREFIX.STORE_PREFIX + "/getAllByCustomerNumber/"
}
// PRODUCTS URLS
export const ProductsUrls = {
    // Get All Groups By Customer Number
    GetAllGroupsBySeasonsAndDivisions: MODULES_PREFIX.PRODUCT_PREFIX + "/allGroups/",

    // Get All Items By Season, Division & Group
    GetAllItemsBySeasonsDivisionAndGroup: MODULES_PREFIX.PRODUCT_PREFIX + "/allItems/",

    // Get Product Status
    GetStatusOfProduct: (season, division) => `https://prodtools-api.shopluckyinlove.com/reports/getstylereport?season=${season}&division=${division}`,

    // Get Product Status V2
    GetStatusOfProductV2: (season, division) => `https://prodtools-api.shopluckyinlove.com/reports/getstylereport/v2?season=${season}&division=${division}`,

    // Get Status Of Product By Item
    GetStatusOfProductByItem: (style) => `https://prodtools-api.shopluckyinlove.com/reports/getstylereport?style=${style}`,

    // Get Status Of Product By Item V2
    GetStatusOfProductByItemV2: (style) => `https://prodtools-api.shopluckyinlove.com/reports/getstylereport/v2?style=${style}`,

    // Get Item By Size Number
    GetItemBySizeNumber: MODULES_PREFIX.PRODUCT_PREFIX + "/getItemsByStyleNumber/",
    // GetItemBySizeNumber: MODULES_PREFIX.PRODUCT_PREFIX + "/getItemsByStyleNumber/",

}
// SIZE URLS
export const SizeUrls = {
    // Get Size By Size Number
    GetSizeBySizeNumber: MODULES_PREFIX.SIZE_PREFIX + "/getBySizeNumber/",

    // Get All Sizes
    GetAllSizes: MODULES_PREFIX.SIZE_PREFIX + "/",
}
// EMAIL URLS
export const EmailUrls = {
    // Get Size By Size Number
    SendEmailWithAttachment: MODULES_PREFIX.EMAIL_PREFIX + "/confirmationEmail",

    // Send Notification Email
    SendNotificationEmail: MODULES_PREFIX.EMAIL_PREFIX + "/notificationEmail/",

    // Send EmbroideryAlert Email
    SendEmbroideryAlert: MODULES_PREFIX.EMAIL_PREFIX + "/embroideryAlertEmail/",

    // Send Support Email
    SendSupportEmail: MODULES_PREFIX.EMAIL_PREFIX + "/supportEmail",

    // Send Individual Delivery Email
    SendIndividualDeliveryEmail: MODULES_PREFIX.EMAIL_PREFIX + "/individualDeliveryEmail/",

    // Send ATS Report Email
    SendATSReportEmail: MODULES_PREFIX.EMAIL_PREFIX + "/atsReportsEmail/",

    // Send Cart Storage Email
    SendCartStorageEmail: MODULES_PREFIX.EMAIL_PREFIX + "/cartStorageEmail/",
}
// USER URLS
export const UserUrls = {
    // Get All Users
    GetAllUsers: MODULES_PREFIX.USER_PREFIX + "/getAll",

    // Get All Salesman
    GetAllSalesman: MODULES_PREFIX.USER_PREFIX + "/getAllSalesman",

    // Add New User
    AddNewUser: MODULES_PREFIX.USER_PREFIX + "/",

    // Change Admin Status
    ChangeAdminStatus: MODULES_PREFIX.USER_PREFIX + "/changeAdminStatus",

    // Change User Status
    ChangeStatus: MODULES_PREFIX.USER_PREFIX + "/changeStatus",

    // Change Password
    ChangePassword: MODULES_PREFIX.USER_PREFIX + "/changePassword",

    // Get User By Id
    GetUserById: MODULES_PREFIX.USER_PREFIX + "/get/",

    // Update User
    UpdateUser: MODULES_PREFIX.USER_PREFIX + "/udpate",

    // Get All Users By Parent Role Id
    GetAllUsersByParentUserId: MODULES_PREFIX.USER_PREFIX + "/getAllByParentRoleId/",

    // Open Slot For Change Password
    OpenSlotForChangePassword : MODULES_PREFIX.USER_PREFIX + "/forgot-password/",

    // Get User For Change Password
    GetUserForForgotPassword : MODULES_PREFIX.USER_PREFIX + "/getUserForForgotPassword/"


}

// AUTHENTICATION URLS
export const RolesUrls = {
    // LOGIN
    GetAll: MODULES_PREFIX.ROLE_PREFIX + "/getAll"
}

// AUTHENTICATION URLS
export const CartStorageUrls = {
    // Get All Cart by User ID
    GetAllByUser: MODULES_PREFIX.CART_PREFIX + "/",

    // Get All Cart by Role ID
    GetAllByRoleId: MODULES_PREFIX.CART_PREFIX + "/role/",

    // Save All Cart
    SaveCartStorageByUser: MODULES_PREFIX.CART_PREFIX + "/",

    // Delete Cart by ID
    DeleteCartStorageByUser: MODULES_PREFIX.CART_PREFIX + "/",
}

// AUTHENTICATION URLS
export const OrderReportsUrl = {
    // Get Yearly Reports
    GetLastYearReports: MODULES_PREFIX.ORDER_REPORTS_PREFIX + "/getLastYearOrdersReport",

    // Get Last Year Reports by User Id
    GetLastYearReportsByUserId: MODULES_PREFIX.ORDER_REPORTS_PREFIX + "/getLastYearOrdersReportByCreatedBy",

    // Get Last year reports by users
    GetLastYearReportsByUsers: MODULES_PREFIX.ORDER_REPORTS_PREFIX + "/getLastYearOrdersReportByUsers",
}

// Error Log

export const ErrorLogUrl = {
    // Log Error
    LogError : MODULES_PREFIX.ERROR_LOG_PREFIX + "/log"
}


export const CreditApp = {
    SendCreditAppEmail: (email) => `https://prodtools-api.shopluckyinlove.com/creditforms/generatelink?email=${email}`,
}
