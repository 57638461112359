// Library
import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import _ from "lodash";
// Helpers
import {toaster} from "../../../Utils/Helpers";
// Redux Actions
import {getAllUsers, changeAdminStatus, changeStatus} from "../../../Store/User/actions";
// Components
import Loader from "../../../Components/Loader";
import Table from '../../../Components/Table';
import FontIcon from '../../../Components/FontIcon';
// Icons
import {faCheck, faTimes, faKey, faEdit} from "@fortawesome/free-solid-svg-icons";
// Container
import PageContainer from "../../../Containers/PageContainer";
// Helper
import {confirmSWAL} from '../../../Utils/Helpers';
// Logger
import Logger from "../../../Utils/Logger";

const ManageUser = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);

    // State
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // USER EFFECT
    useEffect(() => {
        dispatch(getAllUsers());
        setCurrentPage(1);
        setTotalPages(1)
    }, []);

    const changePassword = (id) => {
        Logger.log("CHANGE PASSWORD");
        history.push("/user-management/change-password/" + id)
    }
    const updateUser = (id) => {
        Logger.log("UPDATE USER");
        history.push("/user-management/update/" + id)
    }

    const handleChangeAdminStatus = (user) => {
        Logger.log("Check Confirm Status : ", user);

        const data = {
            isAdmin: !user.isAdmin,
            id: user.id
        }

        dispatch(changeStatus(data, (err) => {
            if (!err) {
                dispatch(getAllUsers());
            }
        }));

    }

    const handleChangeStatus = (user) => {
        Logger.log("Check Confirm Status : ", user);

        const data = {
            isEnabled: !user.enabled,
            id: user.id
        }

        dispatch(changeStatus(data, (err) => {
            if (!err) {
                dispatch(getAllUsers());
            }
        }));

    }


    // Table Config
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (value, item, index) => (
                <>
                    {item.name}
                </>
            )
        },
        {
            title: "Username",
            dataIndex: "username",
            render: (value, item, index) => (
                <>
                    {item.username}
                </>
            )
        },
        {
            title: "Admin",
            dataIndex: "isAdmin",
            render: (value, item, index) => {

                if (item.isAdmin) {
                    return <FontIcon
                        style={{cursor: "pointer"}}
                        onClick={() => confirmSWAL("Change Admin Status?", () => handleChangeAdminStatus(item), "Yes")}
                        icon={faCheck} className={"text-success"}
                    />
                } else {
                    return <FontIcon
                        style={{cursor: "pointer"}}
                        onClick={() => confirmSWAL("Change Admin Status?", () => handleChangeAdminStatus(item), "Yes")}
                        icon={faTimes} className={"text-danger"}
                    />
                }
            }
        },
        {
            title: "Enabled",
            dataIndex: "enabled",
            render: (value, item, index) => {

                if (item.enabled) {
                    return <FontIcon
                        style={{cursor: "pointer"}}
                        onClick={() => confirmSWAL("You are going to lock that user?", () => handleChangeStatus(item), "Yes")}
                        icon={faCheck} className={"text-success"}
                    />
                } else {
                    return <FontIcon
                        style={{cursor: "pointer"}}
                        onClick={() => confirmSWAL("You are going to unlock that user?", () => handleChangeStatus(item), "Yes")}
                        icon={faTimes} className={"text-danger"}
                    />
                }
            }
        },
        {
            title: "Role",
            dataIndex: "salesMan",
            render: (value, item, index) => {
                return <span>{item.role.name}</span>
            }
        },
        {
            title: "Divisions",
            dataIndex: "userDivisions",
            render: (value, item, index) => {
                const div = [];
                _.map(item.userDivisions, (divisions, index) => {
                    div.push(divisions.name);
                })

                return <span className={"label label-primary"}>{div.join(" | ")}</span>
            }
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (value, item, index) => {
                return (
                    <>
                        <FontIcon
                            onClick={() => changePassword(item.id)}
                            style={{cursor: "pointer", margin: "0px 2px"}}
                            icon={faKey}
                            title={"Change Password"}
                            className={"text-info"}
                        />
                        <FontIcon
                            onClick={() => updateUser(item.id)}
                            style={{cursor: "pointer", margin: "0px 2px"}}
                            icon={faEdit}
                            title={"Update"}
                            className={"text-warning"}
                        />
                    </>
                )
            }
        }
    ];

    // Checking For Admin
    useEffect(() => {
        if (!userState.isAdmin) {
            toaster("You are not allowed to see this page", "danger");
            history.push("/");
        }

    }, []);
    return (
        <PageContainer>

            {userState.processing ? (
                <>
                    <Loader/>
                </>
            ) : (
                <>
                    <Row>
                        <Col sm={{span: 12}}>

                            <h1 className={"text-center"}>All Users</h1>
                            <Table
                                rowKey={"id"}
                                loading={userState.processing}
                                columns={columns}
                                dataSource={userState.allUsers}
                                // changePageHandler={changePageHandler}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                // paginated
                            />

                        </Col>
                    </Row>

                </>
            )}
        </PageContainer>
    )
}

export default ManageUser;
