// Library
import React from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {useSelector} from "react-redux";
// Pages
import BasePage from "./BasePages";
import Login from "../Pages/Authentication/Login";
import Logger from "../Utils/Logger";
import ForgotPassword from "../Pages/ForgotPassword";
import ForgotPasswordImpl from "../Pages/ForgotPasswordImpl";
// Layout
import AppLayout from "../Layouts/AppLayout";

export function Routes() {
    const isAuthorized = useSelector(({user}) => user.authenticated);
    // Logger.log("AUTHORIZED", isAuthorized);
    document.title = window.config.TITLE;
    return (
        <Switch>
            {!isAuthorized ? (
                <Route path={'/auth/login/'} component={Login}/>

            ) : (
                <Redirect from="/auth" to="/"/>
            )}

            <Route path={'/auth/forgot-password/:uuid'} component={ForgotPasswordImpl}/>
            <Route path={'/auth/forgot-password'} component={ForgotPassword}/>

            {/*<Route path="/error" component={ErrorsPage}/>*/}
            {/*<Route path="/logout" component={Logout}/>*/}

            {!isAuthorized ? (
                <Redirect to="/auth/login"/>
            ) : (
                <AppLayout>
                    <BasePage/>
                </AppLayout>
            )}
        </Switch>
    );
}
