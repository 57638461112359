// Library
import React from 'react';
import Select from 'react-select';
// Logger
import Colors from "../../Config/Colors";
import {useSelector} from "react-redux";

const ReactSelect = (props) => {

    const {themeMode} = useSelector(state => state.theme);

    const {value, changer, data, valueIndex, keyIndex, placeholder, multi, isDisabled} = props;

    let key = keyIndex ? keyIndex : "id";
    let showLabel = valueIndex ? valueIndex : "name";

    const selectedValue = multi ? value : props.data.filter(option => {
        return option[key] === value;
    });

    const selectBg = themeMode === 'light' ? Colors.white : Colors.black;
    const color = themeMode === 'light' ? Colors.dark : Colors.light;
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: selectBg,
            color:color

        }),
        menuPortal: base => ({...base, zIndex: 9999}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: selectBg,
                color: color,
                "&:hover": {
                    color: selectBg,
                    backgroundColor : color
                }
            };
        }
    };

    const selectTheme = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            neutral80 : color
        },
    })

    return (
        <Select
            placeholder={placeholder ? placeholder : "Please Select"}
            isSearchable
            isDisabled={isDisabled ? isDisabled : false}
            value={selectedValue}
            onChange={changer}
            menuPortalTarget={document.body}
            styles={customStyles}
            theme={selectTheme}
            options={data}
            getOptionLabel={(option) => option[showLabel]}
            getOptionValue={(option) => option[key]}
            isMulti={multi}
        />
    );
}


export default ReactSelect;
