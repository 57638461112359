// Action Types
import * as Actions from './action-types';

export const logTrack = (data, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));

        dispatch(_setTracker(data));

        dispatch(_setProcessing(false));

        dispatch(_toggleAsyncStorage(true));
        cb && cb(data);
    })
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
// const _setError = (payload) => {
//     return {
//         type: Actions.SET_ERROR,
//         payload,
//     };
// };
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
const _setTracker = (payload) => {
    return {
        type: Actions.SET_TRACKER,
        payload,
    };
};
export const _toggleAsyncStorage = (payload) => {
    return {
        type: Actions.TOGGLE_STORAGE,
        payload,
    };
};