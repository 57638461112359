// Action Types
import * as Actions from './action-types';
// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    attachment: null,
    to: null

};

const reducer = (state = initialState, action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;

        case Actions.SET_ATTACHMENT:
            newState.attachment = payload;
            break;

        case Actions.SET_TO:
            newState.to = payload;
            break;

        default:
            break;
    }

    return newState;
}

export default reducer;
