// Library
import React from 'react';
// Colors

const CenterContainer = (props) => {
    return (
        <>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-md-12 h-100">
                        <div className=" h-100 d-flex justify-content-center align-items-center">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default CenterContainer;
