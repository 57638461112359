// Config
import {Config} from './index';

const imageFolder = Config.FOLDER_SEPARATOR + Config.ASSETS_FOLDER + Config.FOLDER_SEPARATOR + Config.IMAGES_FOLDER + Config.FOLDER_SEPARATOR;
// All Pictures
const Pictures =  {
    Logo: imageFolder + "lil-logo.png",

    LogoLight: imageFolder + "lil-logo-light.png",

    LoginBG: imageFolder + 'login-bg.jpeg',

    HomePageBg: imageFolder + 'lil-bg.jpeg',

    MaintenancePageBg: imageFolder + 'maintenance-bg.jpeg',

    ErrorPage : imageFolder + "404.jpg"
}

export default Pictures;
