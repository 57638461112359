// Redux
import thunk from 'redux-thunk';
import {
    createStore,
    applyMiddleware,
    combineReducers,
    compose
} from 'redux';
// Config
import {Config} from '../Config';
// Stores
import user from './User/reducer';
import order from './Order/reducer';
import cart from './Cart/reducer';
import email from './Email/reducer';
import role from './Roles/reducer';
import orderReports from './OrderReports/reducer';
import theme from './Theme/reducer';
import error from './ErrorLog/reducer';
import tracker from './Tracker/reducer';

const reducer = combineReducers({
    theme,
    user,
    order,
    cart,
    email,
    role,
    orderReports,
    error,
    tracker
});
const composeEnhancer = (Config.ENV === 'DEV' || Config.ENV === "STAGE") ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = createStore(
    reducer,
    composeEnhancer(
        applyMiddleware(thunk)
    )
);


export default store;
