// Library
import React, {useState, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Table} from 'react-bootstrap';
import _ from 'lodash';
import {faTimes, faColumns, faPenNib, faCut} from "@fortawesome/free-solid-svg-icons";
import {Tab, Tabs, Box, Typography} from '@mui/material';
// Constants
import {SIZES_NAME} from '../../Utils/CommonConstants';
// Components
import Button from '../../Components/Button';
import DatePickerV2 from "../../Components/DatePickerV2";
import FontIcon from "../../Components/FontIcon";
// Logger
import Logger from "../../Utils/Logger";
// Redux Actions
import {
    changeDeliveryDateInCart,
    deleteItemFromCart,
    setMaxDeliveryDateForAllItems,
    _setChildOpenForEdit,
    _setChildOpen, splitItem, setEditItem, _setChildOpenForEmbroidery, _toggleAsyncStorage
} from '../../Store/Cart/action';
// Helpers
import {getNewDate, priceViewer, getSizesGreaterThanZero, getAvailableSizes} from '../../Utils/Helpers';
import ToggleButton from "../../Components/ToggleButton";

const CartViewer = (props) => {
    const cartState = useSelector(state => state.cart);
    const {cartDetailRevamped} = cartState;
    const {themeMode} = useSelector(state => state.theme);
    const [value, setValue] = React.useState(0);
    const [uniqueDeliveryDates, setUniqueDeliveryDates] = useState([]);
    const [minMaxDeliveryDate, setMinMaxDeliveryDate] = useState(null);

    const dispatch = useDispatch();

    // const bg = themeMode === 'light' ? Colors.gray100 : Colors.gray1000;

    const cartChildToggle = () => {
        if (cartState.openChild) {
            dispatch(_setChildOpen(false));
        } else {
            dispatch(_setChildOpen(true));
        }
    }

    const cartEmbroideryChildToggle = () => {
        if (cartState.openChildForEmbroidery) {
            dispatch(_setChildOpenForEmbroidery(false));
        } else {
            dispatch(_setChildOpenForEmbroidery(true));
        }
    }

    // Set Delivery Date
    const setDeliveryDate = (date, productId, userDefinedDate = false) => {
        // Logger.log("Selected Date: ", date);
        // Logger.log("Product ID: ", productId);
        dispatch(changeDeliveryDateInCart(date, productId, userDefinedDate));
    }


    const calculateEmbroideryCost = () => {
        let totalCost = 0;

        cartDetailRevamped.forEach(item => {
            if (item.embroideryAvailable && item.shouldAddEmbroidery && item.embroideryDetails.length > 0) {
                const totalQuantity = item.qty.totalQuantity;
                totalCost += totalQuantity * window.config.EMBROIDERY_COST_PER_ITEM;
            }
        });

        return totalCost;

    }


    const displayTotalItemsValue = () => {
        const total = [];
        _.map(cartDetailRevamped, (cart, index) => {
            const sum = parseFloat(cart.qty.totalQuantity) * parseFloat(cart.cost);
            total.push(sum);
        });


        // Logger.log("TOTAL SUM: ", _.sum(total));
        const sum =  _.sum(total);
        let returner = priceViewer(sum);
        if(calculateEmbroideryCost()) {
            returner = priceViewer(sum) + " + " + priceViewer(calculateEmbroideryCost()) + " = " + priceViewer(sum + calculateEmbroideryCost());
        }

        return returner;

    }

    const maxCartDeliveryDateHandler = (date) => {
        // Logger.log("DATE: ", date);
        dispatch(setMaxDeliveryDateForAllItems(cartState.cartDetailRevamped, date));

    }


    const getDatePickerV2 = (singleCart) => {

        let date = getNewDate(singleCart.changeableDeliveryDate.original, null, true);
        let minDate = getNewDate(singleCart.deliveryDate.original) < getNewDate() ? getNewDate() : getNewDate(singleCart.deliveryDate.original, null, true);

        const itemAvailability = singleCart.itemAvailability;
        if (itemAvailability && itemAvailability.length > 0) {
            minDate = getNewDate(itemAvailability[0].deliveryDate.original);
        }


        Logger.log("CART DATE", date);
        Logger.log("CART MIN DATE", minDate);


        // Logger.log("RENDERING THE DATE FIELD NOW...");

        return (
            <DatePickerV2
                value={date}
                minDate={minDate}
                changer={(date) => {
                    Logger.log("DATE CHANGED:", date);
                    return setDeliveryDate(date, singleCart.id, true)
                }}
            />
        )
    }

    useEffect(() => {
        setUniqueDeliveryDates(_.uniqBy(cartDetailRevamped, p => p.changeableDeliveryDate.formatted));
        Logger.log("=== Unique Delivery Dates", uniqueDeliveryDates);

    }, [cartState]);

    const getMaxDeliveryDate = () => {
        let maxDeliveryDate = cartState.maxCartDeliveryDate ? cartState.maxCartDeliveryDate : getNewDate();
        _.map(cartDetailRevamped, (cart, index) => {
            if (getNewDate(cart.itemAvailability && cart.itemAvailability[0].deliveryDate.luxon) > getNewDate(maxDeliveryDate)) {
                maxDeliveryDate = getNewDate(cart.itemAvailability[0].deliveryDate.luxon);
            }
        });
        if (!minMaxDeliveryDate) {
            setMinMaxDeliveryDate(maxDeliveryDate);
        }
        return maxDeliveryDate;
    }

    const getMaxCartDeliveryDateRenderer = () => {
        const value = getMaxDeliveryDate();
        const minCartDeliveryDate = cartState.minMaxCartDeliveryDate ? cartState.minMaxCartDeliveryDate : getNewDate();
        return (
            <DatePickerV2
                label={"Combine Delivery Date"}
                value={value}
                minDate={minMaxDeliveryDate ? minMaxDeliveryDate : minCartDeliveryDate}
                showCurrentDateSelector={true}
                changer={(date) => {
                    Logger.log("Max Cart Delivery DATE CHANGED:", date);
                    return maxCartDeliveryDateHandler(date);
                }}
            />
        )
    }

    const handleDeleteItemFromCart = (item) => {
        Logger.log("Item going to delete from Cart", item);
        dispatch(deleteItemFromCart(item));
    }

    const handleSplitItem = (item) => {
        Logger.log("Item going to Split in Cart", item);
        dispatch(splitItem(item, () => {
            cartChildToggle();
        }));


    }

    const handleEditCart = (item) => {
        Logger.log("EDIT ITEM: ", item);
        dispatch(setEditItem(item, (item) => {
            dispatch(_setChildOpenForEdit(true));
        }));
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const enableShouldAddEmbroidery = (value, cartItem) => {
        cartItem.shouldAddEmbroidery = value;
        dispatch(_toggleAsyncStorage(true));
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const getActiveSizesHeader = () => {

        const availableSizes = getAvailableSizes(SIZES_NAME, cartDetailRevamped);
        Logger.log("==== Available Sizes for header: " , availableSizes);

        return (
            <>
                {
                    _.map(availableSizes, (sizes, index) => (
                        <>
                            <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{sizes}</th>

                        </>
                    ))
                }
            </>
        )
    }

    const getActiveSizesRows = (singleCart) => {

        const availableSizes = getAvailableSizes(SIZES_NAME, cartDetailRevamped);

        return (
            <>
                {
                    _.map(availableSizes, (sizes, index) => (
                        <>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.qty[sizes]}</td>
                        </>
                    ))
                }
            </>
        )
    }

    function CustomTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const getMainTable = () => {
        return (
            <Table size={"lg"} className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                   bordered>
                <thead>
                <tr style={{position: "relative", zIndex: 99999, height: "100px"}}>
                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>#</th>
                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Item</th>
                    <th style={{width: "180px"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Delivery
                        Date
                    </th>
                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Group</th>
                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Style Code</th>
                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Color</th>
                    {
                        getActiveSizesHeader()
                    }
                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total
                    </th>
                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total Price
                    </th>
                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Actions
                    </th>
                    {window.config.EMBROIDERY_ENABLE ? (
                        <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Embroidery
                        </th>
                    ) : null}
                </tr>

                </thead>
                <tbody>
                {getTableRows()}
                </tbody>

            </Table>
        );
    }

    const addEmbroideryDetails = (item) => {
        Logger.log("Set Embroidery Detail ITEM: ", item);
        dispatch(setEditItem(item, (item) => {
            dispatch(_setChildOpenForEmbroidery(true));
        }));
    }


    const getTableRows = () => {
        return (
            <>
                {_.map(cartDetailRevamped, (singleCart, index) => (
                    <>
                        <tr key={index} >
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{++index}</td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.description} - ({singleCart.division})</td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                {/*{getDatePicker(singleCart)}*/}
                                {getDatePickerV2(singleCart)}
                            </td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.group}</td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.fullStyle}</td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.colorCode}</td>
                            {
                                getActiveSizesRows(singleCart)
                            }
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{singleCart.qty["totalQuantity"]} Units</td>
                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{priceViewer(singleCart.qty["totalQuantity"] * singleCart.cost)}</td>

                            <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>

                                <Button color={"error"}
                                        clicker={() => handleDeleteItemFromCart(singleCart)}
                                        style={{padding: '5px', margin: "5px", minWidth: "30px"}}
                                >
                                    <FontIcon icon={faTimes}/>
                                </Button>

                                <Button color={"info"}
                                        clicker={() => handleEditCart(singleCart)}
                                        style={{padding: '5px', margin: "5px", minWidth: "30px"}}
                                >
                                    <FontIcon icon={faPenNib}/>
                                </Button>
                                {window.config.SPLIT_CART_CARRY_OVER_ITEMS ? (
                                        <Button color={"primary"}
                                                clicker={() => handleSplitItem(singleCart)}
                                                style={{
                                                    padding: '5px',
                                                    margin: "5px",
                                                    minWidth: "30px"
                                                }}
                                        >
                                            <FontIcon icon={faColumns}/>
                                        </Button>
                                    )
                                    : null}
                                {singleCart.embroideryAvailable && singleCart.hasOwnProperty("shouldAddEmbroidery") && singleCart.shouldAddEmbroidery ? (
                                    <>
                                        <Button color={"warning"}
                                                clicker={() => addEmbroideryDetails(singleCart)}
                                                style={{
                                                    padding: '5px',
                                                    margin: "5px",
                                                    minWidth: "30px"
                                                }}
                                        >
                                            <FontIcon icon={faCut}/>
                                        </Button>
                                    </>

                                ) : null}

                            </td>
                            {window.config.EMBROIDERY_ENABLE ? (
                                <td style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                    {singleCart.embroideryAvailable ? (
                                        <>
                                            <ToggleButton
                                                checked={singleCart.hasOwnProperty("shouldAddEmbroidery") && singleCart.shouldAddEmbroidery}
                                                changer={(value) => enableShouldAddEmbroidery(value, singleCart)}/>
                                        </>

                                    ) : "N/A"}
                                </td>
                            ) : null}

                        </tr>

                    </>
                ))}

            </>
        )
    }

    Logger.log("=== Unique Dates , ", uniqueDeliveryDates);




    return (
        <>
            <div style={{fontSize: "15px"}} className={'tableFixHead'}>
                <h3 className={themeMode === 'light' ? 'text-dark' : 'text-light'}>Cart Items</h3>
                {getMaxCartDeliveryDateRenderer()}
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={"All"} {...a11yProps(0)} />
                        {_.map(uniqueDeliveryDates, (dates, index) => (
                                <Tab className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                                    label={dates.changeableDeliveryDate.formatted} {...a11yProps(++index)} />
                            )
                        )}
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <>
                        {getMainTable()}
                    </>
                </CustomTabPanel>

                {_.map(uniqueDeliveryDates, (dates, uniqueDateIndex) => (
                        <CustomTabPanel value={value} index={++uniqueDateIndex}>
                            <Table size={"lg"} className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                                   bordered>
                                <thead>
                                <tr style={{position: "relative", zIndex: 99999, height: "100px"}}>
                                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>#</th>
                                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Item</th>
                                    <th style={{width: "180px"}}
                                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Delivery
                                        Date
                                    </th>
                                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Group</th>
                                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Style Code</th>
                                    <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Color</th>
                                    {
                                        getActiveSizesHeader()
                                    }
                                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total
                                    </th>
                                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total Price
                                    </th>
                                    <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Actions
                                    </th>
                                    {window.config.EMBROIDERY_ENABLE ? (
                                        <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Embroidery
                                        </th>
                                    ) : null}

                                </tr>

                                </thead>
                                <tbody>
                                {_.map(cartDetailRevamped, (singleCart, index) => (
                                    <>
                                        {(dates.changeableDeliveryDate.formatted === singleCart.changeableDeliveryDate.formatted) ? (
                                            <>
                                                <tr key={index}>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{++index}</td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.description} - ({singleCart.division})</td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                                        {/*{getDatePicker(singleCart)}*/}
                                                        {getDatePickerV2(singleCart)}
                                                    </td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.group}</td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.fullStyle}</td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{singleCart.colorCode}</td>
                                                    {
                                                        getActiveSizesRows(singleCart)
                                                    }
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{singleCart.qty["totalQuantity"]} Units</td>
                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{priceViewer(singleCart.qty["totalQuantity"] * singleCart.cost)}</td>

                                                    <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>

                                                        <Button color={"error"}
                                                                clicker={() => handleDeleteItemFromCart(singleCart)}
                                                                style={{padding: '5px', margin: "5px", minWidth: "30px"}}
                                                        >
                                                            <FontIcon icon={faTimes}/>
                                                        </Button>

                                                        <Button color={"info"}
                                                                clicker={() => handleEditCart(singleCart)}
                                                                style={{padding: '5px', margin: "5px", minWidth: "30px"}}
                                                        >
                                                            <FontIcon icon={faPenNib}/>
                                                        </Button>
                                                        {window.config.SPLIT_CART_CARRY_OVER_ITEMS ? (
                                                                <Button color={"primary"}
                                                                        clicker={() => handleSplitItem(singleCart)}
                                                                        style={{
                                                                            padding: '5px',
                                                                            margin: "5px",
                                                                            minWidth: "30px"
                                                                        }}
                                                                >
                                                                    <FontIcon icon={faColumns}/>
                                                                </Button>
                                                            )
                                                            : null}
                                                        {singleCart.embroideryAvailable && singleCart.hasOwnProperty("shouldAddEmbroidery") && singleCart.shouldAddEmbroidery ? (
                                                            <>
                                                                <Button color={"warning"}
                                                                        clicker={() => addEmbroideryDetails(singleCart)}
                                                                        style={{
                                                                            padding: '5px',
                                                                            margin: "5px",
                                                                            minWidth: "30px"
                                                                        }}
                                                                >
                                                                    <FontIcon icon={faCut}/>
                                                                </Button>
                                                            </>

                                                        ) : null}

                                                    </td>
                                                    {window.config.EMBROIDERY_ENABLE ? (
                                                        <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>
                                                            {singleCart.embroideryAvailable ? (
                                                                <>
                                                                    <ToggleButton
                                                                        checked={singleCart.hasOwnProperty("shouldAddEmbroidery") && singleCart.shouldAddEmbroidery}
                                                                        changer={(value) => enableShouldAddEmbroidery(value, singleCart)}/>
                                                                </>

                                                            ) : "N/A"}
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            </>
                                        ) : null}

                                    </>
                                ))}

                                </tbody>

                            </Table>
                        </CustomTabPanel>
                    )
                )}

                        <Table size={"sm"} className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'} bordered>
                            <tbody>
                            <tr style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>
                                <th scope={"row"} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total Unique Items:</th>
                                <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{cartDetailRevamped.length}</td>
                                <th scope={"row"} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Total QTY of Items:</th>
                                <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>{
                                    _.sumBy(cartDetailRevamped, p => p.qty.totalQuantity)
                                }
                                </td>
                            </tr>
                            </tbody>
                        </Table>

                <Table size={"sm"} className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'} bordered>
                    <thead>
                    <tr style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                        <th className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{textAlign: "center"}}>Order Total:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                        <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{textAlign: "center"}}>{displayTotalItemsValue()}</td>
                    </tr>
                    </tbody>
                </Table>


            </div>
        </>
    )
}
export default connect(null, {changeDeliveryDateInCart})(CartViewer);


