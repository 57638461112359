import React from 'react';
import {Col, Row} from 'react-bootstrap';


const VersionNumber = (props) => {

    const {version} = props;
    return (
        <>
            <Row>
                <Col style={{marginTop: "20px", fontSize:"14px", color:"grey"}}>
                    {version ? version : null}
                </Col>
            </Row>
        </>
    )
}

export default VersionNumber;