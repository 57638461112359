// Library
import React from 'react';
import {TextField} from "@mui/material";

const InputBox = (props) => {
    const {id, name, label, value, variant, changer, error, helperText, type, multiline, maxRows, rows} = props;
    return (
        <TextField
            margin={'dense'}
            fullWidth
            id={id ? id : "text-box"}
            name={name ? name : "text-box"}
            label={label ? label : "text-box"}
            variant={variant ? variant : "standard"}
            value={value}
            onChange={changer}
            type={type ? type : "text"}
            error={error}
            helperText={helperText}
            multiline={multiline ? multiline : false}
            maxRows={maxRows ? maxRows : 4}
            rows={rows ? rows : 4}
        />
    )
}

export default InputBox
