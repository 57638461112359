// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "THEME_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_THEME_MODE = MODULE_PREFIX_PREFIX + "SET_THEME_MODE";
export const TOGGLE_STORAGE = MODULE_PREFIX_PREFIX + "TOGGLE_STORAGE";

