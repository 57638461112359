// Library
import React from 'react';
import {Grid} from '@mui/material';
import {Pagination} from '@mui/lab';


const PaginationWrapper = (props) => {
    return (
        <>
            {props.totalPages > 1 ? (
                <Grid item>
                    <Grid container spacing={1} direction="column" alignItems="center">
                        <Grid item>
                            <Pagination
                                defaultPage={props.currentPage}
                                page={props.currentPage}
                                count={props.totalPages}
                                onChange={props.changer}
                                variant={"outlined"}
                                color={"primary"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

        </>
    )
}
export default PaginationWrapper;