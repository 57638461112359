// Library
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
// Page Container
import PageContainer from '../../Containers/PageContainer';
// Components
import Input from '../../Components/InputV2';
import Button from '../../Components/Button';
import Loader from '../../Components/Loader';
// Logger
import Logger from '../../Utils/Logger';
// Helpers
import {toaster} from '../../Utils/Helpers';
// Redux Actions
import {sendSupportEmail} from "../../Store/Email/action";

const Support = (props) => {

    // Hooks
    const dispatch = useDispatch();
    // State
    const [emailContent, setEmailContent] = useState(null);

    const emailState = useSelector(state => state.email);

    const handleSubmit = () => {
        if (emailContent && emailContent.length > 20) {

            dispatch(sendSupportEmail(emailContent, (err) => {
                if (!err) {
                    toaster("Your request has been received and will be viewed by our team. You will get notified on your registered Email", "success");
                    setEmailContent("");
                }
            }));

        } else {
            toaster("Detail message is required", "danger");
        }
    }

    Logger.log("Email Content", emailContent);

    return (
        <PageContainer>
            <Input
                labelSize={12}
                inputSize={12}
                label={"Write your message here.."}
                type={"textarea"}
                rows={10}
                value={emailContent}
                changer={(e) => setEmailContent(e.target.value)}

            />

            {emailState.processing ? (
                <Loader style={{float: "right"}}/>
            ) : (
                <Button clicker={handleSubmit} style={{float: "right", marginTop:"30px"}}>Submit</Button>
            )}

        </PageContainer>
    )
}

export default Support;