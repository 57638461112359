// Library
import React from 'react';
import {LazyImageFull, ImageState} from "react-lazy-images";

const LazyImage = (props) => {
    const {alt, height, src, width, clicker} = props;
    return (
        <>
            <LazyImageFull src={src}>
                {({imageProps, imageState, ref}) => (
                    <img
                        {...imageProps}
                        ref={ref}
                        height={height}
                        width={width}
                        alt={alt}
                        onClick={imageState === ImageState.LoadSuccess ? clicker : null}
                        src={
                            imageState === ImageState.LoadSuccess
                                ? imageProps.src
                                : window.config.DEFAULT_IMAGE_URL
                        }
                        style={{opacity: ImageState.LoadSuccess ? "1" : "0.5"}}
                    />
                )}
            </LazyImageFull>
        </>
    )
}

export default LazyImage;
