// Library
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {ErrorMessage, Form, Formik} from 'formik';
import {FormHelperText} from "@mui/material";
import _ from 'lodash';
import * as yup from 'yup';
// Helpers
import {toaster} from '../../../Utils/Helpers';
// Redux Actions
import {getAllSalesman, addNewUser, getAllUsers} from "../../../Store/User/actions";
import {getAllDivisions} from "../../../Store/Order/action";
import {getAllRoles} from "../../../Store/Roles/actions";
// Container
import PageContainer from "../../../Containers/PageContainer";
// Components
import InputBox from "../../../Components/Input";
import Loader from "../../../Components/Loader";
import Button from "../../../Components/Button";
import ToggleButton from "../../../Components/ToggleButton";
import Select from "../../../Components/Select";
// Config
import Colors from "../../../Config/Colors";
// Logger
import Logger from "../../../Utils/Logger";

// Validations
const validationSchema = yup.object().shape({
    name: yup.string().required("Name is Required").min(4, "Name is too Short"),
    username: yup.string().required("Username is Required").min(6, "Username is too short"),
    email: yup.string().required("Email is Required").min(6, "Email is too short").email(),
    password: yup.string().required("Password is Required").min(6, "Password is too short"),
    roleId: yup.string().required("Role Selection is Required"),

});
// Initial Values
const initialDefaultValues = {
    name: "",
    username: "",
    password: "",
    email: "",
    salesmanId: "",
    roleId: "",
    isAdmin: false,
    isEnabled: true,
    divisions: [],
    parentRoleId: "",
    parentRoles: []

}

// Some Internal Constants
const REP = "rep";
const CSR = "csr";
const ADMIN = "admin";

const AddUser = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);
    const orderState = useSelector(state => state.order);
    const roleState = useSelector(state => state.role);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(initialDefaultValues);
    const [selectedRole, setSelectedRole] = useState(ADMIN);

    const allCsrs = userState.allUsersForAdding ? userState.allUsersForAdding.csr : [];
    const allReps = userState.allUsersForAdding ? userState.allUsersForAdding.reps : [];

    useEffect(() => {
        dispatch(getAllSalesman());
        dispatch(getAllDivisions());
        dispatch(getAllRoles());
        dispatch(getAllUsers());
        setInitialValues(initialDefaultValues)
    }, []);

    // Checking For Admin
    useEffect(() => {
        if (!userState.isAdmin) {
            toaster("You are not allowed to see this page", "danger");
            history.push("/");
        }

    }, []);

    // Input Changer
    const inputChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index.js.js: ", index.js.js);
        formik.setFieldValue(fieldName, value);


        // Logger.log("Formik", formik);
    }

    // Drop Down Changer
    const dropDownChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index.js.js: ", index.js.js);
        formik.setFieldValue(fieldName, index ? value[index] : value.id);

        if (fieldName === "roleId") {
            if (value.id === window.config.REP_ROLE_ID) {
                setSelectedRole(REP);
            } else if (value.id === window.config.CSR_ROLE_ID) {
                setSelectedRole(CSR);
            } else {
                setSelectedRole(ADMIN);
            }
        }

        // Logger.log("Formik", formik);
    }
    // Drop Down Changer
    const multiDropDownChanger = (value, formik, fieldName, index) => {
        Logger.log("VALUE: ", value);
        Logger.log("FORMIK: ", formik);
        Logger.log("Field Name: ", fieldName);
        Logger.log("index.js.js: ", index);
        formik.setFieldValue(fieldName, value);


        Logger.log("Formik", formik);
    }

    const onSubmit = (values, {setStatus, setSubmitting}) => {


        Logger.log("VALUES: ", values);
        setLoading(true);
        setSubmitting(true);
        setStatus(false);
        let sev = "";
        let message = "";

        const userDivisions = [];

        if (values.divisions.length) {
            _.map(values.divisions, (division, index) => {
                userDivisions.push(division.id);
            })
        }

        const parentRoles = [];

        if (values.hasOwnProperty("parentRoles") && Array.isArray(values.parentRoles) && values.parentRoles.length) {
            _.map(values.parentRoles, (roles, index) => {
                parentRoles.push(roles.id);
            })
        }

        values.divisions = userDivisions;
        values.parentRoles = parentRoles;

        // Making User As ADMIN
        if (selectedRole === ADMIN) {
            values.isAdmin = true;
        }
        Logger.log("VALUES: ", values);
        dispatch(addNewUser(values, (err) => {
            if (err) {
                Logger.log("Errors: ", err);

                message = "There are errors in adding the user";
                sev = "danger";
                setLoading(false);
                setSubmitting(false);
            } else {
                message = "Successfully Added the User";
                sev = "success";
                setTimeout(() => {
                    setLoading(false);
                    setSubmitting(false);
                }, 6000);

            }

            toaster(message, sev);
        }));
    }

    return (
        <PageContainer>
            <h3 className={"text-center"}>Add New User</h3>
            <hr/>
            <Row>
                <Col>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formikProps) => (
                            <Form>
                                <Row className={"mt-4"}>
                                    <Col sm={{span: 4, offset: 4}}>
                                        <InputBox
                                            margin={'dense'}
                                            fullWidth
                                            id="name"
                                            name={"name"}
                                            label="Name"
                                            helperText={"Enter Name"}
                                            variant="filled"
                                            changer={(e) => inputChanger(e.target.value, formikProps, `name`)}

                                        />
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`name`}/>
                                        </div>
                                    </Col>

                                    <Col sm={{span: 4, offset: 4}}>
                                        <InputBox
                                            margin={'dense'}
                                            fullWidth
                                            id="username"
                                            name={"username"}
                                            label="Username"
                                            helperText={"Enter Username"}
                                            variant="filled"
                                            changer={(e) => inputChanger(e.target.value, formikProps, `username`)}

                                        />
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`username`}/>
                                        </div>
                                    </Col>
                                    <Col sm={{span: 4, offset: 4}}>
                                        <InputBox
                                            margin={'dense'}
                                            fullWidth
                                            id="email"
                                            name={"email"}
                                            label="Email"
                                            helperText={"Enter Email"}
                                            variant="filled"
                                            changer={(e) => inputChanger(e.target.value, formikProps, `email`)}

                                        />
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`email`}/>
                                        </div>
                                    </Col>

                                    <Col sm={{span: 4, offset: 4}}>
                                        <InputBox
                                            margin={'dense'}
                                            fullWidth
                                            id="password"
                                            type={"password"}
                                            name={"password"}
                                            label="Password"
                                            helperText={"Enter Password"}
                                            variant="filled"
                                            changer={(e) => inputChanger(e.target.value, formikProps, `password`)}

                                        />
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`password`}/>
                                        </div>

                                    </Col>

                                    <Col sm={{span: 4, offset: 4}}>
                                        <Select
                                            data={roleState.allRoles}
                                            value={formikProps.values.roleId}
                                            placeholder={"Please Select Role"}
                                            changer={(value) => dropDownChanger(value, formikProps, `roleId`)}/>
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`roleId`}/>
                                        </div>
                                        <FormHelperText>Select Role</FormHelperText>
                                    </Col>

                                    {selectedRole === CSR ? (
                                        <Col sm={{span: 4, offset: 4}}>
                                            <Select
                                                data={allReps}
                                                value={formikProps.values.parentRoles}
                                                placeholder={"Please Select REPs"}
                                                multi={true}
                                                changer={(value) => multiDropDownChanger(value, formikProps, `parentRoles`)}/>
                                            <div>
                                                <ErrorMessage component={"div"}
                                                              className={"text-danger text-center"}
                                                              name={`parentRoles`}/>
                                            </div>
                                            <FormHelperText>Select REPs</FormHelperText>
                                        </Col>
                                    ) : null}

                                    {selectedRole === REP ? (
                                        <>
                                            <Col sm={{span: 4, offset: 4}}>
                                                <Select
                                                    data={allCsrs}
                                                    value={formikProps.values.parentRoleId}
                                                    placeholder={"Please Select CSR"}
                                                    changer={(value) => dropDownChanger(value, formikProps, `parentRoleId`)}/>
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`parentRoleId`}/>
                                                </div>
                                                <FormHelperText>Select CSR</FormHelperText>
                                            </Col>

                                            <Col sm={{span: 4, offset: 4}}>
                                                <Select
                                                    data={orderState.division ? orderState.division : []}
                                                    value={formikProps.values.divisions}
                                                    placeholder={"Please Select Divisions"}
                                                    multi={true}
                                                    changer={(value) => multiDropDownChanger(value, formikProps, `divisions`)}/>
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`salesmanId`}/>
                                                </div>
                                                <FormHelperText>Select Divisions</FormHelperText>
                                            </Col>
                                        </>
                                    ) : null}


                                    <Col sm={{span: 4, offset: 4}}>
                                        <Select
                                            data={userState.allSalesman ? userState.allSalesman : []}
                                            value={formikProps.values.salesmanId}
                                            placeholder={"Please Select Sales Rep"}
                                            changer={(value) => dropDownChanger(value, formikProps, `salesmanId`)}/>
                                        <div>
                                            <ErrorMessage component={"div"}
                                                          className={"text-danger text-center"}
                                                          name={`salesmanId`}/>
                                        </div>
                                        <FormHelperText>Select Sales Rep</FormHelperText>
                                    </Col>


                                    {/*<Col sm={{span: 4, offset: 4}}>*/}
                                    {/*    <ToggleButton checked={formikProps.values.isAdmin}*/}
                                    {/*                  changer={(value) => inputChanger(value, formikProps, `isAdmin`)}/>*/}
                                    {/*    <FormHelperText>Is Admin?</FormHelperText>*/}
                                    {/*</Col>*/}
                                    <Col sm={{span: 4, offset: 4}}>
                                        <ToggleButton checked={formikProps.values.isEnabled}
                                                      changer={(value) => inputChanger(value, formikProps, `isEnabled`)}/>
                                        <FormHelperText>Enabled?</FormHelperText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{span: 2, offset: 5}}>
                                        {loading ? (
                                            <Loader/>
                                        ) : (
                                            <Button
                                                fullWidth
                                                type="submit"
                                                style={{color: Colors.white, marginTop: "30px"}}
                                                disabled={loading}
                                            >
                                                Add User
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </PageContainer>
    )
}

export default AddUser;
