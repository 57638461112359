//Action Types
import * as Actions from './action-types';
import Logger from "../../Utils/Logger";
import * as LS from "../../Utils/LocalStorageHelpers";
import {
    TRACKER_LOCAL_STORAGE_KEY,
    TRACKER_STORAGE_EXPIRY
} from "../../Utils/CommonConstants";

// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    tracker: []

};
const getInitialState = () => {
    const getData = LS.getStorage(TRACKER_LOCAL_STORAGE_KEY);
    if (getData) {
        const dataParsed = JSON.parse(getData);
        // Logger.log("TRACKER STORAGE DATA", dataParsed);
        return {
            processing: false,
            error: false,
            message: null,
            tracker: dataParsed.tracker,
        }
    }
    return initialState;
}
const reducer = (state = getInitialState(), action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;

        case Actions.SET_TRACKER:
            newState.tracker = payload;
            break;

        case Actions.TOGGLE_STORAGE:
            return setStateToStorage(state, payload);
        default:
            break;
    }


    return newState;

};

const setStateToStorage = (state, fill) => {
    if (fill) {
        // Logger.log("SAVING TO STORAGE", state);
        LS.setStorage(TRACKER_LOCAL_STORAGE_KEY, JSON.stringify(state), TRACKER_STORAGE_EXPIRY);
    } else {
        LS.removeStorage(TRACKER_LOCAL_STORAGE_KEY);
    }
    return state;
};

export default reducer;
