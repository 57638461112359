// Library
import React, {useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import _ from 'lodash';
// Logger
import Logger from "../../Utils/Logger";
// Helpers
import {priceViewer, getAmericanDateFormatter, getAvailableSizes, getAllQtyFromPlacedOrder} from '../../Utils/Helpers';
import {SIZES_NAME} from "../../Utils/CommonConstants";

const OrderDetailViewer = (props) => {
    const {data, term} = props;

    const [allQty, setAllQty] = useState([]);

    useEffect(() => {
        if(data) {
            setAllQty(getAllQtyFromPlacedOrder(data));
        }

    } ,[data])

    const costs = [];

    const renderSizeHeader = (qty) => {
        const parsedQty = JSON.parse(qty);
        Logger.log("PARSED:", allQty);
        const header = [];
        _.forIn(allQty, (value, index) => {
            if (index !== "other" || index !== "cost") {
                header.push(<th>{value}</th>)
            }
        });

        header.push(<th>Unit Price</th>)
        header.push(<th>Discount</th>)
        header.push(<th>Total Price</th>)
        return (
            <>
                {header}
            </>
        );
    }

    const renderSizeBody = (qty, cost, order) => {
        const parsedQty = JSON.parse(qty);
        Logger.log("=====> PARSED QTY BODY :", parsedQty);
        const header = [];

        // Iterate over allQty instead of parsedQty
        allQty.forEach(size => {
            // Check if the size exists in parsedQty and if the quantity is greater than 0
            if (parsedQty[size] > 0) {
                // Only push <td> for sizes with qty > 0
                header.push(<td key={size}>{parsedQty[size]}</td>);
            } else {
                header.push(<td key={size}>0</td>);
            }
        });

        // Add cost and discount to the header
        header.push(<td key="cost">{priceViewer(parseFloat(cost))}</td>);
        header.push(<td key="discount">{order.discount}%</td>);

        return (
            <>
                {header}
            </>
        );
    }

    const renderCost = (qty, cost, orderEmbroideryDetail, singleOrder) => {
        const parsedQty = JSON.parse(qty);
        // Logger.log("PARSED:", parsedQty);
        // Logger.log("Cost:", cost);

        let totalCost = parseFloat(cost * parsedQty.totalQuantity);
        let discount = singleOrder.discount;

        if (discount > 0) {
            discount = discount / 100;
            totalCost = totalCost - (totalCost * discount);
        }
        if(orderEmbroideryDetail) {
            const totalQuantity = parsedQty.totalQuantity;
            totalCost += totalQuantity * window.config.EMBROIDERY_COST_PER_ITEM;
        }
        costs.push(totalCost);

        return priceViewer(totalCost);
    }

    const renderOrderFooter = (order) => {
        // Logger.log("ORDER: ", order);
        const totalQuantity = [];
        const totalCost = [];
        const totalUniqueItems = [];
        const totalOrders = order.length;
        const totalEmbroideryItems = [];

        _.map(order, (singleOrder, orderIndex) => {
            _.map(singleOrder.orderDetails, (orderDetails, orderDetailIndex) => {
                totalEmbroideryItems.push(orderDetails.orderEmbroideryDetail.length);
            });
        });

        _.map(order, (singleOrder, orderIndex) => {
            let discount = singleOrder.discount;

            _.map(singleOrder.orderDetails, (orderDetails, orderDetailIndex) => {
                totalUniqueItems.push(orderDetails);
                const parsedQty = JSON.parse(orderDetails.qty);
                if (discount > 0) {
                    let totalCostAfterDiscount = orderDetails.cost * parsedQty.totalQuantity;
                    discount = discount / 100;
                    totalCost.push(totalCostAfterDiscount - (totalCostAfterDiscount * discount));

                } else {
                    totalCost.push(orderDetails.cost * parsedQty.totalQuantity)
                }


                totalQuantity.push(parsedQty.totalQuantity);
            });

        });

        Logger.log("Total Quantity: ", totalQuantity);
        Logger.log("Total Cost: ", totalCost);
        Logger.log("Total Unique Items: ", _.sum(totalUniqueItems));
        Logger.log("Total Orders: ", totalOrders);


        return (
            <Table size={"sm"} className={"mt-5"} bordered>
                <tbody>
                <tr>

                    <th scope="row" style={{width: "250px"}}>Total Deliveries:</th>
                    <td>{totalOrders}</td>


                    <th scope="row" style={{width: "250px"}}>Total Unique Items:</th>
                    <td>{totalUniqueItems.length}</td>


                    <th scope="row" style={{width: "250px"}}>Total Quantity:</th>
                    <td>{_.sum(totalQuantity)}</td>

                    <th scope="row" style={{width: "250px"}}>Total Embroidery Items:</th>
                    <td>{_.sum(totalEmbroideryItems)}</td>

                    <th scope="row" style={{width: "250px"}}>Total Embroidery Price:</th>
                    <td>{priceViewer(calculateEmbroideryCost(order))}</td>

                    <th scope="row" style={{width: "250px"}}>Total Price:</th>
                    <td>{priceViewer(_.sum(totalCost) + calculateEmbroideryCost(order))}</td>
                </tr>
                </tbody>

            </Table>
        );
    }

    const calculateEmbroideryCost = (order) => {
        let totalCost = 0;

        _.map(order, (singleOrder, orderIndex) => {
            _.map(singleOrder.orderDetails, (item, orderDetailIndex) => {
                if (item.orderEmbroideryDetail.length > 0) {
                    const parsedQty = JSON.parse(item.qty);
                    const totalQuantity = parsedQty.totalQuantity;
                    totalCost += totalQuantity * window.config.EMBROIDERY_COST_PER_ITEM;
                }
            });
        });

        return totalCost;
    }

    const renderOrderTotal = (order) => {

        let totalCost = 0;
        let totalItems = 0;
        let discount = order.discount;

        _.map(order.orderDetails, (orderDetails, orderDetailIndex) => {
            const parsedQty = JSON.parse(orderDetails.qty);
            if (discount > 0) {
                let totalCostAfterDiscount = orderDetails.cost * parsedQty.totalQuantity;
                discount = discount / 100;
                totalCost = totalCost + (totalCostAfterDiscount - (totalCostAfterDiscount * discount));

            } else {
                totalCost = totalCost + (orderDetails.cost * parsedQty.totalQuantity)
            }

            totalItems = totalItems + parsedQty.totalQuantity;

        });


        return (
            <tbody>
            <td>

                <th scope="row" style={{width: "50%"}}>Total Items:</th>
                <td>{totalItems}</td>

                <th scope="row" style={{width: "50%"}}>Total Price:</th>
                <td>{priceViewer(totalCost)}</td>

            </td>
            </tbody>
        )
    }

    const renderEmbroideryDetails = (embroideryDetails) => {
        Logger.log("Embroidery Details ==> ", embroideryDetails);
        if (embroideryDetails.length) {
            return (
                <td colSpan={17}>
                    <Table size={"sm"} bordered style={{width: '100%'}}>
                        <thead>
                        <tr>
                            <th colSpan={4} style={{textAlign:"center"}}>Embroidery Details</th>
                        </tr>
                        <tr>
                            <th>Location</th>
                            <th>Color</th>
                            <th>Size</th>
                            <th>Remarks</th>
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(embroideryDetails, (item, key) => (
                            <tr>
                                <td>{item.location}</td>
                                <td>{item.color}</td>
                                <td>{item.size}</td>
                                <td>{item.remarks}</td>
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                </td>
            );
        }

        return null;
    }

    Logger.log("TOTAL COSTS: ", costs);
    return (
        <>
            <div className={"mt-3"}>


                {_.map(data, (singleOrder, orderIndex) => (
                    <>
                        <h6 className={"text-center"}>Delivery # {++orderIndex}</h6>
                        <Table size={"sm"} className={"mt-3"} bordered>
                            <tbody>
                            <tr>

                                <th scope="row" style={{width: "150px"}}>Delivery Date:</th>
                                <td>{singleOrder ? getAmericanDateFormatter(singleOrder.deliveryDate) : "N/A"}</td>

                                <th scope="row" style={{width: "150px"}}>Cancel Date:</th>
                                <td>{singleOrder ? getAmericanDateFormatter(singleOrder.cancelDate.replace("PKT", "UTC")) : "N/A"}</td>


                                <th scope="row" style={{width: "150px"}}>PO Number:</th>
                                <td>{singleOrder ? singleOrder.poNumber : "N/A"}</td>


                                <th scope="row" style={{width: "150px"}}>Season:</th>
                                <td>{singleOrder ? singleOrder.season : "N/A"}</td>


                                <th scope="row" style={{width: "150px"}}>Order Type:</th>
                                <td>{singleOrder ? singleOrder.orderTypeModel.userRef : "N/A"}</td>


                                <th scope="row" style={{width: "150px"}}>Terms:</th>
                                <td>{term ? term.termNo : "N/A"}</td>

                            </tr>
                            <tr>
                                <th scope="row" style={{width: "150px"}}>Shipping Type:</th>
                                <td>{singleOrder ? singleOrder.shippingTypeModel.carrierAbbr : "N/A"}</td>

                                <th scope="row" style={{width: "150px"}}>Remarks:</th>
                                <td colSpan={10}>{singleOrder ? singleOrder.userRemarks : "N/A"}</td>

                            </tr>
                            <tr>
                                <th scope="row" style={{width: "150px"}}>Control Number:</th>
                                <td>{singleOrder ? singleOrder.controlNumber : "N/A"}</td>

                                <th scope="row" style={{width: "150px"}}>Invoice Number:</th>
                                <td>{singleOrder ? singleOrder.invoiceNumber : "N/A"}</td>

                                <th scope="row" style={{width: "150px"}}>Invoice Status:</th>
                                <td>{singleOrder ? singleOrder.invoiceStatus : "N/A"}</td>
                            </tr>

                            <tr>
                                <td colSpan={12}>

                                    <Table size={"sm"} bordered>
                                        <thead>
                                        <th>Product</th>
                                        <th>Style</th>
                                        {renderSizeHeader(singleOrder.orderDetails[0].qty, data)}
                                        </thead>

                                        <tbody>
                                        {_.map(singleOrder.orderDetails, (orderDetail, orderDetailIndex) => (
                                            <>
                                                <tr>
                                                    <td>{orderDetail.product.description}</td>
                                                    <td>{orderDetail.product.fullStyle}</td>
                                                    {renderSizeBody(orderDetail.qty, orderDetail.cost, singleOrder)}
                                                    <td>{renderCost(orderDetail.qty, orderDetail.cost, orderDetail.orderEmbroideryDetail, singleOrder)}</td>
                                                </tr>
                                                {orderDetail.hasOwnProperty("orderEmbroideryDetail") && orderDetail.orderEmbroideryDetail ? (
                                                    <>
                                                        <tr>
                                                            {renderEmbroideryDetails(orderDetail.orderEmbroideryDetail)}
                                                        </tr>
                                                    </>
                                                ) : null}
                                            </>

                                        ))}

                                        </tbody>

                                    </Table>


                                </td>

                            </tr>
                            </tbody>
                        </Table>
                        <Table size={"sm"} bordered>
                            {renderOrderTotal(singleOrder)}
                        </Table>
                    </>
                ))}

                <h4 className={"text-center"}>Order Summary</h4>
                {renderOrderFooter(data)}


            </div>
        </>
    )
}
export default OrderDetailViewer;
