// Action Types
import * as Actions from './action-types';

export const setTheme = (mode, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setThemeMode(mode));
        dispatch(_setProcessing(false));

        dispatch(_toggleAsyncStorage(true));
    })
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
// const _setError = (payload) => {
//     return {
//         type: Actions.SET_ERROR,
//         payload,
//     };
// };
// const _setMessage = (payload) => {
//     return {
//         type: Actions.SET_MESSAGE,
//         payload,
//     };
// };
const _setThemeMode = (payload) => {
    return {
        type: Actions.SET_THEME_MODE,
        payload,
    };
}
export const _toggleAsyncStorage = (payload) => {
    return {
        type: Actions.TOGGLE_STORAGE,
        payload,
    };
};