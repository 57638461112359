// Library
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import reportWebVitals from './reportWebVitals';
// Theme
import {DefaultThemeProvider} from "./Config/MuiTheme";
// Application
import App from './App';
// Store
import store from './Store';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';


const AppIgnite = (props) => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <DefaultThemeProvider>
                    <App/>
                </DefaultThemeProvider>
            </Provider>
        </React.StrictMode>
    )
}


ReactDOM.render(
    <AppIgnite/>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
