// Library
import _ from "lodash";
// Action Types
import * as Actions from './action-types';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Logger
import Logger from '../../Utils/Logger';
// Error Handler
import errorHandler from "../../Utils/errorHandler";
import {toaster} from "../../Utils/Helpers";


export const login = (username, password, cb) => {
    Logger.log("LOGIN ACTION | request landed on redux Action , username: " + username + " | password: " + password);

    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));
        axios.login(URLS.AuthenticationUrls.Login, {username, password})
            .then(response => {
                Logger.log("LOGIN RESPONSE : ", response.data);

                const {data} = response;
                const {content, message} = data;
                const {token, refreshToken} = content;

                if(content.hasOwnProperty("isCustomer") && !content.isCustomer) {
                    dispatch(_setMessage(message));
                    dispatch(_setError(false));
                    dispatch(_setUserData(content));
                    dispatch(_setSalesmanData(content.salesman));
                    dispatch(_setIsAdmin(content.isAdmin));
                    dispatch(_setRoleId(content.role_id));
                    dispatch(_setIsRep(window.config.CSR_ROLE_ID === content.role_id));
                    dispatch(_setParentRoleId(content.is_rep));
                    dispatch(_setToken(token));
                    dispatch(_setRefreshToken(refreshToken));
                    dispatch(_setFetchTempAccountCartStorage(content.fetchTempAccountCartStorage));
                    dispatch(_setAuthenticated(true));
                    dispatch(_setProcessing(false));

                    dispatch(_toggleAsyncStorage(true));

                    cb && cb();
                } else {

                    dispatch(_setProcessing(false));

                    toaster("Customer cannot be log in here!");

                    cb && cb(true);
                }
            })
            .catch(error => {
                Logger.error("LOGIN ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'Wrong Credentials'));
                dispatch(_setProcessing(false));


                errorHandler(error);

                cb && cb(error);
            })


    }


}

export const getAllUsersByParentRoleId = (parentRoleId, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setAllUsers([]));

        axios.get(URLS.UserUrls.GetAllUsersByParentUserId + parentRoleId)
            .then(response => {
                Logger.log("ALL USER RESPONSE RESPONSE : ", response.data);

                const {data} = response;
                const {content} = data;

                dispatch(_setAllUsers(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("ALL USER ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            });
    })
}

export const setUserFromStorage = (data, cb) => {
    return (dispatch) => {
        dispatch(_setProcessing(true));
        dispatch(_setToken(data.token));
        dispatch(_setUserData(data.userData));
        dispatch(_setAuthenticated(data.authenticated));
        dispatch(_setMessage(data.message));
        dispatch(_setProcessing(false));

        cb && cb();
    };
};

export const logout = (cb) => {
    return ((dispatch, getState) => {
        dispatch(_setProcessing(true));
        dispatch(_resetState());
        dispatch(_toggleAsyncStorage(false));
        dispatch(_setProcessing(false));

        cb && cb();
    });
};

// Get All Users
export const getAllUsers = (cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));
        axios.get(URLS.UserUrls.GetAllUsers)
            .then(response => {
                Logger.log("ALL USER RESPONSE RESPONSE : ", response.data);

                const {data} = response;
                const {content} = data;

                const reps = _.filter(content, p => p.roleId === window.config.REP_ROLE_ID);
                const csrs = _.filter(content, p => p.roleId === window.config.CSR_ROLE_ID);

                Logger.log("CSRS: ", csrs);
                Logger.log("REPS: ", reps);

                const users = {
                    reps: reps,
                    csr: csrs
                }

                dispatch(_setAllUsersForAdding(users));
                dispatch(_setAllUsers(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("ALL USER ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            });
    });
}
// get All SalesMan
export const getAllSalesman = (cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));

        axios.get(URLS.UserUrls.GetAllSalesman)
            .then(response => {
                Logger.log("ALL SALESMAN RESPONSE RESPONSE : ", response.data);

                const {data} = response;
                const {content} = data;

                dispatch(_setAllSalesman(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("ALL SALESMAN ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            });
    })
}
// Add new user

export const addNewUser = (user, cb) => {
    return (dispatch => {

        dispatch(_setProcessing(true));

        axios.post(URLS.UserUrls.AddNewUser, user)
            .then(response => {
                Logger.log("ADD NEW USER RESPONSE RESPONSE : ", response.data);

                // const {data} = response;
                // const {content} = data;

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("ADD NEW USER ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            })

    })
}

// Change Admin Status
export const changeAdminStatus = (data, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.patch(URLS.UserUrls.ChangeAdminStatus, data)
            .then(response => {
                Logger.log("ADMIN STATUS CHANGED : ", response.data);

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("CHANGE ADMIN STATUS ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            })


    });
}
export const changeStatus = (data, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.patch(URLS.UserUrls.ChangeStatus, data)
            .then(response => {
                Logger.log("USER STATUS CHANGED : ", response.data);

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("USER ADMIN STATUS ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            })


    });
}
// open Slot for Forgot Password
export const openSlotForPasswordChange = (email, cb) => {

    return (dispatch => {

        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.get(URLS.UserUrls.OpenSlotForChangePassword + email)
            .then(response => {
                Logger.log("OPEN SLOT FOR FORGOT PASSWORD  : ", response.data);

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("OPEN SLOT FOR FORGOT PASSWORD ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            });
    });


}
// Change Password
export const changePassword = (data, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.patch(URLS.UserUrls.ChangePassword, data)
            .then(response => {
                Logger.log("PASSWORD CHANGED : ", response.data);

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("PASSWORDSTATUS ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            })


    });
}

// Get User By Id
export const getUserById = (id, cb) => {
    return (dispatch => {

        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.get(URLS.UserUrls.GetUserById + id)
            .then(response => {

                Logger.log("GET USER BY ID RESPONSE : ", response.data);


                const {data} = response;
                const {content} = data;

                dispatch(_setTransactionalUser(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();

            })
            .catch(error => {
                Logger.error("GET USER BY ID ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            })

    })
}
// Udpate User
export const updateUser = (user, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));

        axios.patch(URLS.UserUrls.UpdateUser, user)
            .then(response => {

                Logger.log("UPDATE USER RESPONSE : ", response.data);

                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();


            }).catch(error => {
            Logger.error("UPDATE USER ERROR", error);

            dispatch(_setError(true));
            dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
            dispatch(_setProcessing(false));
            errorHandler(error);

            cb && cb(error);
        })
    })
}
export const getUserToUpdatePassword = (uuid, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setError(false));
        axios.get(URLS.UserUrls.GetUserForForgotPassword + uuid)
            .then(response => {

                Logger.log("GET USER FOR FORGOT PASSWORD RESPONSE : ", response.data);

                dispatch(_setTransactionalUser(response.data.content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();


            })
            .catch(error => {
                Logger.error("GET USER FOR FORGOT PASSWORD  ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No Salesman Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
        });


    })
}


// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
const _setMessage = (payload) => {
    return {
        type: Actions.SET_MESSAGE,
        payload,
    };
};
const _setIsAdmin = (payload) => {
    return {
        type: Actions.SET_IS_ADMIN,
        payload,
    };
};
const _setUserData = (payload) => {
    return {
        type: Actions.SET_USER_DATA,
        payload,
    };
};
const _setSalesmanData = (payload) => {
    return {
        type: Actions.SET_SALESMAN_DATA,
        payload,
    };
};
const _setToken = (payload) => {
    return {
        type: Actions.SET_TOKEN,
        payload,
    };
};
const _setAllUsers = (payload) => {
    return {
        type: Actions.SET_ALL_USERS,
        payload,
    };
};
const _setAllSalesman = (payload) => {
    return {
        type: Actions.SET_ALL_SALESMAN,
        payload,
    };
};
const _setRefreshToken = (payload) => {
    return {
        type: Actions.SET_REFRESH_TOKEN,
        payload,
    };
};
const _setAuthenticated = (payload) => {
    return {
        type: Actions.SET_AUTH,
        payload,
    };
};
const _toggleAsyncStorage = (payload) => {
    return {
        type: Actions.TOGGLE_STORAGE,
        payload,
    };
};
const _resetState = (payload) => {
    return {
        type: Actions.RESET_STATE,
        payload
    }
}
const _setTransactionalUser = (payload) => {
    return {
        type: Actions.SET_TRANSACTIONAL_USER,
        payload
    }
}
const _setRoleId = (payload) => {
    return {
        type: Actions.SET_ROLE_ID,
        payload
    }
}
const _setIsRep = (payload) => {
    return {
        type: Actions.SET_IS_REP,
        payload
    }
}
const _setParentRoleId = (payload) => {
    return {
        type: Actions.SET_PARENT_ROLE_ID,
        payload
    }
}
const _setAllUsersForAdding = (payload) => {
    return {
        type: Actions.SET_ALL_USERS_FOR_ADDING,
        payload
    }
}
const _setFetchTempAccountCartStorage = (payload) => {
    return {
        type: Actions.SET_FETCH_TEMP_ACCOUNT_CART_STORAGE,
        payload
    }
}