// Action Types
import * as Actions from './action-types';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Logger
import Logger from '../../Utils/Logger';
// Error Handler
import errorHandler from "../../Utils/errorHandler";

export const logError = (data, cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));

        axios.post(URLS.ErrorLogUrl.LogError , data)
            .then(response => {
                Logger.log("Error Log RESPONSE RESPONSE : ", response.data);

                const {data} = response;
                const {content} = data;
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb({error:false, data: content});
            })
            .catch(error => {
                Logger.error("ERROR LOG ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb({error:true, data: error});
            });
    })
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
const _setMessage = (payload) => {
    return {
        type: Actions.SET_MESSAGE,
        payload,
    };
};
