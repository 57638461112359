// Library
import React from 'react';

const ActionLink = (props) => {
    const {clicker, children} = props;

    return (
        <>
            <div onClick={() => clicker()}>{children}</div>
        </>
    )
}

export default ActionLink;
