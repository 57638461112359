// Library
import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
// Page Container
import PageContainer from "../../Containers/PageContainer";
// Components
import Select from '../../Components/Select';
import Button from '../../Components/Button';
// Containers
import CustomerViewer from "../../Containers/CustomerTable";
import StoreViewer from "../../Containers/StoreContainer";
import ProductViewer from "../../Containers/ProductViewer";
// Logger
import Logger from "../../Utils/Logger";
// Redux Actions
import {
    getAllCustomersBySalesmanId,
    getLatestSeasonsAndDivisions,
    setSelectedCustomer,
    getAllStoresByCustomerNumber,
    setSelectedStore,
    setSelectedSeason,
    setSelectedDivision,
    getAllGroupsBySeasonsAndDivisions,
    setSelectedGroup,
    getAllItemsBySeasonDivisionAndGroup,
    setSelectedProduct,
    getAllSizesWithSizeNumber, getAllCustomersByRepIds, _setPreLoadedCustomerAndStore
} from '../../Store/Order/action';
import Loader from "../../Components/Loader";
import {logTrack} from "../../Store/Tracker/actions";
import {getTrackerDO, toaster, validateEmbroideryDetails} from "../../Utils/Helpers";
import {PAGES} from "../../Utils/CommonConstants";


const OrderPage = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);
    const orderState = useSelector(state => state.order);
    const cartState = useSelector(state => state.cart);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TRACKER
    const tracker = useSelector(state => state.tracker);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        // Logger.log("Component Loaded");

        // Logger.log("User Data", userData);

        if (!orderState.preLoadedCustomerAndStore) {
            // Getting All Customers
            if (userData.isRep) {
                dispatch(getAllCustomersByRepIds(userData.userData.id, (err) => {
                    if (!err) {
                        // Getting Seasons And Divisions
                        dispatch(getLatestSeasonsAndDivisions());
                    }
                }));
            } else {
                dispatch(getAllCustomersBySalesmanId(userData.isAdmin ? 0 : userData.salesmanData.salesmanId, (err) => {
                    if (!err) {
                        // Getting Seasons And Divisions
                        dispatch(getLatestSeasonsAndDivisions());
                    }
                }));
            }
        } else {
            dispatch(_setPreLoadedCustomerAndStore(false));
        }


    }, []);

    // Customer Changer
    const customerChanger = (value) => {
        Logger.log("Selected Customer: ", value);


        dispatch(logTrack(getTrackerDO(tracker.tracker, "CUSTOMER CHANGED", PAGES.ORDER_PAGE, value)));


        dispatch(setSelectedCustomer(value, (selectedCustomer) => {
            // Getting All Stores By Customer Number
            dispatch(getAllStoresByCustomerNumber(selectedCustomer.customerNumber));
        }));
    }

    // Store Changer
    const storeChanger = (value) => {
        Logger.log("Selected Store: ", value);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "STORE CHANGED", PAGES.ORDER_PAGE, value)));

        dispatch(setSelectedStore(value));
    }

    // Season Changer
    const seasonChanger = (value) => {
        Logger.log("Selected Season : ", value);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "SEASON CHANGED", PAGES.ORDER_PAGE, value)));

        dispatch(setSelectedSeason(value, () => {
            dispatch(getAllGroupsBySeasonsAndDivisions());
        }));
    }

    // Division Changer
    const divisionChanger = (value) => {
        Logger.log("Selected Division : ", value);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "DIVISION CHANGED", PAGES.ORDER_PAGE, value)));

        dispatch(setSelectedDivision(value, () => {
            dispatch(getAllGroupsBySeasonsAndDivisions());
        }));
    }

    // Group Changer
    const groupChanger = (value) => {
        Logger.log("Selected group : ", value);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "GROUP CHANGED", PAGES.ORDER_PAGE, value)));

        dispatch(setSelectedGroup(value, () => {
            dispatch(getAllItemsBySeasonDivisionAndGroup((obj) => {
                // if (!obj.error) {
                //     const allItems = obj.content;
                //     dispatch(getAllSizesWithSizeNumber(allItems[0].sizeScale));
                // }

            }));
        }));
    }

    // // Group Changer
    // const itemChanger = (value) => {
    //     Logger.log("Selected Item : ", value);
    //
    //
    //     dispatch(setSelectedProduct(value, (selectedItem) => {
    //         dispatch(getAllSizesWithSizeNumber(selectedItem.sizeScale));
    //     }));
    //
    // }

    const goToCheckout = () => {
       if(validateEmbroideryDetails(cartState.cartDetailRevamped)) {
           dispatch(logTrack(getTrackerDO(tracker.tracker, "GO TO CHECKOUT FROM ORDER PAGE" , PAGES.ORDER_PAGE,cartState.cartDetailRevamped)));
           history.push("/checkout")
       } else {
           toaster("Embroidery Details Are Missing for Some Items");
       }
    }


    return (
        <PageContainer>
            {orderState.processing ? (
                <>
                    <Loader/>
                </>
            ) : (
                <>
                    <Row>
                        <Col sm={{size: '4', offset: 1}}>
                            <Select
                                valueIndex={"selectTitle"}
                                data={orderState.allCustomers}
                                value={orderState.selectedCustomer ? orderState.selectedCustomer.id : null}
                                placeholder={"Please Select Customer"}
                                changer={(value) => customerChanger(value)}/>

                        </Col>
                        <Col sm={{size: '4', offset: 2}}>
                            <Select
                                valueIndex={"selectTitle"}
                                data={orderState.allStores}
                                value={orderState.selectedStore ? orderState.selectedStore.id : null}
                                placeholder={"Please Select Store"}
                                changer={(value) => storeChanger(value)}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{size: "6"}}>
                            {orderState.customerProcessing ? (
                                <Loader/>
                            ) : (
                                <CustomerViewer data={orderState.selectedCustomer}/>
                            )}

                        </Col>
                        <Col sm={{size: "6"}}>
                            {orderState.storeProcessing ? (
                                <Loader/>
                            ) : (
                                <StoreViewer data={orderState.selectedStore}/>
                            )}
                        </Col>

                    </Row>
                    {/*<Row>*/}
                    {/*    {orderState.customerProcessing ? (*/}
                    {/*        <Loader/>*/}
                    {/*    ) : (*/}
                    {/*        <Col sm={{size: "12"}}>*/}
                    {/*            <SalesPersonViewer*/}
                    {/*                salesPerson={userData.salesmanData ? userData.salesmanData.salesmanId : "N/A"}*/}
                    {/*                terms={orderState.selectedCustomer ? orderState.selectedCustomer.term.descr : "N/A"}*/}
                    {/*            />*/}
                    {/*        </Col>*/}
                    {/*    )}*/}

                    {/*</Row>*/}
                    <Row>
                        {orderState.selectedCustomer && orderState.selectedStore ? (
                            <>
                                <Row>
                                    <Col sm={{size: '4'}}>
                                        <Select
                                            valueIndex={"label"}
                                            data={orderState.season}
                                            value={orderState.selectedSeason ? orderState.selectedSeason.id : null}
                                            placeholder={"Please Select Season"}
                                            changer={(value) => seasonChanger(value)}/>

                                    </Col>
                                    <Col sm={{size: '4'}}>
                                        <Select
                                            data={_.sortBy(orderState.division, p => p.name)}
                                            value={orderState.selectedDivision ? orderState.selectedDivision.id : null}
                                            placeholder={"Please Select Division"}
                                            changer={(value) => divisionChanger(value)}/>

                                    </Col>
                                    <Col sm={{size: '4'}}>
                                        {orderState.itemProcessing ? (
                                            <Loader/>
                                        ) : (
                                            <Select
                                                valueIndex={"group"}
                                                data={_.sortBy(orderState.allGroups, p => p.group)}
                                                value={orderState.selectedGroup ? orderState.selectedGroup.id : null}
                                                placeholder={"Please Select Group"}
                                                changer={(value) => groupChanger(value)}/>
                                        )}


                                    </Col>

                                </Row>
                                {/*<Row className={"mt-3"}>*/}
                                {/*    <Col sm={{size: '8', offset: 2}}>*/}
                                {/*        {orderState.productsProcessing ? (*/}
                                {/*            <Loader/>*/}
                                {/*        ) : (*/}
                                {/*            <Select*/}
                                {/*                valueIndex={"valueForSelect"}*/}
                                {/*                data={orderState.allItems}*/}
                                {/*                value={orderState.selectedProduct ? orderState.selectedProduct.id : null}*/}
                                {/*                placeholder={"Please Select Item"}*/}
                                {/*                changer={(value) => itemChanger(value)}/>*/}
                                {/*        )}*/}

                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    {orderState.sizeProcessing ? (
                                        <Col sm={12} className={"text-center"}><Loader/></Col>

                                    ) : (
                                        <>
                                            {orderState.allItems.length ? (
                                                <>
                                                    <ProductViewer sizes={orderState.allSizes}
                                                                   sizesAccessory={orderState.allSizesAccessory}
                                                                   product={orderState.allItems}
                                                                   cart={cartState.cartDetailRevamped}/>


                                                </>
                                            ) : null}

                                            {/*{cartState.cartDetailRevamped.length ? (*/}
                                            {/*    <Col sm={{size: 2, offset: 10}}>*/}
                                            {/*        <Button*/}
                                            {/*            size={"large"}*/}
                                            {/*            clicker={() => {*/}
                                            {/*                goToCheckout()*/}
                                            {/*            }}*/}
                                            {/*        >Checkout</Button>*/}
                                            {/*    </Col>*/}
                                            {/*) : null}*/}

                                        </>
                                    )}
                                </Row>

                            </>
                        ) : null}

                    </Row>
                </>
            )}

        </PageContainer>
    )
}
export default connect(null, {
    getAllCustomersBySalesmanId,
    getLatestSeasonsAndDivisions,
    setSelectedCustomer,
    getAllStoresByCustomerNumber,
    setSelectedStore,
    setSelectedSeason,
    setSelectedDivision,
    getAllGroupsBySeasonsAndDivisions,
    setSelectedGroup,
    getAllItemsBySeasonDivisionAndGroup,
    setSelectedProduct,
    getAllSizesWithSizeNumber
})(OrderPage);
