export const Config = {
    ENV: window.config.ENV,
    APP_NAME: 'luckyInLove',
    APP_DISPLAY_NAME: 'Lucky In Love',
    APP_VERSION: '0.0.1',
    FOLDER_SEPARATOR: "/",
    ASSETS_FOLDER: "Assets",
    IMAGES_FOLDER: "img"

}
