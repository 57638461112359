import React, {useEffect, useState} from 'react';
import Logger from "../../Utils/Logger";
import {
    getAllCustomersByRepIds,
    getAllCustomersBySalesmanId, getAllStoresByCustomerNumber,
    getLatestSeasonsAndDivisions, setSelectedCustomer, setSelectedStore
} from "../../Store/Order/action";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../Components/Loader";
import {Col, Row} from "react-bootstrap";
import Input from '../../Components/InputV2';
import Select from "../../Components/Select";
import CustomerViewer from "../../Containers/CustomerTable";
import StoreViewer from "../../Containers/StoreContainer";
import SalesPersonViewer from "../../Containers/SalesPersonViewer";
import PageContainer from "../../Containers/PageContainer";
import {getTrackerDO, toaster, validateEmbroideryDetails} from "../../Utils/Helpers";
import {getItemBySizeNumber} from '../../Store/Order/action';
import ProductViewer from "../../Containers/ProductViewer";
import Button from "../../Components/Button";
import {logTrack} from "../../Store/Tracker/actions";
import {PAGES} from "../../Utils/CommonConstants";

const OrderByStyle = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);
    const orderState = useSelector(state => state.order);
    const cartState = useSelector(state => state.cart);
    // const {themeMode} = useSelector(state => state.theme);

    const [styleNumber, setStyleNumber] = useState("");

    useEffect(() => {
        setStyleNumber(null);
        // Logger.log("Component Loaded");

        // Logger.log("User Data", userData);

        // Getting All Customers
        if (userData.isRep) {
            dispatch(getAllCustomersByRepIds(userData.userData.id, (err) => {
                if (!err) {
                    // Getting Seasons And Divisions
                    dispatch(getLatestSeasonsAndDivisions());
                }
            }));
        } else {
            dispatch(getAllCustomersBySalesmanId(userData.salesmanData.salesmanId, (err) => {
                if (!err) {
                    // Getting Seasons And Divisions
                    dispatch(getLatestSeasonsAndDivisions());
                }
            }));
        }


    }, []);

    // Customer Changer
    const customerChanger = (value) => {
        Logger.log("Selected Customer: ", value);

        dispatch(setSelectedCustomer(value, (selectedCustomer) => {
            // Getting All Stores By Customer Number
            dispatch(getAllStoresByCustomerNumber(selectedCustomer.customerNumber));
        }));
    }

    // Store Changer
    const storeChanger = (value) => {
        Logger.log("Selected Store: ", value);

        dispatch(setSelectedStore(value));
    }

    const searchItem = () => {
        // Logger.log("BTN CLICKER");

        if (styleNumber && styleNumber.length) {
            // Logger.log("STYLE NUMBER: ", styleNumber);

            dispatch(getItemBySizeNumber(styleNumber));

        } else {
            toaster("Please enter the size");
        }
    }

    const goToCheckout = () => {
        if (validateEmbroideryDetails(cartState.cartDetailRevamped)) {
            history.push("/checkout")
        } else {
            toaster("Embroidery Details Are Missing for Some Items");
        }
    }

    return (
        <PageContainer>
            {orderState.processing ? (
                <>
                    <Loader/>
                </>
            ) : (
                <>
                    <Row>
                        <Col sm={{size: '4', offset: 1}}>
                            <Select
                                valueIndex={"selectTitle"}
                                data={orderState.allCustomers}
                                value={orderState.selectedCustomer ? orderState.selectedCustomer.id : null}
                                placeholder={"Please Select Customer"}
                                changer={(value) => customerChanger(value)}/>

                        </Col>
                        <Col sm={{size: '4', offset: 2}}>
                            <Select
                                valueIndex={"selectTitle"}
                                data={orderState.allStores}
                                value={orderState.selectedStore ? orderState.selectedStore.id : null}
                                placeholder={"Please Select Store"}
                                changer={(value) => storeChanger(value)}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{size: "6"}}>
                            {orderState.customerProcessing ? (
                                <Loader/>
                            ) : (
                                <CustomerViewer data={orderState.selectedCustomer}/>
                            )}

                        </Col>
                        <Col sm={{size: "6"}}>
                            {orderState.storeProcessing ? (
                                <Loader/>
                            ) : (
                                <StoreViewer data={orderState.selectedStore}/>
                            )}
                        </Col>

                    </Row>
                    {/*<Row>*/}
                    {/*    {orderState.customerProcessing ? (*/}
                    {/*        <Loader/>*/}
                    {/*    ) : (*/}
                    {/*        <Col sm={{size: "12"}}>*/}
                    {/*            <SalesPersonViewer*/}
                    {/*                salesPerson={userData.salesmanData ? userData.salesmanData.salesmanId : "N/A"}*/}
                    {/*                terms={orderState.selectedCustomer ? orderState.selectedCustomer.term.descr : "N/A"}*/}
                    {/*            />*/}
                    {/*        </Col>*/}
                    {/*    )}*/}

                    {/*</Row>*/}
                    {orderState.selectedCustomer && orderState.selectedStore ?
                        (
                            <>
                                <Row>

                                    <Input
                                        labelSize={2}
                                        label={"Style Number"}
                                        button={true}
                                        btnLabel={"Search"}
                                        btnClicker={searchItem}
                                        inputSize={7}
                                        changer={(e) => setStyleNumber(e.target.value)}
                                        value={styleNumber}

                                    />
                                </Row>
                                <Row>
                                    {orderState.sizeProcessing ? (
                                        <Col sm={12} className={"text-center"}><Loader/></Col>

                                    ) : (
                                        <>
                                            {orderState.allItems.length ? (
                                                <>
                                                    <ProductViewer sizes={orderState.allSizes}
                                                                   sizesAccessory={orderState.allSizesAccessory}
                                                                   product={orderState.allItems}
                                                                   cart={cartState.cartDetailRevamped}/>


                                                </>
                                            ) : null}

                                            {/*{cartState.cartDetailRevamped.length ? (*/}
                                            {/*    <Col sm={{size: 2, offset: 10}}>*/}
                                            {/*        <Button*/}
                                            {/*            size={"large"}*/}
                                            {/*            clicker={() => goToCheckout()}*/}
                                            {/*        >Checkout</Button>*/}
                                            {/*    </Col>*/}
                                            {/*) : null}*/}

                                        </>
                                    )}
                                </Row>

                            </>
                        ) : null}
                </>
            )}
        </PageContainer>


    )
}

export default OrderByStyle;
