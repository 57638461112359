// Library
import React from 'react';
import * as Helpers from "../../Utils/Helpers";
import Pictures from "../../Config/Pictures";
// Full Page Bg
import FullPageBg from "../../Containers/FulPageBg";
// Images
const bgImage = Helpers.toAbsoluteUrl(Pictures.HomePageBg);


const HomePage = (props) => {
    return (
        <>
            <FullPageBg img={bgImage} height={"91vh"}/>
        </>
    )
}
export default HomePage;
