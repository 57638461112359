// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "EMAIL_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_REPORTS = MODULE_PREFIX_PREFIX + "SET_REPORTS";
export const SET_REPORTS_PARSED = MODULE_PREFIX_PREFIX + "SET_REPORTS_PARSED";
export const SET_USER_REPORTS_PARSED = MODULE_PREFIX_PREFIX + "SET_USER_REPORTS_PARSED";
