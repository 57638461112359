// Library
import React from 'react';
import {useSelector} from "react-redux";
// Helper
import * as Helpers from "../../Utils/Helpers";
// Pictures
import Pictures from '../../Config/Pictures';

const logoImage = Helpers.toAbsoluteUrl(Pictures.Logo);
const logoImageLight = Helpers.toAbsoluteUrl(Pictures.LogoLight);

const Logo = (props) => {
    const {themeMode} = useSelector(state => state.theme);
    return (
        <>
            <img src={themeMode === 'light' ? logoImage : logoImageLight} style={{width: "100%"}} alt={"logo"}/>
        </>
    )
}
export default Logo;
