// Library
import React, {useEffect} from 'react';
import {connect, useSelector,useDispatch} from "react-redux";
import _ from 'lodash';
// Container
import CartViewer from "../../Containers/CartViewer";
import {areDatesEqual, checkExceedsMaxQtyInWholeCart} from "../../Utils/Helpers";
import Logger from "../../Utils/Logger";
import {changeDeliveryDateInCart} from "../../Store/Cart/action";


const CartPage = (props) => {
    const cartState = useSelector(state => state.cart);
    const dispatch = useDispatch();
    useEffect(() => {

        const checkForExceededQty = checkExceedsMaxQtyInWholeCart(cartState.cartDetailRevamped);
        Logger.log("== Check For Max QTY Exceeded : " ,  checkForExceededQty);
        if(checkForExceededQty && checkForExceededQty.length) {
            _.forEach(checkForExceededQty, (qtyExceeded) => {
               // Logger.log("===> Item: " , qtyExceeded.item);
               // Logger.log("===> ALT: " , qtyExceeded.date);
                   dispatch(changeDeliveryDateInCart(qtyExceeded.date.luxon, qtyExceeded.item.id));

            });
        }

    }, [cartState]);
    return (
        <>
            <CartViewer/>
        </>
    )
}
export default connect(null, null)(CartPage);
