import React from 'react';
import {connect} from 'react-redux';
import Logger from '../Utils/Logger';
import {logError} from "../Store/ErrorLog/actions";
import FullPageBg from "../Containers/FulPageBg";
import * as Helpers from "../Utils/Helpers";
import Pictures from "../Config/Pictures";
// Images
const bgImage = Helpers.toAbsoluteUrl(Pictures.ErrorPage);

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorLogId: 0 };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        Logger.error(error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        if(window.config.ERROR_BOUNDARY) {
            const request = {
                cart: this.props.cart,
                tracker: this.props.tracker.tracker
            }
            const data = {
                error: error.hasOwnProperty("message") ? error.message : JSON.stringify(error),
                stackTrace: JSON.stringify(errorInfo),
                userId: this.props.user.userData.id,
                otherInfo: error.hasOwnProperty("stack") ? error.stack : null,
                request: JSON.stringify(request)
            }
            this.props.logError(data, (content) => {
                if (!content.error) {
                    this.setState({errorLogId: content.data.id})
                }
            });
        }

        Logger.error(error, errorInfo);
    }

    render() {
        if(window.config.ERROR_BOUNDARY) {
            if (this.state.hasError) {
                // You can render any custom fallback UI
                return (
                    <FullPageBg size={"44% 100%"} position={"center 70px"} img={bgImage} height={"91vh"}>
                        <h2 className={"text-center"}>Something Went Wrong</h2>
                        <h3 className={"text-center"}>Please give us some time to resolve your issue</h3>
                        {this.props.errorLog.processing ? (
                            <></>
                        ) : (
                            <>
                                <h4 className={"text-center"}>Your Resolution Id is: <b>{this.state.errorLogId}</b></h4>
                                <h6 className={"text-center"}>Please provide this ID to the Team to resolve this issue
                                    asap</h6>
                            </>
                        )}

                    </FullPageBg>
                )
            }
        }

        return this.props.children;
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.user,
        errorLog: state.error,
        cart: state.cart,
        tracker: state.tracker
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logError: (data, cb) => dispatch(logError(data, cb))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);