// Libraries
import _ from 'lodash';
// Logger
import Logger from '../../Utils/Logger';


const COST_COLOR = "rgb(38,243,31)";
const COST_COLOR_BORDER = "rgba(38,243,31, 0.5)";

const DELIVERY_COLOR = "rgb(255, 99, 132)";
const DELIVERY_COLOR_BORDER = "rgba(255, 99, 132, 0.5)";

const ORDER_COLOR = "rgb(53, 162, 235)";
const ORDER_COLOR_BORDER = "rgba(53, 162, 235, 0.5)";


export const getParsedReports = (reportsData, monthsCount) => {

    const dataSetToReturn = [];
    const dataSetToReturnForCost = [];

    const lastSixMonths = [];
    const monthNameArr = [];

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const today = new Date();
    let d;
    let month;

    for (let i = monthsCount; i >= 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        Logger.log(month);
        const finder = _.find(reportsData, p => p.monthName === month.toUpperCase());
        if (finder) {
            lastSixMonths.push(finder);
            monthNameArr.push(finder.monthName);
        }
    }
    dataSetToReturnForCost.push(
        {
            label: "Revenue",
            data: _.map(lastSixMonths, p => p.totalSum),
            borderColor: COST_COLOR,
            backgroundColor: COST_COLOR_BORDER,
        }
    );
    dataSetToReturn.push(
        {
            label: "Deliveries",
            data: _.map(lastSixMonths, p => p.totalDeliveries),
            borderColor: DELIVERY_COLOR,
            backgroundColor: DELIVERY_COLOR_BORDER,
        }
    );
    dataSetToReturn.push(
        {
            label: "Transactions",
            data: _.map(lastSixMonths, p => p.totalOrders),
            borderColor: ORDER_COLOR,
            backgroundColor: ORDER_COLOR_BORDER,
        }
    );

    return {
        revenue: {
            labels: monthNameArr,
            datasets: dataSetToReturnForCost
        },
        order: {
            labels: monthNameArr,
            datasets: dataSetToReturn
        }
    }
}

export const getParsedFilterReports = (reportsData, requestData, userData) => {

    const parsedDataSet = [];

    const dataSet = [];
    const dataSetForRevenue = [];

    Logger.log("REPORTS DATA: " , reportsData);
    Logger.log("REQUESTED DATA: " , requestData);
    Logger.log("USER DATA: " , userData);

    const REP_LABELS = [];
    Logger.log("ALL REPS: " , userData);

    _.map(reportsData, (data, index) => {

        const userFinder = _.find(userData, p => p.id === data.userId);

        Logger.log("USERFINDER: " , userFinder);
        REP_LABELS.push(userFinder.name);
        parsedDataSet.push(data.report);
    });

    Logger.log("PARSED REPORTS: " , parsedDataSet);

    dataSet.push(
        {
            label: "Transactions",
            data: _.map(parsedDataSet, p => p.totalOrders),
            borderColor: ORDER_COLOR,
            backgroundColor: ORDER_COLOR_BORDER,
        }
    );
    dataSet.push(
        {
            label: "Deliveries",
            data: _.map(parsedDataSet, p => p.totalDeliveries),
            borderColor: DELIVERY_COLOR,
            backgroundColor: DELIVERY_COLOR_BORDER,
        }
    );

    dataSetForRevenue.push(
        {
            label: "Revenue",
            data: _.map(parsedDataSet, p => p.totalSum),
            borderColor: COST_COLOR,
            backgroundColor: COST_COLOR_BORDER,
        }
    );

    return {
        revenue: {
            labels: REP_LABELS,
            datasets: dataSetForRevenue
        },
        order: {
            labels: REP_LABELS,
            datasets: dataSet
        }
    }
}
