// Library
import {createTheme, ThemeProvider} from "@mui/material";
// Importing Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Importing Colors
import Colors from '../Colors';
// Default Theme
const theme = createTheme(
    {
        // direction: "rtl",
        // typography: {
        //     fontFamily: ["Poppins"].join(",")
        // },

        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: Colors.primary,
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: Colors.secondary,
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: Colors.error,
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            }
        },

        /**
         * @see https://material-ui.com/customization/globals/#default-props
         */
        props: {
            // // Name of the component ⚛️
            // MuiButtonBase: {
            //     // The properties to apply
            //     disableRipple: false // No more ripple, on the whole application 💣!
            // },
            //
            // // Set default elevation to 1 for popovers.
            // MuiPopover: {
            //     elevation: 1
            // }
        }
    }
);

export function DefaultThemeProvider(props) {
    const {children} = props;

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
