// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "ORDER_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_ALL_CUSTOMERS = MODULE_PREFIX_PREFIX + "SET_ALL_CUSTOMERS";
export const SET_SELECTED_CUSTOMER = MODULE_PREFIX_PREFIX + "SET_SELECTED_CUSTOMER";
export const SET_CUSTOMER_PROCESSING = MODULE_PREFIX_PREFIX + "SET_CUSTOMER_PROCESSING";
export const SET_ALL_STORES = MODULE_PREFIX_PREFIX + "SET_ALL_STORES";
export const SET_SELECTED_STORE = MODULE_PREFIX_PREFIX + "SET_SELECTED_STORE";
export const SET_STORES_PROCESSING = MODULE_PREFIX_PREFIX + "SET_STORES_PROCESSING";
export const SET_ALL_PRODUCTS = MODULE_PREFIX_PREFIX + "SET_ALL_PRODUCTS";
export const SET_SELECTED_PRODUCT = MODULE_PREFIX_PREFIX + "SET_SELECTED_PRODUCT";
export const SET_PRODUCTS_PROCESSING = MODULE_PREFIX_PREFIX + "SET_PRODUCTS_PROCESSING";
export const SET_SEASON = MODULE_PREFIX_PREFIX + "SET_SEASON";
export const SET_SELECTED_SEASON = MODULE_PREFIX_PREFIX + "SET_SELECTED_SEASON";
export const SET_DIVISION = MODULE_PREFIX_PREFIX + "SET_DIVISION";
export const SET_SELECTED_DIVISION = MODULE_PREFIX_PREFIX + "SET_SELECTED_DIVISION";
export const SET_GROUP = MODULE_PREFIX_PREFIX + "SET_GROUP";
export const SET_SELECTED_GROUP = MODULE_PREFIX_PREFIX + "SET_SELECTED_GROUP";
export const SET_GROUP_PROCESSING = MODULE_PREFIX_PREFIX + "SET_GROUP_PROCESSING";
export const SET_ALL_ITEMS = MODULE_PREFIX_PREFIX + "SET_ALL_ITEMS";
export const SET_ALL_ITEMS_PARSED = MODULE_PREFIX_PREFIX + "SET_ALL_ITEMS_PARSED";
export const SET_ITEM_PROCESSING = MODULE_PREFIX_PREFIX + "SET_ITEM_PROCESSING";
export const SET_ALL_SIZES = MODULE_PREFIX_PREFIX + "SET_ALL_SIZES";
export const SET_ALL_SIZES_ACCESSORY = MODULE_PREFIX_PREFIX + "SET_ALL_SIZES_ACCESSORY";
export const SET_SIZE_PROCESSING = MODULE_PREFIX_PREFIX + "SET_SIZE_PROCESSING";
export const SET_PLACED_ORDER = MODULE_PREFIX_PREFIX + "SET_PLACED_ORDER";
export const SET_ALL_ORDERS = MODULE_PREFIX_PREFIX + "SET_ALL_ORDERS";
export const SET_LIMIT = MODULE_PREFIX_PREFIX + "SET_LIMIT";
export const SET_TOTAL_PAGES = MODULE_PREFIX_PREFIX + "SET_TOTAL_PAGES";
export const SET_CURRENT_PAGE = MODULE_PREFIX_PREFIX + "SET_CURRENT_PAGE";
export const SET_DATA_COUNT = MODULE_PREFIX_PREFIX + "SET_DATA_COUNT";
export const SET_TOTAL_DOCUMENTS = MODULE_PREFIX_PREFIX + "SET_TOTAL_DOCUMENTS";
export const SET_NEXT_PAGE = MODULE_PREFIX_PREFIX + "SET_NEXT_PAGE";
export const RESET_STATE = MODULE_PREFIX_PREFIX + "RESET_STATE";
export const SET_ORDER_TYPES = MODULE_PREFIX_PREFIX + "SET_ORDER_TYPES";
export const SET_SELECTED_ORDER_TYPE = MODULE_PREFIX_PREFIX + "SET_SELECTED_ORDER_TYPE";
export const SET_SHIPPING_TYPES = MODULE_PREFIX_PREFIX + "SET_SHIPPING_TYPES";
export const SET_SELECTED_SHIPPING_TYPE = MODULE_PREFIX_PREFIX + "SET_SELECTED_SHIPPING_TYPE";
export const SET_PRELOADED_CUSTOMER_AND_STORE = MODULE_PREFIX_PREFIX + "PRELOADED_CUSTOMER_AND_STORE";

export const SET_ITEM_FILTER = MODULE_PREFIX_PREFIX + "SET_ITEM_FILTER";
