// Library
import React from 'react';
import {Button} from "@mui/material";
import Logger from "../../Utils/Logger";


const CustomButton = (props) => {
    const {color, variant, type, children, fullWidth, style, disabled, clicker, size} = props;
    return (
        <>
            <Button color={color ? color : "primary"}
                    variant={variant ? variant : "contained"}
                    fullWidth={fullWidth ? fullWidth : false}
                    type={type ? type : "button"}
                    disabled={disabled}
                    sx={style}
                    size={size ? size : "medium"}
                    onClick={clicker ? () => clicker() : Logger.log("No Function Assigned")}>
                {children}
            </Button>
        </>
    )
}
export default CustomButton;
