// Library
import React from 'react';
// History
import {NavLink} from 'react-router-dom';

const Linker = (props) => {
    const {to} = props;
    return (
        <>
            <NavLink to={to}>
                {props.children}
            </NavLink>
        </>
    )
}

export default Linker;
