// Library
import React from 'react';
import {Container} from 'react-bootstrap';

const AppContainer = (props) => {
    return (
        <>
            <Container fluid>
                {props.children}
            </Container>
        </>
    )
}
export default AppContainer;
