// Library
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FontIcon = (props) => {
    return (
        <>
            <FontAwesomeIcon {...props}/>
        </>
    )
}

export default FontIcon;
