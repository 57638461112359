// Library
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector, connect} from 'react-redux';
import {Row, Col, Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
// Containers
import PageContainer from "../../Containers/PageContainer";
// Components
import Table from '../../Components/Table';
import Button from '../../Components/Button';
import Select from '../../Components/SelectV2';
import DateRangePicker from '../../Components/DateRangePicker';
// Helpers
import {
    getAmericanDateFormatter,
    getDateFormatForBackend,
    priceViewer,
    toaster,
    validateEmail
} from '../../Utils/Helpers';
// Redux Actions
import {getAllOrdersByUser, getAllCustomersBySalesmanId, getAllCustomersByRepIds} from "../../Store/Order/action";
// Logger
import Logger from "../../Utils/Logger";
// Loader
import Loader from "../../Components/Loader";
// Moment
import moment from 'moment';
import {getAllUsersByParentRoleId} from "../../Store/User/actions";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import FontIcon from "../../Components/FontIcon";
import InputBox from "../../Components/Input";
import {sendIndividualDeliveryEmail} from "../../Store/Email/action";

const AllOrdersPage = (props) => {

    // Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    // Store State
    const orderState = useSelector(state => state.order);
    const userState = useSelector(state => state.user);
    const {themeMode} = useSelector(state => state.theme);
    // State
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [customerId, setCustomerId] = useState(0);
    const [repId, setRepId] = useState(0);
    const [to, setTo] = useState(null);
    const [individualUUID, setIndividualUUID] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const dateRangeChanger = (dates) => {
        Logger.log("DATE RANGE CHANGER: ", dates);
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start && end) {
            Logger.log("DO FILTER");
            Logger.log("START DATE: " , new Date(start).toDateString());
            Logger.log("END DATE: " , new Date(end).toDateString());
        }
    };

    const [attachment, setAttachment] = useState(null);
    const [show, setShow] = useState(false);

    Logger.log("ATTACHMENT" , attachment);

    const handleClose = () => {
        setShow(false);
        setAttachment(null);
        setTo(null)
        setIndividualUUID(null)
    }
    const handleShow = () => setShow(true);

    // Page Change Handler
    const changePageHandler = (event, page) => {
        Logger.log("Page: ", page);
        const data = {
            page: page,
            customerId: customerId,
        }
        if (repId > 0) {
            data.repId = repId;
        } else if (customerId > 0) {
            data.customerId = customerId;
        }
        if(startDate && endDate) {
            data.startDate = getDateFormatForBackend(startDate);
            data.endDate = getDateFormatForBackend(endDate);
        }
        if (currentPage !== page) {
            dispatch(getAllOrdersByUser(data));
        }
    }


    // Use Effect
    useEffect(() => {
        Logger.log("Order State: ", orderState);
        Logger.log("CUSTOMER ID: ", customerId );
        Logger.log("repid: ", repId);
        Logger.log("endDate: ", endDate);

        if (repId > 0) {
            const data = {
                page: 1,
                customerId: customerId,
                repId: repId
            }
            if(startDate && endDate) {
                data.startDate = getDateFormatForBackend(startDate);
                data.endDate = getDateFormatForBackend(endDate);
            }
            dispatch(getAllOrdersByUser(data));
        } else if (customerId > 0) {
            const data = {
                page: 1,
                customerId: customerId,
            }
            if(startDate && endDate) {
                data.startDate = getDateFormatForBackend(startDate);
                data.endDate = getDateFormatForBackend(endDate);
            }
            dispatch(getAllOrdersByUser(data));
        } else {
            const data = {
                page: currentPage,
                customerId: customerId,
            }
            if(startDate && endDate) {
                data.startDate = getDateFormatForBackend(startDate);
                data.endDate = getDateFormatForBackend(endDate);
            }
            dispatch(getAllOrdersByUser(data, (err) => {
                if (!err) {
                    if (userState.isRep) {
                        const userId = userState.userData.id;
                        dispatch(getAllCustomersByRepIds(userId));
                        dispatch(getAllUsersByParentRoleId(userId));
                    } else {
                        dispatch(getAllCustomersBySalesmanId(userState.userData.isAdmin ? 0 : userState.salesmanData.salesmanId));
                    }
                }
            }));
        }

    }, [customerId, repId, endDate]);


    useEffect(() => {
        Logger.log("Product State Changes", orderState);
        setTotalPages(orderState.totalPages);
        setCurrentPage(orderState.page);
    }, [orderState]);


    const filterRecords = (value) => {
        if (value) {
            setCustomerId(value.id);
        } else {
            setCustomerId(0);
        }
    }

    const filterRecordsByRep = (value) => {
        if (value) {
            setRepId(value.id);
        } else {
            setRepId(0);
        }
    }

    const sendIndividualViaEmail = () => {
        if (to && validateEmail(to)) {
            setShow(false);
            dispatch(sendIndividualDeliveryEmail(to, individualUUID, (err) => {
                handleClose();
                if (err) {
                    toaster("Something went wrong while sending email");
                } else {
                    toaster("Email Sent Successfully", "success");
                }
            }));
        } else {
            toaster("Please check the email address entered");
        }
    }

    // Table Config
    const columns = [
        {
            title: "Customer",
            dataIndex: "id",
            render: (value, item, index) => (
                <span className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                    {item.orders[0].customer.name}
                </span>
            ),
            sortDirections: ['descend'],
            sorter: (a, b) => a.orders[0].customer.name.length - b.orders[0].customer.name.length,
        },
        {
            title: "Store",
            dataIndex: "id",
            render: (value, item, index) => (
                <>
                    {item.orders[0].store.name}
                </>
            ),
            sortDirections: ['descend'],
            sorter: (a, b) => a.orders[0].store.name.length - b.orders[0].store.name.length,
        },
        {
            title: "Total Deliveries",
            dataIndex: "id",
            render: (value, item, index) => item.orders.length,
            sortDirections: ['descend'],
            sorter: (a, b) => a.orders.length - b.orders.length,
        },
        {
            title: "PO Numbers",
            dataIndex: "id",
            render: (value, item, index) => {
                const poNumbers = [];
                _.map(item.orders, (order, orderIndex) => {
                    poNumbers.push(order.poNumber);
                });

                return (
                    <>{poNumbers.join(" | ")}</>
                )
            },
        },
        {
            title: "Order Total",
            dataIndex: "id",
            render: (value, item, index) => {

                const totalCost = [];

                _.map(item.orders, (singleOrder, orderIndex) => {

                    _.map(singleOrder.orderDetails, (orderDetails, orderDetailIndex) => {
                        const parsedQty = JSON.parse(orderDetails.qty);
                        totalCost.push(orderDetails.cost * parsedQty.totalQuantity)
                    });

                });

                return priceViewer(_.sum(totalCost));
            },
            sortDirections: ['descend'],
            sorter: (a, b) => {
                const totalCostA = [];

                _.map(a.orders, (singleOrder, orderIndex) => {

                    _.map(singleOrder.orderDetails, (orderDetails, orderDetailIndex) => {
                        const parsedQty = JSON.parse(orderDetails.qty);
                        totalCostA.push(orderDetails.cost * parsedQty.totalQuantity)
                    });

                });

                const totalCostB = [];

                _.map(b.orders, (singleOrder, orderIndex) => {

                    _.map(singleOrder.orderDetails, (orderDetails, orderDetailIndex) => {
                        const parsedQty = JSON.parse(orderDetails.qty);
                        totalCostB.push(orderDetails.cost * parsedQty.totalQuantity)
                    });

                });

                Logger.log("TOTAL A", _.sum(totalCostA));
                Logger.log("TOTAL B", _.sum(totalCostB));

                return _.sum(totalCostA) - _.sum(totalCostB);

            },
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            render: (value, item, index) => moment(item.createdAt).format("MMMM Do YYYY, h:mm a"),
            sorter: (a, b) => {
                return moment(a.createdAt).unix() - moment(b.createdAt).unix()
            }
        },
        {
            title: "Created By",
            dataIndex: "user",
            render: (value, item, index) => item.user.name
        },
        {
            title: "Details",
            dataIndex: "uuid",
            render: (value, item, index) => (
                <Button clicker={() => history.push("/order-acceptance/" + item.uuid)}> Details </Button>
            )
        }
    ];
    const expandedRowRender = (item) => {
        const columns = [
            {
                title: 'Delivery Number',
                dataIndex: 'deliveryNumber',
                key: 'deliveryNumber',
            },
            {
                title: 'PO Number',
                dataIndex: 'poNumber',
                key: 'poNumber',
            },
            {
                title: 'Control Number',
                dataIndex: 'controlNumber',
                key: 'controlNumber',
            },
            {
                title: 'Invoice Status',
                dataIndex: 'invoiceStatus',
                key: 'invoiceStatus',
            },
            {
                title: 'Invoice Number',
                key: 'invoiceNumber',
                dataIndex: 'invoiceNumber',
            },
            {
                title: 'Tracking Number',
                key: 'trackingNumber',
                dataIndex: 'trackingNumber',
            },
            {
                title: 'Delivery Date',
                key: 'deliveryDate',
                dataIndex: 'deliveryDate',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
            },
        ];
        const data = [];

        _.map(item.orders, (order, index) => {
            data.push({
                key: index,
                deliveryNumber: ++index,
                poNumber: order.poNumber,
                controlNumber: order.controlNumber ? order.controlNumber : "N/A",
                invoiceNumber: order.invoiceNumber ? order.invoiceNumber : "N/A",
                invoiceStatus: order.invoiceStatus ? order.invoiceStatus : "N/A",
                trackingNumber: order.trackingNumber ? order.trackingNumber : "N/A",
                deliveryDate: order.deliveryDate ? getAmericanDateFormatter(order.deliveryDate) : "N/A",
                action: (
                    <FontIcon
                        onClick={() => {
                            setIndividualUUID(order.uuid);
                            handleShow();
                        }}
                        style={{cursor: "pointer", margin: "0px 2px"}}
                        icon={faEnvelope}
                        title={"Send Via Email"}
                        className={"text-warning"}
                    />
                )
            })
        })

        Logger.log("Data: ", data);
        return <Table loading={false} columns={columns} dataSource={data} pagination={false}/>;
    };

    const clearFilter = () => {
        setCustomerId(0);
        setRepId(0);
        setStartDate(null);
        setEndDate(null);
    }

    Logger.log("REp ID:  ", repId);

    return (
        <PageContainer>
            <Row>
                <Col sm={{span: 12}}>
                    <h1 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}>All Orders</h1>
                    {orderState.processing && !orderState.allCustomers.length ? (
                        <Loader/>
                    ) : (
                        <>
                            <>

                                <Row>
                                    <Col sm={{span: 4}}>
                                        <Select
                                            label={"Customer"}
                                            valueIndex={"selectTitle"}
                                            data={orderState.allCustomers}
                                            value={customerId}
                                            placeholder={"Please Select Customer"}
                                            changer={(value) => filterRecords(value)}
                                        />
                                    </Col>
                                    {userState.isRep ? (
                                        <Col sm={{span: 4}}>
                                            <Select
                                                label={"Select REP"}
                                                valueIndex={"name"}
                                                data={userState.allUsers ? userState.allUsers : []}
                                                value={repId}
                                                placeholder={"Please Select REP"}
                                                changer={(value) => filterRecordsByRep(value)}
                                            />
                                        </Col>
                                    ) : null}
                                    <Col>
                                        <DateRangePicker
                                            label={"Date"}
                                            className={themeMode === 'light' ? 'bg-light text-dark form-control' : 'bg-dark text-light form-control'}
                                            startDate={startDate}
                                            endDate={endDate}
                                            changer={dateRangeChanger}
                                        />
                                    </Col>


                                    <Col sm={{span: 4}}>
                                        <Button style={{marginTop: "13px"}} clicker={clearFilter}>Clear</Button>

                                    </Col>
                                </Row>
                            </>
                        </>
                    )}

                    <Table
                        rowKey={"id"}
                        loading={orderState.storeProcessing}
                        columns={columns}
                        dataSource={orderState.allOrders}
                        changePageHandler={changePageHandler}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        expandable={{expandedRowRender}}
                        paginated
                    />

                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Write Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="to"
                        name="to"
                        label="Add Recipient's Email"
                        variant="standard"
                        value={to}
                        changer={(e) => setTo(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button color="error" clicker={handleClose}>
                        Close
                    </Button>
                    {"    "}
                    <Button color="primary" clicker={sendIndividualViaEmail}>
                        Sent
                    </Button>
                </Modal.Footer>
            </Modal>


            {/*{orderState.processing ? (*/}
            {/*    <>*/}
            {/*        <Loader/>*/}
            {/*    </>*/}
            {/*) : (*/}
            {/*    <>*/}
            {/*        <Row>*/}
            {/*            <Col sm={{span: 12}}>*/}


            {/*                <h1 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}>All*/}
            {/*                    Orders</h1>*/}
            {/*                <Row>*/}
            {/*                    <Col sm={{span: 4}}>*/}
            {/*                        <Select*/}
            {/*                            label={"Customer"}*/}
            {/*                            valueIndex={"selectTitle"}*/}
            {/*                            data={orderState.allCustomers}*/}
            {/*                            value={customerId}*/}
            {/*                            placeholder={"Please Select Customer"}*/}
            {/*                            changer={(value) => filterRecords(value)}*/}
            {/*                        />*/}
            {/*                    </Col>*/}
            {/*                    {userState.isRep ? (*/}
            {/*                        <Col sm={{span: 4}}>*/}
            {/*                            <Select*/}
            {/*                                label={"Select REP"}*/}
            {/*                                valueIndex={"name"}*/}
            {/*                                data={userState.allUsers ? userState.allUsers : []}*/}
            {/*                                value={repId}*/}
            {/*                                placeholder={"Please Select REP"}*/}
            {/*                                changer={(value) => filterRecordsByRep(value)}*/}
            {/*                            />*/}
            {/*                        </Col>*/}
            {/*                    ) : null}*/}
            {/*                    <Col>*/}
            {/*                        <DateRangePicker*/}
            {/*                            label={"Date"}*/}
            {/*                            className={themeMode === 'light' ? 'bg-light text-dark form-control' : 'bg-dark text-light form-control'}*/}
            {/*                            startDate={startDate}*/}
            {/*                            endDate={endDate}*/}
            {/*                            changer={dateRangeChanger}*/}
            {/*                        />*/}
            {/*                    </Col>*/}


            {/*                    <Col sm={{span: 4}}>*/}
            {/*                        <Button style={{marginTop: "13px"}} clicker={clearFilter}>Clear</Button>*/}

            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*                <Table*/}
            {/*                    rowKey={"id"}*/}
            {/*                    loading={orderState.processing}*/}
            {/*                    columns={columns}*/}
            {/*                    dataSource={orderState.allOrders}*/}
            {/*                    changePageHandler={changePageHandler}*/}
            {/*                    currentPage={currentPage}*/}
            {/*                    totalPages={totalPages}*/}
            {/*                    expandable={{expandedRowRender}}*/}
            {/*                    paginated*/}
            {/*                />*/}

            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <Modal show={show} onHide={handleClose}>*/}
            {/*            <Modal.Header closeButton>*/}
            {/*                <Modal.Title>Write Email</Modal.Title>*/}
            {/*            </Modal.Header>*/}
            {/*            <Modal.Body>*/}
            {/*                <InputBox*/}
            {/*                    margin={'dense'}*/}
            {/*                    fullWidth*/}
            {/*                    id="to"*/}
            {/*                    name="to"*/}
            {/*                    label="Add Recipient's Email"*/}
            {/*                    variant="standard"*/}
            {/*                    value={to}*/}
            {/*                    changer={(e) => setTo(e.target.value)}*/}
            {/*                />*/}

            {/*            </Modal.Body>*/}
            {/*            <Modal.Footer>*/}
            {/*                <Button color="error" clicker={handleClose}>*/}
            {/*                    Close*/}
            {/*                </Button>*/}
            {/*                {"    "}*/}
            {/*                <Button color="primary" clicker={sendIndividualViaEmail}>*/}
            {/*                    Sent*/}
            {/*                </Button>*/}
            {/*            </Modal.Footer>*/}
            {/*        </Modal>*/}

            {/*    </>*/}
            {/*)}*/}
        </PageContainer>
    )
}

export default connect()(AllOrdersPage);
