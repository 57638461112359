import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from 'reactstrap';
import _ from "lodash";
import {
    customDateFormatter,
    getNewDate,
    getTrackerDO,
    priceViewer,
    singleCartIdGenerator, toaster,
    validateEmbroideryDetails
} from "../../Utils/Helpers";
import {Table} from "react-bootstrap";
import Logger from "../../Utils/Logger";
import DatePickerV2 from "../../Components/DatePickerV2";
import {splitItem, modifyTheSplitItem, addToCart} from "../../Store/Cart/action";
import PlusMinusInput from "../../Components/PlusMinusInput";
import Colors from "../../Config/Colors";
import CartEditModal from "../CartEditModal";
import {Grid} from '@mui/material';
import FullScreenDialog from "../../Components/FullScreenDialog";
import Dialog from "../../Components/Dialogs";
import {logTrack} from "../../Store/Tracker/actions";
import {PAGES} from "../../Utils/CommonConstants";
import CartEmbroideryDetailModal from "../CartEmbroideryDetailModal";

const CartModal = (props) => {

    const dispatch = useDispatch();

    const {
        toggler,
        clearCart,
        open,
        openChild,
        cartChildToggle,
        cartState,
        body,
        history,
        saveCart
    } = props;
    const {themeMode} = useSelector(state => state.theme);
    const orderState = useSelector(state => state.order);
    const userState = useSelector(state => state.user);
    const {itemToBeSplit, itemToBeEdit} = cartState;
    const {allSizes} = orderState;

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TRACKER
    const tracker = useSelector(state => state.tracker);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const bg = themeMode === 'light' ? Colors.gray100 : Colors.gray1000;

    const getDatePicker = (singleCart) => {
        let date = getNewDate(singleCart.changeableDeliveryDate.original, null, true);
        let minDate = getNewDate(singleCart.deliveryDate) < getNewDate() ? getNewDate() : getNewDate(singleCart.deliveryDate, null, true);

        Logger.log("CART DATE", date);
        Logger.log("CART MIN DATE", minDate);

        const currentItemStatus = singleCart.availableStatus;
        const nextWip = currentItemStatus.hasOwnProperty("nextwipqty") && currentItemStatus.nextwipqty ? currentItemStatus.nextwipqty : false;
        const onhand = parseInt(currentItemStatus.onhand);
        const nextwipdate = currentItemStatus.hasOwnProperty("nextwipdate") && currentItemStatus.nextwipdate ? currentItemStatus.nextwipdate : "";

        Logger.log("Checking for WIP DATE");
        Logger.log("=== OnHand : ", onhand);
        Logger.log("=== Next Wip : ", nextWip);
        Logger.log("=== Next Wip Date : ", nextwipdate);


        if ((!onhand || onhand < 0) && nextWip && nextwipdate) {
            Logger.log("Adding Next Wip Date");
            minDate = getNewDate(nextwipdate);
            date = getNewDate(nextwipdate);

            if (getNewDate(singleCart.changeableDeliveryDate.original) > date) {
                minDate = date = getNewDate(singleCart.changeableDeliveryDate.original);
            }
        }


        // Logger.log("RENDERING THE DATE FIELD NOW...");

        return (
            <DatePickerV2
                value={date}
                minDate={minDate}
                changer={(date) => {
                    Logger.log("DATE CHANGED:", date);
                    return setDeliveryDate(date, singleCart.id)
                }}
            />
        )
    }

    const goToCheckout = () => {
        if(validateEmbroideryDetails(cartState.cartDetailRevamped)) {
            history.push("/checkout")
            dispatch(logTrack(getTrackerDO(tracker.tracker, "GO TO CHECKOUT FROM CART", PAGES.CART, cartState.cartDetailRevamped)));
            toggler()
        } else {
            toaster("Embroidery Details Are Missing for Some Items");
        }
    }

    const setDeliveryDate = (date, productId) => {
        // Logger.log("Date: ", date);
        // Logger.log("Product: ", productId);

        const item = _.cloneDeep(cartState.itemToBeSplit);

        item.changeableDeliveryDate = customDateFormatter(date);

        dispatch(splitItem(item));

    }

    const incrementerSplitter = (id, product, size, sizeNumber) => {
        const itemToWorkOn = _.cloneDeep(cartState.itemToBeSplit);

        dispatch(modifyTheSplitItem(itemToWorkOn, size, sizeNumber));
    }

    const decrementerSplitter = (id, product, size, sizeNumber) => {
        const itemToWorkOn = _.cloneDeep(cartState.itemToBeSplit);
        dispatch(modifyTheSplitItem(itemToWorkOn, size, sizeNumber, 2));
    }


    const inputChanger = () => {

    }

    const PlusMinusRendererSplitter = (product, size, key) => {
        const cartUniqueId = singleCartIdGenerator(size.sizeName, product.id, userState.userData.id, true);
        const checkProductInCartDetail = product;

        const itemStatus = product.itemAvailability;

        const currentSizeStatus = _.find(itemStatus, p => p.sizeNumber === size.sizeNumber);
        const checkDisabledInConfig = _.find(window.config.DISABLED_SIZES, p => p.style === product.fullStyle);
        let disabledSizesInConfig = [];
        // Logger.log("Disabled Styles", checkDisabledInConfig);
        if (checkDisabledInConfig) {
            disabledSizesInConfig = checkDisabledInConfig.sizes;
        }
        // Logger.log("Size in product Viewer: ", size.sizeNumber);
        // Logger.log("Check In Cart Detail? ", checkProductInCartDetail);
        let checkInCartDetail = false;
        if (checkProductInCartDetail) {
            if (checkProductInCartDetail.qty[size.sizeName] > 0) {
                checkInCartDetail = checkProductInCartDetail.qty[size.sizeName];
            }

        }
        let show = false;
        _.forIn(product.sizes, (sizeKey, sizeIndex) => {
            if (sizeKey === size.sizeName) {
                show = true;
                if (disabledSizesInConfig.length > 0 && _.indexOf(disabledSizesInConfig, sizeKey) >= 0) {
                    show = false;
                }
            }
        });

        return (
            <>

                <td style={{width: "125px", padding: "19px 10px", paddingBottom: 0}} key={key}>
                    {show ? (
                        <>
                            <PlusMinusInput incrementer={incrementerSplitter}
                                            decrementer={decrementerSplitter}
                                            product={product}
                                            size={size.sizeName}
                                            sizeNumber={size.sizeNumber}
                                            value={checkInCartDetail ? checkInCartDetail : 0}
                                            id={cartUniqueId}
                                            name={cartUniqueId}
                                            inputChanger={inputChanger}
                                            onBlur={inputChanger}
                            />
                            <div key={key} style={{textAlign: "center", marginTop: "30px"}}>
                                {currentSizeStatus.view.available}
                            </div>
                        </>
                    ) : (
                        <>
                            {blackDiv()}
                        </>
                    )}

                </td>


            </>
        )
    }

    const blackDiv = () => {

        return (
            <>
                <div style={{
                    width: "100px",
                    backgroundColor: themeMode === 'light' ? 'rgb(108 117 125)' : 'rgb(255 255 255)',
                    height: "35px"
                }}/>

            </>
        )
    }

    const saveSplitItemCart = () => {
        dispatch(addToCart(null, itemToBeSplit, null, null, 1, null, true));
    }


    return (
        <div>
            <FullScreenDialog
                title={"Cart"}
                open={open}
                handleOpen={toggler}
                handleClose={toggler}
                bg={bg}
            >
                <>
                    {body}
                </>

                <Grid direction="row-reverse"
                      container spacing={1}
                >
                    <Grid item>
                        <Button color="info" onClick={() => {
                            saveCart();
                            toggler();
                        }}>Save Cart</Button>
                    </Grid>
                    <Grid item>
                        <Button color="danger" onClick={clearCart}>Clear</Button>
                    </Grid>
                    <Grid item>
                        <Button color="success" onClick={() => {
                            goToCheckout()
                        }}>Checkout</Button>
                    </Grid>
                </Grid>


            </FullScreenDialog>
            <Dialog
                title={"Split Item Details"}
                open={openChild}
                toggler={cartChildToggle}
                bg={bg}
                color={themeMode === 'light' ? '#000000' : '#ffffff'}
            >
                <>
                    {itemToBeSplit && (
                        <Table className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                               size={"lg"} bordered>
                            <thead>
                            <tr>
                                <th >Item</th>
                                <th style={{width: "180px"}}>Delivery Date</th>
                                <th>Group</th>
                                <th>Style Code</th>
                                <th>Color</th>
                                {
                                    _.map(allSizes, (sizes, index) => (
                                        <>

                                            <th>{sizes.sizeName}</th>

                                        </>
                                    ))
                                }
                                <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>Total</th>
                                <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>Total
                                    Price
                                </th>

                            </tr>

                            </thead>
                            <tbody>
                            <tr>
                                <td>{itemToBeSplit.description}</td>
                                <td>
                                    {getDatePicker(itemToBeSplit)}
                                </td>
                                <td>{itemToBeSplit.group}</td>
                                <td>{itemToBeSplit.fullStyle}</td>
                                <td>{itemToBeSplit.colorCode}</td>
                                {
                                    _.map(allSizes, (size, index) => (
                                        <>
                                            <td>
                                                {PlusMinusRendererSplitter(itemToBeSplit, size, index, false)}
                                            </td>
                                        </>
                                    ))
                                }
                                <td style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{itemToBeSplit.qty["totalQuantity"]} Units</td>
                                <td style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{priceViewer(itemToBeSplit.qty["totalQuantity"] * itemToBeSplit.cost)}</td>
                            </tr>

                            </tbody>

                        </Table>
                    )}
                    <Grid direction="row-reverse"
                          container spacing={1}>
                        <Grid item>
                            <Button color="success" onClick={() => {
                                saveSplitItemCart();
                                cartChildToggle()
                            }}>Save</Button>
                        </Grid>
                        <Grid item>
                            <Button color="secondary" onClick={cartChildToggle}>Cancel</Button>
                        </Grid>
                    </Grid>

                </>

            </Dialog>
            <CartEditModal item={itemToBeEdit}/>
            <CartEmbroideryDetailModal/>
        </div>
    );
}

export default CartModal;
