// Default Application Colors

const Color = {
    // Secondary
    secondary: "#E4E6EF",

    // Error
    error: "#F64E60",

    success: "#1BC5BD",
    light: "#F3F6F9",
    dark: "#212121",

    // Black color
    black: "#000000",
    // White color
    white: "#ffffff",
    whiteName: "white",
    blackName: "black",

    // Gray colors
    gray100: "#F3F6F9",
    gray200: "#EBEDF3",
    gray300: "#E4E6EF",
    gray400: "#D1D3E0",
    gray500: "#B5B5C3",
    gray600: "#7E8299",
    gray700: "#5E6278",
    gray800: "#3F4254",
    gray900: "#181C32",
    gray1000: "#1E1E1EFF",

    // Primary colors
    primary: window.colors.primary,
    info: window.colors.info, // Bootstrap variable

    warning: window.colors.warning, // Bootstrap variable

    danger: window.colors.danger, // Bootstrap variable

    darkSpinner: window.colors.darkSpinner,
    lightSpinner: window.colors.lightSpinner,

}
export default Color;
