import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
    const {
        open,
        handleClose,
        title,
        children,
        bg
    } = props;

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {background :bg}
                }}
            >
                <AppBar sx={{position: 'relative', bgcolor: "#192a64", color:"white"}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{
                    width: "95%",
                    margin: "20px auto"
                }}>

                        {children}
                </div>

            </Dialog>
        </div>
    );
}

export default FullScreenDialog;