// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "USER_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_IS_ADMIN = MODULE_PREFIX_PREFIX + "SET_IS_ADMIN";
export const SET_AUTH = MODULE_PREFIX_PREFIX + "SET_AUTH";
export const SET_USER_DATA = MODULE_PREFIX_PREFIX + "SET_USER_DATA";
export const SET_ROLE_ID = MODULE_PREFIX_PREFIX + "SET_ROLE_ID";
export const SET_PARENT_ROLE_ID = MODULE_PREFIX_PREFIX + "SET_PARENT_ROLE_ID";
export const SET_IS_REP = MODULE_PREFIX_PREFIX + "SET_IS_REP";
export const SET_TOKEN = MODULE_PREFIX_PREFIX + "SET_TOKEN";
export const TOGGLE_STORAGE = MODULE_PREFIX_PREFIX + "TOGGLE_STORAGE";
export const RESET_STATE = MODULE_PREFIX_PREFIX + "RESET_STATE";
export const SET_SALESMAN_DATA = MODULE_PREFIX_PREFIX + "SET_SALESMAN_DATA";
export const SET_REFRESH_TOKEN = MODULE_PREFIX_PREFIX + "SET_REFRESH_TOKEN";
export const SET_ALL_USERS = MODULE_PREFIX_PREFIX + "SET_ALL_USERS";
export const SET_ALL_USERS_FOR_ADDING = MODULE_PREFIX_PREFIX + "SET_ALL_USERS_FOR_ADDING";
export const SET_ALL_SALESMAN = MODULE_PREFIX_PREFIX + "SET_ALL_SALESMAN";
export const SET_TRANSACTIONAL_USER = MODULE_PREFIX_PREFIX + "SET_TRANSACTIONAL_USER";
export const SET_FETCH_TEMP_ACCOUNT_CART_STORAGE = MODULE_PREFIX_PREFIX + "SET_FETCH_TEMP_ACCOUNT_CART_STORAGE";
