// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "EMAIL_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const SET_TO = MODULE_PREFIX_PREFIX + "SET_TO";
export const SET_ATTACHMENT = MODULE_PREFIX_PREFIX + "SET_ATTACHMENT";
