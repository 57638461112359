export const USER_LOCAL_STORAGE_KEY = "luckyinlove_user_local";
export const USER_STORAGE_EXPIRY = 72 * 60 * 60 // 3 Days

export const CART_LOCAL_STORAGE_KEY = "luckyinlove_cart_local";
export const CART_STORAGE_EXPIRY = 72 * 60 * 60 // 3 Days

export const THEME_LOCAL_STORAGE_KEY = "luckyinlove_theme_local";
export const THEME_STORAGE_EXPIRY = 72 * 60 * 60 // 3 Days

export const TRACKER_LOCAL_STORAGE_KEY = "luckyinlove_tracker_local";
export const TRACKER_STORAGE_EXPIRY = 24 * 60 * 60 // 1 Days

export const CART_MAX_QTY = window.config["CART_MAX_QTY"];

export const REGEX_FOR_ONLY_NUMBER = /^\d+$/;
export const REGEX_FOR_COMMA_SEPERATED_NUMBER = /^[0-9]+(,[0-9]+)*$/;
export const REGEX_FOR_DASH_SEPERATED_NUMBER = /^[0-9]+(-[0-9]+)*$/;

export const REGEX_FOR_DASH_AND_COMMA_SEPERATED_NUMBER = /[0-9]+-[0-9]+,[0-9]+/i;

export const FILTER_PRICE_HIGH_TO_LOW = "FILTER_PRICE_HIGH_TO_LOW";
export const FILTER_STYLE_HIGH_TO_LOW = "FILTER_STYLE_HIGH_TO_LOW";
export const FILTER_PRICE_LOW_TO_HIGH = "FILTER_PRICE_LOW_TO_HIGH";
export const FILTER_STYLE_LOW_TO_HIGH = "FILTER_STYLE_LOW_TO_HIGH";

export const PAGES = {
    LOGIN: "LOGIN",
    ORDER_PAGE: "ORDER_PAGE",
    ORDER_BY_STYLE: "ORDER_BY_STYLE",
    CHECKOUT: "CHECKOUT",
    CART: "CART"
}

export const SIZES_LENGTH = 8;
export const SIZES_INDEXES = ['size1', 'size2', 'size3', "size4", "size5", "size6", "size7", "size8"];
export const SIZES_NAME = window.config.STYLES ? window.config.STYLES : ['XXS', 'XS', 'S', 'M', "L", "XL", "XXL", "ONE SIZE", "KIDS 9-3", "WOMEN 4-10", "36(6)", "37(7)", "38(7.5)", "39(8.5)", "40(9)", "41(9.5)", "42(10)"];
export const MONTHS = [
    {
        full: "January",
        half: "Jan",
        number: 1,
        string: "01"
    },
    {
        full: "February",
        half: "Feb",
        number: 2,
        string: "02"
    },
    {
        full: "March",
        half: "Mar",
        number: 3,
        string: "03"
    },
    {
        full: "April",
        half: "Apr",
        number: 4,
        string: "04"
    },
    {
        full: "May",
        half: "May",
        number: 5,
        string: "05"
    },
    {
        full: "June",
        half: "Jun",
        number: 6,
        string: "06"
    },
    {
        full: "July",
        half: "Jul",
        number: 7,
        string: "07"
    },
    {
        full: "August",
        half: "Aug",
        number: 8,
        string: "08"
    },
    {
        full: "September",
        half: "Sep",
        number: 9,
        string: "09"
    },
    {
        full: "October",
        half: "Oct",
        number: 10,
        string: "10"
    },
    {
        full: "November",
        half: "Nov",
        number: 11,
        string: "11"
    },
    {
        full: "December",
        half: "Dec",
        number: 12,
        string: "12"
    },
];

