// Library
import axios from 'axios';
// Store
import store from '../Store';
// Server Url
import url from '../server';
// Logger
import Logger from './Logger';
// Helper
import {encryptionHeadersForProductApi} from './Helpers';

const getJwt = () => {
    const {user} = store.getState();
    // Logger.log("JWT", auth);
    if (user && user.token) {
        return "Bearer " + user.token;
    }

    return '';
};

const getLocale = () => {
    const {language} = store.getState();
    if(language && language.locale) {
        return language.locale;
    }
    return "en";
};

const getLoginOptions = () => {
    return {
        headers: {
            "Accept-Language": getLocale()
        }
    }
};

const getOptions = () => {
    return {
        headers: {
            "Authorization": getJwt(),
            "Accept-Language": getLocale()
        }
    }
};

const getAESEncryption = () => {
    return {
        headers : encryptionHeadersForProductApi()
    }
}
const prepareUrl = (api) => {
    const urll = `${url}${api}`;
    Logger.log("Sending Request to ", urll);
    Logger.log("Sending Request with Locale ", getLocale());

    return urll;
};

const prepareLog = (formData) => {
    const {user} = store.getState();
    if(user && user.id) {
        formData.userId = user.id;
    } else {
        formData.userId = 'generic';
    }

    return formData;
}
// Making Instance

const axiosInstance = axios.create();

const wrapper = {
    get: (api) => axiosInstance.get(prepareUrl(api), getOptions()),
    post: (api, formData = {}) => axiosInstance.post(prepareUrl(api), formData, getOptions()),
    put: (api, formData = {}) => axiosInstance.put(prepareUrl(api), formData, getOptions()),
    delete: (api) => axiosInstance.delete(prepareUrl(api), getOptions()),
    patch: (api, formData = {}) => axiosInstance.patch(prepareUrl(api), formData, getOptions()),
    login: (api, formData = {}) => axiosInstance.post(prepareUrl(api), formData, getLoginOptions()),
    rawGet: (api) => axiosInstance.get(api),
    getWithAES: (api) => axiosInstance.get(api, getAESEncryption()),
    logger : (api, formData) => axiosInstance.post(api, prepareLog(formData))
};


export default wrapper;
