// Action Types

// PREFIX
const MODULE_PREFIX_PREFIX = "CART_ACTION__";

// ACTIONS
export const SET_PROCESSING =  MODULE_PREFIX_PREFIX + "SET_PROCESSING";
export const SET_ERROR = MODULE_PREFIX_PREFIX + "SET_ERROR";
export const SET_MESSAGE = MODULE_PREFIX_PREFIX + "SET_MESSAGE";
export const TOGGLE_STORAGE = MODULE_PREFIX_PREFIX + "TOGGLE_STORAGE";
export const RESET_STATE = MODULE_PREFIX_PREFIX + "RESET_STATE";
export const SET_CART_DETAIL = MODULE_PREFIX_PREFIX + "SET_CART_DETAIL";
export const SET_CART_DETAIL_PARSED = MODULE_PREFIX_PREFIX + "SET_CART_DETAIL_PARSED";
export const SET_CART_DETAIL_REVAMPED = MODULE_PREFIX_PREFIX + "SET_CART_DETAIL_REVAMPED";
export const SET_UNIQUE_CART_ITEMS = MODULE_PREFIX_PREFIX + "SET_UNIQUE_CART_ITEMS";
export const SET_USER_ID = MODULE_PREFIX_PREFIX + "SET_USER_ID";
export const SET_OPEN = MODULE_PREFIX_PREFIX + "SET_OPEN";
export const SET_CHILD_OPEN = MODULE_PREFIX_PREFIX + "SET_CHILD_OPEN";
export const SET_CHILD_OPEN_FOR_EDIT = MODULE_PREFIX_PREFIX + "SET_CHILD_OPEN_FOR_EDIT";
export const SET_CHILD_OPEN_FOR_EMBROIDERY = MODULE_PREFIX_PREFIX + "SET_CHILD_OPEN_FOR_EMBROIDERY";
export const SET_ITEM_TO_BE_SPLIT = MODULE_PREFIX_PREFIX + "SET_ITEM_TO_BE_SPLIT";
export const SET_ITEM_TO_BE_EDIT = MODULE_PREFIX_PREFIX + "SET_ITEM_TO_BE_EDIT";
export const SET_SELECTED_STORE = MODULE_PREFIX_PREFIX + "SET_SELECTED_STORE";
export const SET_SELECTED_CUSTOMER = MODULE_PREFIX_PREFIX + "SET_SELECTED_CUSTOMER";
export const SET_CART_STORAGE = MODULE_PREFIX_PREFIX + "SET_CART_STORAGE";
export const SET_TEMP_ACCOUNT_CART_STORAGE = MODULE_PREFIX_PREFIX + "SET_TEMP_ACCOUNT_CART_STORAGE";
export const SET_MAX_CART_DELIVERY_DATE = MODULE_PREFIX_PREFIX + "SET_MAX_CART_DELIVERY_DATE";
export const SET_MIN_MAX_CART_DELIVERY_DATE = MODULE_PREFIX_PREFIX + "SET_MIN_MAX_CART_DELIVERY_DATE";
