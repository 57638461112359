// Library
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider} from "@mui/material";
import Button from '../Button';
import {getNewDate} from "../../Utils/Helpers";

const DatePickerV2 = (props) => {

    const {value, changer, minDate, label, showCurrentDateSelector} = props;
    const {themeMode} = useSelector(state => state.theme);
    const [open, setOpen] = useState(false);

    const theme = createTheme({
        palette: {
            // background: {
            //     paper: '#000',
            // },
            // text: {
            //     primary: '#fff',
            //     secondary: '#fff',
            // },
            // action: {
            //     active: '#fff',
            // },
            // success: {
            //     dark: '#fff',
            //     main: "#fff"
            // },
            mode: themeMode
        },
    });

    const selectCurrentDate = () => {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "52px"
            }}>
                <Button clicker={() => {
                    changer(value);
                    setOpen(false);
                }}>Select Current Date</Button>
            </div>
        )
    }


    return (
        <>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DesktopDatePicker
                        components={{
                            ActionBar: showCurrentDateSelector ? selectCurrentDate : null
                        }}
                        componentsProps={{
                            actionBar: {actions: showCurrentDateSelector ? ['clear'] : []},
                        }}
                        // closeOnSelect={true}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        open={open}
                        label={label ? label : "Date"}
                        inputFormat="MM-dd-yyyy"
                        value={getNewDate(value)}
                        sx={{borderColor: 'primary.main'}}
                        onChange={changer}
                        // minDate={minDate}
                        minDate={getNewDate(minDate)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </>
    )
}

export default DatePickerV2;