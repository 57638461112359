import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
// Page Container
import PageContainer from "../../Containers/PageContainer";
import InputV2 from "../../Components/InputV2";
import Loader from "../../Components/Loader";
import Button from "../../Components/Button";
import Colors from "../../Config/Colors";
import {toaster, validateEmail} from "../../Utils/Helpers";
import Logger from '../../Utils/Logger';
// Redux Actions
import {sendCreditAppEmail} from '../../Store/Order/action';


const CreditAppPage = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [response, setResponse] = useState("");


    const submitForm = () => {
        setResponse("");
        setLoading(true);
        if (email && validateEmail(email)) {
            dispatch(sendCreditAppEmail(email, (response) => {
                setLoading(false);
                if(response) {
                    Logger.log("RESPONSE", response);
                    const {data} = response;
                    if(data && data.hasOwnProperty("data")) {
                        const innerHtml = <div className={"alert alert-success"}><h4>{response.status.statusdescription}</h4><a target="_blank" href={response.data.data.link}>Link to Form</a></div>;
                        setResponse(innerHtml);
                        toaster("Please check your email for access token", "success");
                    } else {
                        const {status} = response;
                        if(status) {
                            const innerHtml = <div className={"alert alert-danger"}><h4>{status.statusdescription}</h4></div>;
                            setResponse(innerHtml);
                        }
                    }

                } else {
                    toaster("Some error occurred while processing request");
                }
            }));
        } else {
            setLoading(false);
            toaster("Please check the email address entered");
        }
    }


    return (
        <PageContainer>
            <h3 className={"text-center"}>Credit App</h3>
            <hr/>
            <div className={"text-center"}>{response}</div>
            <Row>
                <Col sm={{span: 5, offset: 4}}>
                    <InputV2
                        id="email"
                        name={"email"}
                        label="Email"
                        placeholder={"Enter Email Address"}
                        changer={(e) => setEmail(e.target.value)}
                    />

                </Col>

            </Row>
            <Row>
                <Col sm={{span: 2, offset: 5}}>
                    {loading ? (
                        <Loader/>
                    ) : (
                        <Button
                            clicker={() => submitForm()}
                            fullWidth
                            type="submit"
                            style={{color: Colors.white, marginTop: "30px"}}
                            disabled={loading}
                        >
                            Submit
                        </Button>
                    )}
                </Col>
            </Row>
            <br/>
            <br/>
        </PageContainer>
    )
}

export default CreditAppPage;