// Library
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// Colors
import Colors from "../../Config/Colors";

const Loader = (props) => {
    const {dark, style} = props;
    return (
        <>
            <CircularProgress sx={{color: dark ? Colors.darkSpinner : Colors.lightSpinner , ...style}}/>
        </>
    )
}
export default Loader;
