import React, {useEffect, useState} from 'react';
import './counter.css';
import Logger from "../../Utils/Logger";
import {CART_MAX_QTY} from "../../Utils/CommonConstants"; // Import CSS for styling

const Counter = (props) => {
    const {
        incrementer,
        decrementer,
        value,
        id,
        name,
        product,
        size,
        sizeNumber,
        inputChanger
    } = props;

    const [inputValue, setInputValue] = useState(value);


    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const valueChanger = (id, product, size, value) => {
        const valueChecker = /^\d*[0-9]\d*$/;
        if(valueChecker.test(value)) {
            document.getElementById(id).addEventListener('change',() => {Logger.log("SUCCESSFULLY ADDED EVENT")});
            // Logger.log("INPUT CHANGER", value);
            setInputValue(value);
            inputChanger(id, product, size, sizeNumber, value);
        }
    }

    const onBlurHandler = () => {
        if(product.deliveryDateInGracePeriod) {
            if (value > CART_MAX_QTY) {
                setInputValue(CART_MAX_QTY);
                inputChanger(id, product, size, sizeNumber, CART_MAX_QTY);
            } else {
                setInputValue(value);
                inputChanger(id, product, size,sizeNumber, value);
            }
        } else {
            if(value > product.maxQuantitySelection) {
                setInputValue(product.maxQuantitySelection);
                inputChanger(id, product, size, sizeNumber,product.maxQuantitySelection);
            } else {
                setInputValue(value);
                inputChanger(id, product, size,sizeNumber, value);
            }
        }
    }

    return (
        <div className="counter">
            <button
                onClick={() => decrementer(id, product, size,sizeNumber, value)}
            >-</button>
            <input
                type={"number"}
                value={inputValue}
                id={id}
                name={name}
                onChange={(e) => valueChanger(id, product, size, e.target.value)}
                onBlur={(e) => onBlurHandler(e.target.value)}
            />
            <button
                onClick={() => incrementer(id, product, size, sizeNumber, value)}
            >+</button>
        </div>
    );
};

export default Counter;
