// Library
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Select from "../Select";
import {
    FILTER_PRICE_HIGH_TO_LOW,
    FILTER_PRICE_LOW_TO_HIGH, FILTER_STYLE_HIGH_TO_LOW,
    FILTER_STYLE_LOW_TO_HIGH
} from "../../Utils/CommonConstants";
import Logger from "../../Utils/Logger";
import {applyFilters} from "../../Store/Order/action";

const FilterForItems = (props) => {

    const dispatch = useDispatch();

    const orderState = useSelector(state => state.order);


    const options = [
        {
            id: FILTER_PRICE_LOW_TO_HIGH,
            name: "$ | Low → High",
        },
        {
            id: FILTER_PRICE_HIGH_TO_LOW,
            name: "$ | High → Low",
        },
        {
            id: FILTER_STYLE_LOW_TO_HIGH,
            name: "§ | Low → High",
        },
        {
            id: FILTER_STYLE_HIGH_TO_LOW,
            name: "§ | High → Low",
        }
    ]

    const filterChanger = (value) => {
        // Logger.log("CHANGER: " , value);

        dispatch(applyFilters(orderState.allItems, value.id));
    }

	return (
		<>
            <div style={{
                position:"absolute",
                width: "200px",
                right:"42px",
                top:"388px"
            }}>
                <Select
                    value={orderState.itemFilter}
                    data={options}
                    changer={filterChanger}
                />
            </div>

		</>
	)
}
export default FilterForItems