import React from 'react';
import {Table} from "react-bootstrap";
import _ from "lodash";
import CustomDialog from "../../Components/Dialogs";
import {getAmericanDateFormatter} from "../../Utils/Helpers";
import Logger from "../../Utils/Logger";


const cartUniqueItemViewer = (props) => {
    const {
        toggler,
        data,
    } = props;

    let title = "";
    let items = [];
    if(data) {
        title = data.deliveryDate + " - " + data.division;
        items = data.items;
    }
    Logger.log("Items in Unique Viewer: ", items);
    return (

        <CustomDialog
            title={title}
            open={!!data}
            toggler={toggler}
        >
            <>
                {items ? (
                    <Table className={'mt-3 text-dark'}
                           size={"lg"} bordered>
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th style={{width: "180px"}}>Delivery Date</th>
                            <th>Group</th>
                            <th>Style Code</th>
                            <th>Color</th>
                        </tr>

                        </thead>
                        <tbody>

                            {_.map(items, (item, key) => (
                                <tr>
                                    <td>{item.description} - ({item.division})</td>
                                    <td>
                                        {getAmericanDateFormatter(data.deliveryDate)}
                                    </td>
                                    <td>{item.group}</td>
                                    <td>{item.fullStyle}</td>
                                    <td>{item.colorCode}</td>
                                </tr>
                            ))}



                        </tbody>

                    </Table>
                ) : null}
            </>
        </CustomDialog>
    );
}

export default cartUniqueItemViewer;