// Library
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'react-bootstrap';
// Redux Actions
import {getLastYearReports, setCustomMonthsForFetchedReports} from "../../Store/OrderReports/action";
// Components
import LineChartComponent from "../../Components/LineChart";
import VerticalChartComponent from '../../Components/VerticalBarChart';
import Select from "../../Components/Select";
import Loader from "../../Components/Loader";
// Page Container
import PageContainer from "../../Containers/PageContainer";
// Logger
import Logger from '../../Utils/Logger';

const OrderReports = (props) => {
    const dispatch = useDispatch();

    const [monthsCount, setMonthsCount] = useState({id: 5, name: "Last Six Months"});

    const orderReports = useSelector(state => state.orderReports);

    useEffect(() => {

        dispatch(getLastYearReports(monthsCount.id));

    }, []);

    const dropdownValueChanger = (value) => {
        Logger.log("VALUE: ", value);

        setMonthsCount(value);

        dispatch(setCustomMonthsForFetchedReports(orderReports.reports, value.id));
    }

    const dropdownData = [
        {
            id: 1,
            name: "Last Two Month"
        },
        {
            id: 2,
            name: "Last Three Month"
        },
        {
            id: 3,
            name: "Last Four Month"
        },
        {
            id: 4,
            name: "Last Five Month"
        },
        {
            id: 5,
            name: "Last Six Month"
        },
        {
            id: 6,
            name: "Last Seven Month"
        },
        {
            id: 7,
            name: "Last Eight Month"
        },
        {
            id: 8,
            name: "Last Nine Month"
        },
        {
            id: 9,
            name: "Last Ten Month"
        },
        {
            id: 10,
            name: "Last Eleven Month"
        },
        {
            id: 11,
            name: "Last Year Month"
        },
    ];

    Logger.log("orderReports.reportsParsed", orderReports.reportsParsed);
    return (
        <>
            <PageContainer>
                {orderReports.processing ? (
                    <>
                        <Loader/>
                    </>
                ) : (
                    <>
                        <Row>
                            <Col sm={12}>
                                <Row>
                                    <Col sm={{span: 4, offset: 4}}>
                                        <h6 style={{textAlign:"center"}}>Filter Reports</h6>
                                        <Select
                                            data={dropdownData}
                                            value={monthsCount.id}
                                            changer={dropdownValueChanger}
                                        />
                                    </Col>

                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row style={{marginTop:"50px"}}>
                                    <Col sm={6}>
                                        <LineChartComponent
                                            dataset={orderReports.reportsParsed ? orderReports.reportsParsed.order : {datasets: []}}
                                            chartHeading={monthsCount.name + " Order Report"}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <VerticalChartComponent
                                            dataset={orderReports.reportsParsed ? orderReports.reportsParsed.revenue : {datasets: []}}
                                            chartHeading={monthsCount.name + " Revenue Report"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </PageContainer>
        </>
    )
}
export default OrderReports