// Library
import React from 'react';
import {Table} from 'react-bootstrap';
import {useSelector} from 'react-redux';

const CustomerViewer = (props) => {
    const {themeMode} = useSelector(state => state.theme);
    const {data} = props;
    return (
        <>
            <div className={"mt-3"}>
                <h6 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}>Customer Information</h6>
                <Table size={"sm"} bordered className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}>
                    <tbody>
                    <tr>
                        <th scope="row" style={{width:"150px", textIndent:"5px"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Bill to</th>
                        <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{textIndent:"5px"}}>{data ? data.name : "N/A"}</td>
                    </tr>
                    <tr>

                        <th scope="row" style={{textIndent:"5px"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Full Address</th>
                        <td style={{textIndent:"5px"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                            {data ? data.address : "N/A"}{data ? data.addressAlt ?  " - " + data.addressAlt : "" : ""}
                            {data ? ", " + data.city : ""}
                            {data ? ", " + data.state : ""}
                            {data ? ", " + data.zip : ""}
                            {data ? ", " + data.tel : ""}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" style={{textIndent:"5px"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Buyer</th>
                        <td className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} style={{textIndent:"5px"}}>{data ? data.name : "N/A"}</td>
                    </tr>

                    </tbody>
                </Table>
            </div>
        </>
    )
}
export default CustomerViewer;
