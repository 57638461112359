// Getting URLS
import * as URLS from './Utils/ApiUrls';
// Logger
import Logger from './Utils/Logger';
// Configurations
import {Config} from './Config';

Logger.log("ENVIRONMENT FOR API ", Config.ENV);

let url = null;

switch (Config.ENV) {

    case "DEV":
        url = URLS.URLS_CONFIGS.BASE_URL_DEV;
        break;

    case "PROD":
        url = URLS.URLS_CONFIGS.BASE_URL_PROD;
        break;

    case "STAGE":
        url = URLS.URLS_CONFIGS.BASE_URL_STAGE;
        break;

    default:
        url = URLS.URLS_CONFIGS.BASE_URL_DEV;
        break;
}


url = url + URLS.URLS_CONFIGS.API_PREFIX + URLS.URLS_CONFIGS.VERSION_ONE_PREFIX;

Logger.log("API BASE URL ", url);
export default url;
