// Library
import React from 'react';
import Select from "react-select";
import {Col, FormGroup, Label, FormText, FormFeedback} from 'reactstrap';
import {useSelector} from "react-redux";
import Colors from "../../Config/Colors";

const SelectV2 = (props) => {

    const {themeMode} = useSelector(state => state.theme);
    const {
        value,
        id,
        labelSize,
        label,
        changer,
        data,
        helperText,
        error,
        inputSize,
        valueIndex,
        keyIndex,
        placeholder,
        disabled
    } = props;

    let key = keyIndex ? keyIndex : "id";
    let showLabel = valueIndex ? valueIndex : "name";

    const selectBg = themeMode === 'light' ? Colors.white : Colors.black;
    const color = themeMode === 'light' ? Colors.dark : Colors.light;
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: selectBg,
            color:color

        }),
        menuPortal: base => ({...base, zIndex: 9999}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: selectBg,
                color: color,
                "&:hover": {
                    color: selectBg,
                    backgroundColor : color
                }
            };
        }
    };

    const selectTheme = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            neutral80 : color
        },
    })

    return (
        <FormGroup row style={{margin: "15px 0px"}}>
            <Label for={id} className={themeMode === 'light' ? 'text-dark' : 'text-light'} sm={labelSize ? labelSize : 3}>{label ? label : "text-box"}</Label>
            <Col sm={inputSize ? inputSize : 9}>
                <Select
                    placeholder={placeholder ? placeholder : "Please Select"}
                    isSearchable
                    value={props.data.filter(option => {
                        return option[key] === value;
                    })}
                    onChange={changer}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    theme={selectTheme}
                    options={data}
                    getOptionLabel={(option) => option[showLabel]}
                    getOptionValue={(option) => option[key]}
                    isDisabled={disabled ? disabled : false}
                />
                {helperText ? (
                    <FormText>{helperText}</FormText>
                ) : null}
                {error ? (
                    <FormFeedback>{error}</FormFeedback>
                ) : null}
            </Col>
        </FormGroup>
    );
}

export default SelectV2;
