// Library
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {Table} from "react-bootstrap";
import _ from "lodash";
// Helpers
import {getNewDate, priceViewer, singleCartIdGenerator} from "../../Utils/Helpers";
import Colors from "../../Config/Colors";
import {_setChildOpenForEdit, addToCart, changeDeliveryDateInCart} from "../../Store/Cart/action";
import Logger from "../../Utils/Logger";
import DatePickerV2 from "../../Components/DatePickerV2";
import PlusMinusInput from "../../Components/PlusMinusInput";
import CustomDialog from "../../Components/Dialogs";
import {Grid} from "@mui/material";
// Redux


const CartEditModal = (props) => {

    const {item} = props;

    const dispatch = useDispatch();

    const {themeMode} = useSelector(state => state.theme);
    const orderState = useSelector(state => state.order);
    const userState = useSelector(state => state.user);
    const cartState = useSelector(state => state.cart);
    const {allSizes} = orderState;

    const bg = themeMode === 'light' ? Colors.gray100 : Colors.gray1000;

    const {openChildForEdit} = cartState;

    const cartChildToggle = () => {
        if (cartState.openChildForEdit) {
            dispatch(_setChildOpenForEdit(false));
        } else {
            dispatch(_setChildOpenForEdit(true));
        }
    }

    const getDatePicker = (singleCart) => {
        let date = getNewDate(singleCart.changeableDeliveryDate.original, null, true);
        let minDate = getNewDate(singleCart.deliveryDate) < getNewDate() ? getNewDate() : getNewDate(singleCart.deliveryDate, null, true);

        // Logger.log("CART DATE", date);
        // Logger.log("CART MIN DATE", minDate);

        const currentItemStatus = singleCart.availableStatus;
        const nextWip = currentItemStatus.hasOwnProperty("nextwipqty") && currentItemStatus.nextwipqty ? currentItemStatus.nextwipqty : false;
        const onhand = parseInt(currentItemStatus.onhand);
        const nextwipdate = currentItemStatus.hasOwnProperty("nextwipdate") && currentItemStatus.nextwipdate ? currentItemStatus.nextwipdate : "";

        // Logger.log("Checking for WIP DATE");
        // Logger.log("=== OnHand : ", onhand);
        // Logger.log("=== Next Wip : ", nextWip);
        // Logger.log("=== Next Wip Date : ", nextwipdate);


        if ((!onhand || onhand < 0) && nextWip && nextwipdate) {
            // Logger.log("Adding Next Wip Date");
            minDate = getNewDate(nextwipdate);
            date = getNewDate(nextwipdate);

            if (getNewDate(singleCart.changeableDeliveryDate.original) > date) {
                minDate = date = getNewDate(singleCart.changeableDeliveryDate.original);
            }
        }


        // Logger.log("RENDERING THE DATE FIELD NOW...");

        return (
            <DatePickerV2
                value={date}
                minDate={minDate}
                changer={(date) => {
                    Logger.log("DATE CHANGED:", date);
                    return setDeliveryDate(date, singleCart.id)
                }}
            />
        )
    }

    const incrementer = (id, product, size, sizeNumber) => {
        // Logger.log("Incremented: " + id);
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        dispatch(addToCart(id, product, size, sizeNumber));
    }

    const decrementer = (id, product, size, sizeNumber) => {
        // Logger.log("Decremented: " + id);
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        dispatch(addToCart(id, product, size, sizeNumber, 2));
    }
    const inputChanger = (id, product, size, sizeNumber, qty) => {
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        if (qty) {
            dispatch(addToCart(id, product, size, sizeNumber, 3, qty));
        }
    }

    const setDeliveryDate = (date, itemId) => {
        dispatch(changeDeliveryDateInCart(date, itemId));
    }
    const PlusMinusRendererSplitter = (product, size, key) => {
        const cartUniqueId = singleCartIdGenerator(size.sizeName, product.id, userState.userData.id, true);
        const checkProductInCartDetail = product;
        const availabilityStatus = product.availableStatus;

        // Logger.log("Check In Cart Detail? ", checkProductInCartDetail);
        let checkInCartDetail = false;
        if (checkProductInCartDetail) {
            if (checkProductInCartDetail.qty[size.sizeName] > 0) {
                checkInCartDetail = checkProductInCartDetail.qty[size.sizeName];
            }

        }
        let show = false;
        let sizeCode = null;
        let available = 0;
        // let wipDate = null;
        _.forIn(product.sizes, (sizeKey, sizeIndex) => {
            if (sizeKey === size.sizeName) {
                show = true;
                sizeCode = size.sizeNumber;
            }
        });

        if (availabilityStatus.hasOwnProperty(sizeCode)) {
            // Logger.log("GOING TO GENERATE THE AVAILABLE ITEMS");
            const sizeCodeAvailabilityFinder = availabilityStatus[sizeCode];
            // Logger.log("GOING TO GENERATE THE AVAILABLE ITEMS2" , sizeCodeAvailabilityFinder);
            // Case # 1 (If the OnHand Is greater than 0)
            if (parseInt(sizeCodeAvailabilityFinder.onhand) > 0) {
                available = parseInt(sizeCodeAvailabilityFinder.onhand);
            } else {
                available = 0;
                // wipDate = availabilityStatus.hasOwnProperty("nextwipdate") && availabilityStatus.nextwipdate ? `(${customDateFormatter(availabilityStatus.nextwipdate, 5).displayForm})` : "";
            }
            // Logger.log("GOING TO GENERATE THE AVAILABLE ITEMS3" , available);

            if (sizeCodeAvailabilityFinder.hasOwnProperty("nextwipqty")) {
                available = parseInt(available) + parseInt(sizeCodeAvailabilityFinder.nextwipqty);
            }
            if (parseInt(available) <= 0) {
                available = 0;
                // wipDate = null;
            }

            if (available === 0 && product.deliveryDateInGracePeriod) {
                available = "∞";
            }
            // Logger.log("GOING TO GENERATE THE AVAILABLE ITEMS 4" , available);
        }


        return (
            <>
                <td style={{width: "160px", padding: "19px 10px", paddingBottom: 0}} key={key}>
                    {show ? (
                        <>
                            <PlusMinusInput incrementer={incrementer}
                                            decrementer={decrementer}
                                            product={product}
                                            size={size.sizeName}
                                            sizeNumber={size.sizeNumber}
                                            value={checkInCartDetail ? checkInCartDetail : 0}
                                            id={cartUniqueId}
                                            name={cartUniqueId}
                                            inputChanger={inputChanger}
                                            onBlur={inputChanger}
                            />

                        </>
                    ) : (
                        <>
                            {blackDiv()}
                        </>
                    )}

                </td>
            </>
        )
    }
    const blackDiv = () => {

        return (
            <>
                <div style={{
                    width: "100px",
                    backgroundColor: themeMode === 'light' ? 'rgb(108 117 125)' : 'rgb(255 255 255)',
                    height: "35px"
                }}/>

            </>
        )
    }

    return (
        <>

            <CustomDialog
                title={"Edit Cart Details"}
                open={openChildForEdit}
                toggler={cartChildToggle}
                bg={bg}
                color={themeMode === 'light' ? '#000000' : '#ffffff'}
            >
                <>
                    {item ? (
                        <Table className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                               size={"lg"} bordered>
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th style={{width: "180px"}}>Delivery Date</th>
                                <th>Group</th>
                                <th>Style Code</th>
                                <th>Color</th>
                                {
                                    _.map(allSizes, (sizes, index) => (
                                        <>

                                            <th>{sizes.sizeName}</th>

                                        </>
                                    ))
                                }
                                <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>Total</th>
                                <th style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>Total
                                    Price
                                </th>

                            </tr>

                            </thead>
                            <tbody>
                            <tr>
                                <td>{item.description} - ({item.division})</td>
                                <td>
                                    {getDatePicker(item)}
                                </td>
                                <td>{item.group}</td>
                                <td>{item.fullStyle}</td>
                                <td>{item.colorCode}</td>
                                {
                                    _.map(allSizes, (size, index) => (
                                        <>
                                            <td>
                                                {PlusMinusRendererSplitter(item, size, index, false)}
                                            </td>
                                        </>
                                    ))
                                }
                                <td style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{item.qty["totalQuantity"]} Units</td>
                                <td style={{border: themeMode === 'light' ? "2px solid #000" : "2px solid #fff"}}>{priceViewer(item.qty["totalQuantity"] * item.cost)}</td>
                            </tr>

                            </tbody>

                        </Table>
                    ) : null}
                </>
                <Grid direction="row-reverse"
                      container spacing={1}>
                    <Grid item>
                        <Button color="danger" onClick={() => {
                            cartChildToggle()
                        }}>Close</Button>
                    </Grid>
                </Grid>

            </CustomDialog>
        </>
    );
};
export default CartEditModal;