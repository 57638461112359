// Action Types
import * as Actions from './action-types';
// User Storage Key
import {USER_LOCAL_STORAGE_KEY, USER_STORAGE_EXPIRY} from '../../Utils/CommonConstants';
// Local Storage Methods
import * as LS from '../../Utils/LocalStorageHelpers';
// Logger
import Logger from '../../Utils/Logger';
// Initial State
const initialState = {
    processing: false,
    error: false,
    message: null,
    userData: null,
    roleId: null,
    isRep: false,
    parentRoleId: null,
    authenticated: false,
    token: null,
    refreshToken: null,
    salesmanData: null,
    isAdmin: false,
    fetchTempAccountCartStorage: false,
    allUsers: [],
    allUsersForAdding: null,
    allSalesman: [],
    transactionalUser: null
};
// Get Initial State
const getInitialState = () => {
    const getData = LS.getStorage(USER_LOCAL_STORAGE_KEY);
    if (getData) {
        const dataParsed = JSON.parse(getData);
        // Logger.log("USER STORAGE DATA", dataParsed);
        return {
            processing: false,
            error: false,
            message: null,
            roleId: dataParsed.roleId,
            parentRoleId: dataParsed.parentRoleId,
            isRep: dataParsed.isRep,
            userData: dataParsed.userData,
            authenticated: dataParsed.authenticated,
            token: dataParsed.token,
            refreshToken: dataParsed.refreshToken,
            salesmanData: dataParsed.salesmanData,
            isAdmin: dataParsed.isAdmin,
            fetchTempAccountCartStorage: dataParsed.fetchTempAccountCartStorage
        }
    }
    return initialState;
}


const reducer = (state = getInitialState(), action) => {
    const newState = {...state};

    const {type, payload} = action;

    switch (type) {

        case Actions.SET_PROCESSING:
            newState.processing = payload;
            break;

        case Actions.SET_ERROR:
            newState.error = payload;
            break;

        case Actions.SET_MESSAGE:
            newState.message = payload;
            break;

        case Actions.SET_IS_ADMIN:
            newState.isAdmin = payload;
            break;

        case Actions.SET_ALL_USERS:
            newState.allUsers = payload;
            break;
        case Actions.SET_ALL_SALESMAN:
            newState.allSalesman = payload;
            break;

        case Actions.SET_AUTH:
            newState.authenticated = payload;
            break;

        case Actions.SET_USER_DATA:
            newState.userData = payload;
            break;

        case Actions.SET_TOKEN:
            newState.token = payload;
            break;

        case Actions.SET_REFRESH_TOKEN:
            newState.refreshToken = payload;
            break;

        case Actions.SET_SALESMAN_DATA:
            newState.salesmanData = payload;
            break;

        case Actions.SET_TRANSACTIONAL_USER:
            newState.transactionalUser = payload;
            break;

        case Actions.SET_ROLE_ID:
            newState.roleId = payload;
            break;

        case Actions.SET_PARENT_ROLE_ID:
            newState.parentRoleId = payload;
            break;

        case Actions.SET_IS_REP:
            newState.isRep = payload;
            break;

        case Actions.SET_ALL_USERS_FOR_ADDING:
            newState.allUsersForAdding = payload;
            break;

        case Actions.SET_FETCH_TEMP_ACCOUNT_CART_STORAGE:
            newState.fetchTempAccountCartStorage = payload;
            break;

        case Actions.TOGGLE_STORAGE:
            setStateToStorage(state, payload);
            break;

        case Actions.RESET_STATE:
            resetState(newState);
            break;

        default:
            break;
    }


    return newState;

};

const setStateToStorage = (state, fill) => {
    if (fill) {
        // Logger.log("SAVING TO STORAGE", state);
        LS.setStorage(USER_LOCAL_STORAGE_KEY, JSON.stringify(state), USER_STORAGE_EXPIRY);
    } else {
        LS.removeStorage(USER_LOCAL_STORAGE_KEY);
    }
    return state;
};
// Reset State
const resetState = (state) => {

    state.processing = false;
    state.error = false;
    state.message = null;
    state.userData = null;
    state.authenticated = false;
    state.token = null;
    state.refreshToken = null;
    state.salesmanData = null;
    state.isAdmin = false;
    state.fetchTempAccountCartStorage = false;


    return state;

};
export default reducer;
