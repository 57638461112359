// Library
import React from 'react';
// Router
import {BrowserRouter} from "react-router-dom";
// Navigation
import {Routes} from './Navigation/Main';
// Error Boundary
import ErrorBoundary from "./ErrorBoundary/ErrorBoundry";
// Toaster
import {ToastContainer} from 'react-toastify';
import {DateTime} from 'luxon';
import Logger from "./Utils/Logger";
// Maintenance Page
import MaintenancePage from './Pages/MaintenancePage';
import {encryptionHeadersForProductApi} from './Utils/Helpers';
const App = () => {

    Logger.log("==== Encryption for Product API", encryptionHeadersForProductApi());
    Logger.log("==== DATE TIME", DateTime.now());
    Logger.log("==== DATE TIME STRING", DateTime.now().toISODate());
    Logger.log("==== DATE TIME TIMEZONE", DateTime.now().setZone("America/Los_Angeles"));
    Logger.log("==== DATE TIME UTC", new Date(DateTime.now().toUTC().toISO().toString()));

    return (
        <>
            {window.config.MAINTENANCE ? (
                <MaintenancePage/>
            ) : (
                <ErrorBoundary>
                    <BrowserRouter>
                        <Routes/>
                        <ToastContainer/>
                    </BrowserRouter>
                </ErrorBoundary>
            )}
        </>
    )
}

export default App;
