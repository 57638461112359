import React from 'react';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangePickerComponent = (props) => {

    const {selectionRange, changer, minDate, maxDate} = props;
    return (
        <DateRangePicker
            ranges={[selectionRange]}
            onChange={changer}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}

export default DateRangePickerComponent;