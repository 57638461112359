import React, {useState} from 'react';
// Config
import Colors from "../../Config/Colors";
// Logo
import Logo from "../../Components/Logo";
// Container
import AuthenticationLayout from "../../Layouts/AuthenticationLayout";
import CenterContainer from "../../Containers/PageCenterContainer";
import InputBox from "../../Components/Input";
import {useFormik} from "formik";
import Logger from "../../Utils/Logger";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import Loader from "../../Components/Loader";
import Button from "../../Components/Button";
import {toaster} from "../../Utils/Helpers";
import Linker from "../../Components/Link";
import {openSlotForPasswordChange} from '../../Store/User/actions';
import {useHistory} from "react-router-dom";

// Validations
const validationSchema = yup.object({
    email: yup
        .string('Enter your Email')
        .email("Email is not formatted")
        .required('Email is required'),
});

const ForgotPassword = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };


    // Formik Form
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setStatus(false);

            Logger.log("VALUES : ", values);

            dispatch(openSlotForPasswordChange(values.email, (err) => {
                disableLoading();
                Logger.error("Open Slot Error: ", err);
                setSubmitting(false);
                if (!err) {
                    toaster("Email sent to your email Address, Please Click the button on the Email to continue", "success");
                    history.push("/");
                }

            }))

            Logger.log("Submitter, Login Values", values);
        },
    });

    return (
        <>
            <AuthenticationLayout>
                <CenterContainer>
                    <div className={"p-5 br-2 rounded-top rounded-bottom"}
                         style={{background: Colors.white, boxShadow: "0px 0px 16px #fff"}}>
                        <Logo/>
                        <form onSubmit={formik.handleSubmit}>
                            <InputBox
                                margin={'dense'}
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                variant="standard"
                                value={formik.values.email}
                                changer={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Button
                                fullWidth
                                type="submit"
                                style={{color: Colors.white, marginTop: "30px"}}
                                disabled={formik.isSubmitting}
                            >
                                {loading ? <Loader dark/> : "Submit"}
                            </Button>
                            <div style={{marginTop: "10px", float: "right"}}>
                                <Linker to={"/auth/login"}>Login</Linker>
                            </div>
                        </form>
                    </div>
                </CenterContainer>
            </AuthenticationLayout>
        </>
    )
}

export default ForgotPassword;