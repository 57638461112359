// Library
import React from 'react';


const FullPageBg = (props) => {
    const {img, children, height} = props;
    const style = {
        backgroundImage: `url(${img})`,
        backgroundSize: props.size? props.size: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition : props.position ? props.position : "",
        width: "100%",
        height: height ? height : "100vh",

    }

    return (
        <>
            <div style={style}>{children}</div>
        </>
    )
}
export default FullPageBg
