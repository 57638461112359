// Action Types
import * as Actions from './action-types';
// Axios
import axios from '../../Utils/AxiosWrapper';
// API URLS
import * as URLS from '../../Utils/ApiUrls';
// Logger
import Logger from '../../Utils/Logger';
// Error Handler
import errorHandler from "../../Utils/errorHandler";

export const getAllRoles = (cb) => {
    return (dispatch => {
        dispatch(_setProcessing(true));
        dispatch(_setAllRoles([]));

        axios.get(URLS.RolesUrls.GetAll)
            .then(response => {
                Logger.log("ALL ROLES RESPONSE RESPONSE : ", response.data);

                const {data} = response;
                const {content} = data;

                dispatch(_setAllRoles(content));
                dispatch(_setError(false));
                dispatch(_setProcessing(false));

                cb && cb();
            })
            .catch(error => {
                Logger.error("ALL ROLES ERROR", error);

                dispatch(_setError(true));
                dispatch(_setMessage(error.response && error.response.data.message ? error.response.data.message : 'No User Found'));
                dispatch(_setProcessing(false));
                errorHandler(error);

                cb && cb(error);
            });
    })
}

// Dispatch Actions
const _setProcessing = (payload) => {
    return {
        type: Actions.SET_PROCESSING,
        payload,
    };
};
const _setError = (payload) => {
    return {
        type: Actions.SET_ERROR,
        payload,
    };
};
const _setMessage = (payload) => {
    return {
        type: Actions.SET_MESSAGE,
        payload,
    };
};
const _setAllRoles = (payload) => {
    return {
        type: Actions.SET_ALL_ROLES,
        payload,
    };
}
