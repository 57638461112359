import React from 'react';
import {Col, Row} from "react-bootstrap";
import CustomerViewer from "../../Containers/CustomerTable";
import StoreViewer from "../../Containers/StoreContainer";
import OrderConfirmationDetailViewer from "../../Containers/OrderDetailConfirmationViewer";


const OrderConfirmation = (props) => {
    const {orders} = props;

    return (
        <>
            {orders.length > 0 ? (
                <>

                    <Row>
                        <Col sm={{size: "12"}}>
                            <div style={{border: "1px solid rgb(222, 226, 230)", padding: "10px"}}>
                                <h3 className={"text-center"}><b>LUCKY IN LOVE</b></h3>
                                <div className={"text-center"}>
                                    3300 NW 41ST STREET
                                    MIAMI , FL 33142 USA<br/>
                                    Tel : 305-638-5484<br/>
                                    Fax : 305-638-8949
                                </div>
                            </div>

                        </Col>

                    </Row>
                    <Row>
                        <Col sm={{size: "6"}}>
                            <CustomerViewer data={orders[0].customer}/>

                        </Col>
                        <Col sm={{size: "6"}}>
                            <StoreViewer data={orders[0].store}/>

                        </Col>

                    </Row>
                    <Row>
                        <h4 className={"text-center"}>Order Details</h4>
                        <Col>

                            <OrderConfirmationDetailViewer data={orders} term={orders[0].customer.term}/>
                        </Col>

                    </Row>

                </>
            ) : null}

        </>

    );
}

export default OrderConfirmation;
