// Library
import React from 'react';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// Component
import Button from '../Button';

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ShoppingCartButton = (props) => {
    const {cartCount, color, buttonColor, clicker} = props;
    return (
        <>
            <Button variant={"contained"} color={buttonColor ? buttonColor : "secondary"}
                    clicker={clicker}>
                <StyledBadge badgeContent={cartCount ? cartCount : 0} color={color ? color : "warning"}>
                    <ShoppingCartIcon/>
                </StyledBadge>
            </Button>
        </>
    );
}

export default ShoppingCartButton
