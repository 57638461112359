// Library
import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'react-bootstrap';
import {subMonths, addDays} from 'date-fns';
import _ from 'lodash';
// Redux Actions
import {
    getLastYearReportsByUserId
} from "../../Store/OrderReports/action";
import {getAllUsers} from '../../Store/User/actions';
// Components
import Select from "../../Components/Select";
import Loader from "../../Components/Loader";
import DateRangePicker from '../../Components/DateRangePickerFull';
// Page Container
import PageContainer from "../../Containers/PageContainer";
// Logger
import {compareTwoDates, getNewDate} from "../../Utils/Helpers";
import Logger from '../../Utils/Logger';
import LineChartComponent from "../../Components/LineChart";
import VerticalChartComponent from "../../Components/VerticalBarChart";


const OrderReports = (props) => {
    const dispatch = useDispatch();

    const chartDiv = useRef(null);

    const [userId, setUserId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [message, setMessage] = useState("");
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const orderReports = useSelector(state => state.orderReports);
    const userState = useSelector(state => state.user);

    const allReps = userState.allUsersForAdding ? userState.allUsersForAdding.reps : [];

    useEffect(() => {
        dispatch(getAllUsers(() => {
            // addUsersSelected();
        }));
    }, []);

    useEffect(() => {
        setUserId(allReps);
    }, [userState])


    useEffect(() => {
        if (startDate && endDate && userId) {
            const startDateParsed = getNewDate(startDate.toISOString(), "yyyy-MM-dd");
            const endDateParsed = getNewDate(endDate.toISOString(), "yyyy-MM-dd");
            Logger.log("START DATE: ", startDateParsed);
            Logger.log("END DATE: ", endDateParsed);
            Logger.log("User ID: ", userId);

            const data = {
                userIds: _.map(userId, p => p.id),
                beginDate: startDateParsed,
                endDate: endDateParsed
            }

            Logger.log("DATA: ", data);

            dispatch(getLastYearReportsByUserId(data, (err) => {
                if (!err) {
                    let me = "Displaying the report of Users";
                    setMessage(me);
                    chartDiv.current.scrollIntoView({behavior: "smooth"});
                }
            }));

        }
    }, [startDate, endDate, userId]);

    const handleDateRangeSelect = (e) => {
        Logger.log("EVENT: ", e);

        const newSelection = {
            startDate: e.selection.startDate,
            endDate: e.selection.endDate,
            key: 'selection',
        }
        setSelectionRange(newSelection);
        Logger.log("DATE DIFFERENCE: ", compareTwoDates(e.selection.startDate, e.selection.endDate));
        if (!compareTwoDates(e.selection.startDate, e.selection.endDate)) {
            Logger.log("InnerDate Setter: ");
            setStartDate(e.selection.startDate);
            setEndDate(e.selection.endDate);
        }
    }


    const dropdownValueChanger = (value) => {
        Logger.log("VALUE: ", value);
        setUserId(value);
    }

    Logger.log("orderReports.reportsParsed", orderReports.reportsParsed);
    return (
        <>
            <PageContainer>
                {orderReports.processing || userState.processing ? (
                    <>
                        <Loader/>
                    </>
                ) : (
                    <>
                        <Row>
                            <Col sm={12}>
                                <Row>
                                    <Col sm={{span: 6}}>
                                        <h6 style={{textAlign: "center"}}>Date Selector</h6>
                                        <DateRangePicker
                                            selectionRange={selectionRange}
                                            changer={handleDateRangeSelect}
                                            minDate={subMonths(new Date(), 12)}
                                            maxDate={addDays(new Date(), 1)}
                                        />
                                    </Col>

                                    <Col sm={{span: 4}}>
                                        <h6 style={{textAlign: "center"}}>Rep Selector</h6>
                                        <Select
                                            data={allReps}
                                            value={userId}
                                            changer={dropdownValueChanger}
                                            multi={true}
                                        />
                                    </Col>

                                </Row>
                            </Col>
                            {orderReports.userReportParsed ? (
                                <Col sm={{span: 12}} ref={chartDiv}>
                                    <h6 style={{textAlign: "center"}}>{message}</h6>
                                    <Row style={{marginTop: "50px"}}>
                                        <Col sm={12}>
                                            <LineChartComponent
                                                dataset={orderReports.userReportParsed ? orderReports.userReportParsed.order : {datasets: []}}
                                                chartHeading={" Order Report"}
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <VerticalChartComponent
                                                dataset={orderReports.userReportParsed ? orderReports.userReportParsed.revenue : {datasets: []}}
                                                chartHeading={" Revenue Report"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            ) : null}

                        </Row>
                    </>
                )}
            </PageContainer>
        </>
    )
}
export default OrderReports