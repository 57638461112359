// Library
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {ErrorMessage, FieldArray, Form, Formik} from 'formik';
import _ from "lodash";
import * as yup from "yup";
// Helpers
import {filterEmbroideryLocations, getSizesGreaterThanZero, toaster} from "../../Utils/Helpers";
import Colors from "../../Config/Colors";
// Logger
import Logger from "../../Utils/Logger";
// Components
import InputV2 from "../../Components/InputV2";
import SelectV2 from "../../Components/SelectV2";
import Loader from "../../Components/Loader";
import CustomDialog from "../../Components/Dialogs";
import Button from "../../Components/Button";
// Redux
import {
    _setChildOpenForEmbroidery,
    _toggleAsyncStorage
} from "../../Store/Cart/action";


const validationSchema = yup.object().shape({
    embroideryDetails: yup.array()
        .of(
            yup.object().shape({
                location: yup.string().required('Location is required'), // these constraints take precedence
                color: yup.string().required('Color is required')
            })
        )

});

const initialDefaultValues = {
    embroideryDetails: [],
}

const CartEmbroideryDetailModal = (props) => {

    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState(initialDefaultValues);
    const [loading, setLoading] = useState(false);

    const {themeMode} = useSelector(state => state.theme);
    const cartState = useSelector(state => state.cart);
    const bg = themeMode === 'light' ? Colors.gray100 : Colors.gray1000;

    const item = cartState.itemToBeEdit;

    const {openChildForEmbroidery} = cartState;

    const cartChildToggle = () => {
        if (cartState.openChildForEmbroidery) {
            dispatch(_setChildOpenForEmbroidery(false));
        } else {
            dispatch(_setChildOpenForEmbroidery(true));
        }
    }

    // Drop Down Changer
    const dropDownChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index.js.js: ", index.js.js);
        formik.setFieldValue(fieldName, index ? value[index] : value.id);
        // Logger.log("Formik", formik);
    }

    // Input Changer
    const inputChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index.js.js: ", index.js.js);
        formik.setFieldValue(fieldName, value);
    }


    const formProducer = () => {
        if (item) {
            if (item.embroideryDetails && item.embroideryDetails.length > 0) {
                setInitialValues({embroideryDetails: item.embroideryDetails});
            } else {
                const formForEmbroideryDetails = [];
                if (window.config.EMBROIDERY_ENABLE_ON_SIZE) {
                    Logger.log("Embroidery is enabled on Sizes");
                    const availableSizes = getSizesGreaterThanZero(item.qty);
                    Logger.log("Available Sizes: ", availableSizes);

                    if (availableSizes) {
                        _.forEach(availableSizes, (size) => {
                            formForEmbroideryDetails.push({
                                location: "",
                                color: "",
                                remarks: "",
                                size: size,
                            })
                        });
                    }
                } else {
                    Logger.log("Embroidery is enabled on Style ");
                    formForEmbroideryDetails.push({
                        location: "",
                        color: "",
                        remarks: "",
                        size: "ALL",
                    })
                }

                setInitialValues({embroideryDetails: formForEmbroideryDetails});
            }


        }
    }

    const onSubmit = (values, {setStatus, setSubmitting}) => {
        Logger.log("Values: ", values);
        setLoading(true);
        setSubmitting(true);
        setStatus(false);

        const {embroideryDetails} = values;
        const itemId = item?.id;
        Logger.log("Embroidery Details ---> ", embroideryDetails);
        Logger.log("Item Id ---> ", itemId);

        const findItemInCart = _.find(cartState.cartDetailRevamped, p => p.id === itemId);
        if (findItemInCart) {
            findItemInCart.embroideryDetails = embroideryDetails;
            dispatch(_toggleAsyncStorage(true));
            toaster("Embroidery Details Added", "success");
            cartChildToggle();

        } else {
            toaster("Something Went Wrong!");
        }
        setLoading(false);
        setSubmitting(false);
    }

    useEffect(() => {
        formProducer();
    }, [cartState.openChildForEmbroidery]);


    const formSpan = 5;
    const formOffset = 4;

    return (
        <>
            <CustomDialog
                title={"Add Embroidery Details"}
                open={openChildForEmbroidery}
                toggler={cartChildToggle}
                bg={bg}
                color={themeMode === 'light' ? '#000000' : '#ffffff'}
            >
                {item ? (
                    <>
                        <h4 className={themeMode === 'light' ? 'text-center mt-5 text-dark' : 'text-center mt-5 text-light'}>{item.fullStyle} - {item.description} - {item.division}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formikProps) => (
                                <Form>
                                    <FieldArray name={"embroideryDetails"}>

                                        <>
                                            {formikProps.values.embroideryDetails.map((formItem, index) => (
                                                <>
                                                    {window.config.EMBROIDERY_ENABLE_ON_SIZE ? (
                                                        <h4 className={"text-center mt-5"}>Size: {formItem.size}</h4>
                                                    ) : null}
                                                    <Row className={"mt-4"}>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>

                                                            <SelectV2
                                                                data={filterEmbroideryLocations(item.fields)}
                                                                value={formikProps.values.embroideryDetails[index].location}
                                                                placeholder={"Select Location"}
                                                                label={"Location"}
                                                                changer={(value) => dropDownChanger(value, formikProps, `embroideryDetails.${index}.location`)}/>

                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`embroideryDetails.${index}.location`}/>
                                                            </div>
                                                        </Col>

                                                        <Col sm={{span: formSpan, offset: formOffset}}>

                                                            <SelectV2
                                                                data={window.config.EMBROIDERY_COLORS}
                                                                value={formikProps.values.embroideryDetails[index].color}
                                                                placeholder={"Select Color"}
                                                                label={"Color"}
                                                                changer={(value) => dropDownChanger(value, formikProps, `embroideryDetails.${index}.color`)}/>

                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`embroideryDetails.${index}.color`}/>
                                                            </div>
                                                        </Col>


                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <InputV2
                                                                type={"textarea"}
                                                                margin={'dense'}
                                                                multiline
                                                                maxRows={4}
                                                                rows={4}
                                                                fullWidth
                                                                id="remarks"
                                                                name={`embroideryDetails.${index}.remarks`}
                                                                label="Remarks"
                                                                variant="standard"
                                                                value={formikProps.values.embroideryDetails[index].remarks}
                                                                changer={(e) => inputChanger(e.target.value, formikProps, `embroideryDetails.${index}.remarks`)}
                                                            />
                                                        </Col>
                                                    </Row>

                                                </>
                                            ))}

                                        </>

                                    </FieldArray>
                                    <Row>
                                        <Col sm={{span: 2, offset: 5}}>
                                            <Button
                                                color={"error"}
                                                clicker={cartChildToggle}
                                                type="button"
                                                style={{color: Colors.white, marginTop: "30px"}}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </Button>
                                            {loading ? (
                                                <Loader/>
                                            ) : (
                                                <Button
                                                    clicker={() => Logger.log("FORMIK", formikProps)}
                                                    type="submit"
                                                    style={{color: Colors.white, marginTop: "30px", marginLeft: "30px"}}
                                                    disabled={loading}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </>
                ) : null}

            </CustomDialog>
        </>
    )
};

export default CartEmbroideryDetailModal;
