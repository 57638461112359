// Library
import React, {useState} from 'react';
import _ from 'lodash';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from 'reactstrap'
// using ES6 modules
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
// Containers
import PageContainer from "../../Containers/PageContainer";
// import {Page} from 'react-pdf';
import Button from '../../Components/Button';
// Constants
import {
    REGEX_FOR_COMMA_SEPERATED_NUMBER, REGEX_FOR_DASH_AND_COMMA_SEPERATED_NUMBER,
    REGEX_FOR_DASH_SEPERATED_NUMBER,
    REGEX_FOR_ONLY_NUMBER
} from '../../Utils/CommonConstants';
// Redux Action
import {sendATSReportEmail} from '../../Store/Email/action';
import {Modal} from "react-bootstrap";
import InputBox from "../../Components/Input";
import {toaster, validateEmail} from "../../Utils/Helpers";
import Logger from "../../Utils/Logger";

const ReportPage = (props) => {

    const {season} = useParams();
    const {themeMode} = useSelector(state => state.theme);

    const url = `${window.config.EMBROIDERY_PLACEMENT_FILE_LOCATION}`;

    const dispatch = useDispatch();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageNumberToSend, setPageNumberToSend] = useState("");
    const [to, setTo] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setTo(null)
    }
    const handleShow = () => setShow(true);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const sendIndividualViaEmail = () => {

        let pageNumbers = [];


        // Checking the Pattern coming from the input

        if (REGEX_FOR_ONLY_NUMBER.test(pageNumberToSend)) {
            // 1) First Check for the single Page
            pageNumbers.push(pageNumberToSend);
        } else if (REGEX_FOR_COMMA_SEPERATED_NUMBER.test(pageNumberToSend)) {
            // 2) Checking for the Comma Seperated String => 1,4,5,7,8

            const splitPages = _.split(pageNumberToSend, ",");

            _.forEach(splitPages, (n) => {
                pageNumbers.push(parseInt(n));
            });


        } else if (REGEX_FOR_DASH_SEPERATED_NUMBER.test(pageNumberToSend)) {
            // 3) Checking for the Dash Seperated String => 1-4
            const splitPages = _.split(pageNumberToSend, "-");

            if(splitPages.length >= 2) {
                for (let i = splitPages[0]; i <= splitPages[1]; i++) {
                    pageNumbers.push(parseInt(i));
                }
            }

            //
        } else if (REGEX_FOR_DASH_AND_COMMA_SEPERATED_NUMBER.test(pageNumberToSend)) {
            // 3) Checking for the Dash & Comma  Seperated String => 1-4,6
            const splitPagesDash = _.split(pageNumberToSend, "-");

            if(splitPagesDash.length >= 2) {
                const splitPagesCommaDash = _.split(splitPagesDash[1], ",");

                if(splitPagesCommaDash.length > 1) {

                    for (let i = splitPagesDash[0]; i <= splitPagesCommaDash[0]; i++) {
                        pageNumbers.push(parseInt(i));
                    }

                    _.forEach(splitPagesCommaDash, (n, i) => {
                        if(i !== 0)  {
                            pageNumbers.push(parseInt(n));
                        }
                    });

                }
            }
        }


        Logger.log("PAGE NUMBERS : ", pageNumbers);

        if(pageNumbers.length) {
            if (to && validateEmail(to)) {
                const data = {
                    to: to,
                    pageNumbers: pageNumbers,
                    url: url
                }
                setShow(false);
                dispatch(sendATSReportEmail(data, (err) => {
                    handleClose();
                    if (err) {
                        toaster("Something went wrong while sending email");
                    } else {
                        setPageNumberToSend("");
                        toaster("Email Sent Successfully", "success");
                    }
                }));
            } else {
                toaster("Please check the email address entered");
            }
        } else {
            toaster("Please check the Page Numbers entered");
        }


    }


    return (
        <PageContainer>
            <Row>
                <Col sm={1}>
                    <Button
                        type="button"
                        disabled={pageNumber <= 1}
                        clicker={previousPage}
                    >{"<"}</Button>

                </Col>
                <Col sm={{size: 2, offset: 4}}>
                    <div style={{textAlign: "center"}}>
                        <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-mpxk45-MuiButtonBase-root-MuiButton-root">
                            <a href={url} download="embroidery-placement.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none", color:"#fff"}}>
                                Download PDF
                            </a>
                        </button>
                    </div>

                </Col>

                <Col sm={{size: 1, offset: 4}}>
                    <div style={{textAlign: "right"}}>
                        <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            clicker={nextPage}
                        >{">"}</Button>
                    </div>

                </Col>
            </Row>
            <Row>
                <div style={{textAlign: "center", marginTop: "30px"}}>
                    <p className={themeMode === 'light' ? 'text-dark' : 'text-light'}>
                        Page {pageNumber} of {numPages}
                    </p>

                </div>
            </Row>
            <Row>
                <Col sm={{size: 10}} style={{marginLeft: "40px", marginTop: "20px"}}>
                    <Document file={url}
                              onLoadSuccess={onDocumentLoadSuccess}>
                        <Page width="1600" pageNumber={pageNumber}/>
                    </Document>
                </Col>


            </Row>
            <div style={{marginBottom: "100px"}}></div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>Write Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="to"
                        name="to"
                        label="Add Recipient's Email"
                        variant="standard"
                        value={to}
                        changer={(e) => setTo(e.target.value)}
                    />
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="pageNumber"
                        name="pageNumber"
                        label="Page Number"
                        variant="standard"
                        value={pageNumberToSend}
                        changer={(e) => setPageNumberToSend(e.target.value)}
                        helperText={"Add the combination of Pages e.g [1], [1,2,3], [1-4,5], [1-5], or [0] for full file"}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button color="error" clicker={handleClose}>
                        Close
                    </Button>
                    {"    "}
                    <Button color="primary" clicker={sendIndividualViaEmail}>
                        Sent
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    )
}

export default ReportPage;
