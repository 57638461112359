import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {getAllCartStorageByUserId, deleteCartStorageById, saveFromCartStorage, getAllCartStorageByRoleId} from "../../../Store/Cart/action";
import FontIcon from "../../../Components/FontIcon";
import {getAmericanDateFormatter, toaster, validateEmail} from "../../../Utils/Helpers";
import {faSave, faTimes, faEnvelope} from "@fortawesome/free-solid-svg-icons";

import Logger from "../../../Utils/Logger";
import PageContainer from "../../../Containers/PageContainer";
import Loader from "../../../Components/Loader";
import {Col, Modal, Row} from "react-bootstrap";
import Table from "../../../Components/Table";
import InputBox from "../../../Components/Input";
import Button from "../../../Components/Button";
import {sendCartStorageEmail} from "../../../Store/Email/action";
import {
    setSelectedCustomer,
    setSelectedStore,
    _setPreLoadedCustomerAndStore,
    getAllCustomersByRepIds,
    getLatestSeasonsAndDivisions, getAllCustomersBySalesmanId, getAllStoresByCustomerNumber
} from '../../../Store/Order/action';

const CartStorageManage = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {themeMode} = useSelector(state => state.theme);

    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [to, setTo] = useState(null);

    const userState = useSelector(state => state.user);
    const cartState = useSelector(state => state.cart);

    useEffect(() => {
        dispatch(getAllCartStorageByUserId(userState.userData.id));
        if(userState.userData.fetchTempAccountCartStorage) {
            dispatch(getAllCartStorageByRoleId());
        }

    }, []);



    const handleSaveFromCartStorage = (cart, tempCart = false) => {
        Logger.log("CART", cart);

        dispatch(saveFromCartStorage(JSON.parse(cart), tempCart, (err) => {
            if (!err) {
                if(!tempCart) {
                    const cartParsed = JSON.parse(cart);

                    dispatch(_setPreLoadedCustomerAndStore(true));
                    if (userState.isRep) {
                        dispatch(getAllCustomersByRepIds(userState.userData.id, (err) => {
                            if (!err) {
                                // Getting Seasons And Divisions
                                dispatch(getLatestSeasonsAndDivisions());
                                dispatch(setSelectedCustomer(cartParsed.customer, (selectedCustomer) => {
                                    // Getting All Stores By Customer Number
                                    dispatch(getAllStoresByCustomerNumber(selectedCustomer.customerNumber));
                                    dispatch(setSelectedStore(cartParsed.store));
                                    history.push("/order")
                                }));
                            }
                        }));
                    } else {
                        dispatch(getAllCustomersBySalesmanId(userState.salesmanData.salesmanId, (err) => {
                            if (!err) {
                                // Getting Seasons And Divisions
                                dispatch(getLatestSeasonsAndDivisions());
                                dispatch(setSelectedCustomer(cartParsed.customer, (selectedCustomer) => {
                                    // Getting All Stores By Customer Number
                                    dispatch(getAllStoresByCustomerNumber(selectedCustomer.customerNumber));
                                    dispatch(setSelectedStore(cartParsed.store));
                                    history.push("/order")
                                }));
                            }
                        }));
                    }
                    toaster("Successfully Restored from Cart Storage", "success");
                } else {
                    history.push("/order")
                    toaster("Successfully Restored Unassigned Cart Storage", "success");
                }


            } else {
                toaster("The Cart storage is of older version", "danger");
            }
        }));
    }

    const deleteCartStorage = (cart) => {
        Logger.log("CART ID", cart);
        dispatch(deleteCartStorageById(cart, (err => {
            if (!err) {
                toaster("Cart Deleted Successfully");
                dispatch(getAllCartStorageByUserId(userState.userData.id));
            }
        })));
    }


    const sendCartStorageViaEmail = () => {
        if (to && validateEmail(to) && selectedItem) {
            const  data = {...selectedItem};
            data.to = to;

            setShow(false);
            Logger.log("SELECTED ITEM: ", data);
            dispatch(sendCartStorageEmail(data, (err) => {
                handleClose();
                if (err) {
                    toaster("Something went wrong while sending email");
                } else {
                    toaster("Email Sent Successfully", "success");
                }
            }));

        } else {
            toaster("Please check the email address entered");
        }
    }

    const handleClose = () => {
        setShow(false);
        setSelectedItem(null);
        setTo(null)
    }
    const handleShow = () => setShow(true);

    // Table Config
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (value, item, index) => (
                <>
                    {item.title}
                </>
            )
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            render: (value, item, index) => (
                <>
                    {getAmericanDateFormatter(item.createdAt, "MM/dd/y tt")}
                </>
            )
        },
        {
            title: "Action",
            dataIndex: "id",
            render: (value, item, index) => {
                return (
                    <>
                        <FontIcon
                            onClick={() => handleSaveFromCartStorage(item.cart, item.tempCart)}
                            style={{cursor: "pointer", margin: "0px 2px"}}
                            icon={faSave}
                            title={"Save This Cart To Local"}
                            className={"text-info"}
                        />
                        <FontIcon
                            onClick={() => deleteCartStorage(item.id)}
                            style={{cursor: "pointer", margin: "0px 10px"}}
                            icon={faTimes}
                            title={"Delete This Cart"}
                            className={"text-danger"}
                        />
                        <FontIcon
                            onClick={() => {
                                handleShow();
                                setSelectedItem(JSON.parse(item.cart));
                            }}
                            style={{cursor: "pointer", margin: "0px 10px"}}
                            icon={faEnvelope}
                            title={"Send Via Email"}
                            className={"text-warning"}
                        />
                    </>
                )
            }
        }
    ];

    return (

        <PageContainer>

            {userState.processing ? (
                <>
                    <Loader/>
                </>
            ) : (
                <>
                    <Row>
                        <Col sm={{span: 12}}>

                            <h1 className={themeMode === 'light' ? 'text-dark text-center' : 'text-light text-center'}>Cart Storage</h1>
                            <Table
                                rowKey={"id"}
                                loading={cartState.processing}
                                columns={columns}
                                dataSource={_.filter(cartState.cartStorage , p => !p.tempCart)}
                                // changePageHandler={changePageHandler
                                // paginated
                            />


                            <>
                                <h1 className={themeMode === 'light' ? 'text-dark text-center' : ' text-light text-center'}>Un assigned Storage</h1>
                                <Table
                                    rowKey={"id"}
                                    loading={cartState.processing}
                                    columns={columns}
                                    dataSource={_.filter(cartState.cartStorage , p => p.tempCart)}
                                    // changePageHandler={changePageHandler
                                    // paginated
                                />
                            </>

                        </Col>
                    </Row>

                </>
            )}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Write Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox
                        margin={'dense'}
                        fullWidth
                        id="to"
                        name="to"
                        label="Add Recipient's Email"
                        variant="standard"
                        value={to}
                        changer={(e) => setTo(e.target.value)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button color="error" clicker={handleClose}>
                        Close
                    </Button>
                    {"    "}
                    <Button color="primary" clicker={sendCartStorageViaEmail}>
                        Sent
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>


    )
}

export default CartStorageManage;
