import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CustomDialog = (props) => {
    const {
        open,
        toggler,
        title,
        children,
        bg,
        color
    } = props;


    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={false}
                open={open}
                onClose={toggler}
                PaperProps={{
                    style: {background: bg}
                }}
            >
                <DialogTitle sx={{color: color}}>{title}</DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default CustomDialog;